import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import { RatesIncreaseKeys, RatesIncreaseOptions } from './RatesIncreaseAction';
import GrantSpendActionPage from './GrantSpendAction';

export type KeysType = "threshold" | "paidShipping";

interface Props {
    choice: KeysType;
}

const RatesIncreaseResultPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <ActionOutcomeTemplate
            showNav
        >
            <Element>
                <InnerElement {...props} />
            </Element>

        </ActionOutcomeTemplate>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const competitorChoice = choices.competitor;
    const ratesIndex = (RatesIncreaseKeys as any)[competitorChoice];

    const optionIndex = props.choice ==="threshold"? 0 : 1;
    React.useEffect(() => {
        const newChoices = JSON.parse(JSON.stringify(choices));
        newChoices.ratesIncrease = props.choice;
        dispatch(baseActions.setChoices(newChoices));
        console.log(newChoices);
        console.log('profitImpact: ', RatesIncreaseOptions[ratesIndex].options[optionIndex].profitImpact);
        dispatch(baseActions.adjustProjection(RatesIncreaseOptions[ratesIndex].options[optionIndex].profitImpact));
    }, []);

    return (
        <>
            <div className="title titleText">
                Spend result
            </div>
            {/* <p className='bold'>{RatesIncreaseOptions[ratesIndex].options[optionIndex].label}</p> */}
            <p dangerouslySetInnerHTML={{ __html: RatesIncreaseOptions[ratesIndex].options[optionIndex].result }} />
            <Button text="Continue" className='nextButton' onClick={() => { dispatch(baseActions.setCurrentPage(GrantSpendActionPage)); }} />
        </>
    );
}
export default RatesIncreaseResultPage;

