import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultProps } from './DefaultProps';
import { HasQuizAnswer, IQuizQuestion } from '../lib/quiz';
import { Text24, Text28, Text32 } from '../util/css';


interface Props extends DefaultProps, HasQuizAnswer {
    children?: React.ReactNode;
    onSelect?: (value: any) => void;
    selected?: string;
    disabled?: boolean;
}

const QuizSelect: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        position: relative;
        z-index: 2000;
        
        // select {
        //     -webkit-appearance: none;
        //     -moz-appearance: none;
        //     background: blue;
        //     background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        //     background-repeat: no-repeat;
        //     background-position-x: 100%;
        //     background-position-y: 5px;
        //     border: 1px solid #dfdfdf;
        //     border-radius: 2px;
        //     margin-right: 2rem;
        //     padding: 1rem;
        //     padding-right: 2rem;
        //   }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            /* Some browsers will not display the caret when using calc, so we put the fallback first */ 
            background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
            background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 20px) !important; /* Better placement regardless of input width */
            
            &:disabled {
                color: inherit;
                opacity: inherit;
                border: 2px solid ${props.correct? "green": "red"};
                -moz-appearance: none;
                -webkit-appearance: none;
            }
            box-sizing: border-box;
            cursor: pointer;

            // /* Auto layout */
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
            // align-items: center;
            padding: 16px 18px;
            gap: 18px;

            width: 100%;

            background: #FFFFFF;
            border: 2px solid #A6A6AA;
            border-radius: 8px;

            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 400;
            // font-size: 24px;
            // line-height: 29px;
            ${Text28}

            /* Inside auto layout */
            // flex: none;
            // order: 1;
            // align-self: stretch;
            // flex-grow: 0;
            
            &:focus {
                outline: 0;
            }
        }

        label {
            display: block;
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: #212129;
        }

        option {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: left;
            color: #7A7A80;
        }
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const [selected, setSelected] = React.useState<string|undefined>(props.selected);

    const onChange = (e: any) => {
        const val = (e.target as HTMLSelectElement).value;
        if (props.onSelect)
            props.onSelect(val);
            console.log(val);
        setSelected(val);
    }
    return (
        <>
            <label>
                <select id={props.id} className={props.className} defaultValue={selected}
                    onChange={onChange}
                    disabled={props.disabled}
                >
                    {props.children}
                </select>
            </label>
        </>
    );
}
export default QuizSelect;