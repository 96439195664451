import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BizModel, BizType } from './bizModels';
import { BeautyIcon, CoffeeIcon, HomewaresIcon } from '../util/SVG';

export const randomString = (myLength = 32) => {
    const chars =
        "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
    const randomArray = Array.from(
        { length: myLength },
        () => chars[Math.floor(Math.random() * chars.length)]
    );

    const randomString = randomArray.join("");
    return randomString;
};

export const handleScreenReaderEnterKey = (e: any, onClick: () => any) => {
    const enterKey = 13;
    const spaceKey = 32;
    if (e.keyCode === enterKey || e.keyCode === spaceKey) {
        e.preventDefault();
        onClick();
    }
}

export const randomInt = (min: number, max: number) => {
    // min = Math.ceil(min);
    // max = Math.floor(max);
    // return Math.floor(Math.random() * (max - min + 1)) + min;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getOutcome = (profitImpact: number) => {
    const className = profitImpact < 1 ? "penalty" : "reward";
    if (profitImpact === 1) {
        return (
            <div className="neutral">
                Neutral
            </div>
        );
    }

    return (
        <div className={className}>
            {className.toUpperCase()}: {profitImpact > 1 ? "+" : ""}{Math.round((1 - profitImpact) * -100)}%
        </div>
    );
}

export const confirmDialog = (message: string, title: string, options: Array<{ label: string, onClick: () => void }>) => {
    confirmAlert({
        title,
        message,
        buttons: options
    });
}

export const getBizIcon = (biz: BizModel) => {
    if (biz.type === "Beauty")
        return BeautyIcon;
    else if (biz.type === "Coffee")
        return CoffeeIcon;
    else return HomewaresIcon;
}

export type TestingData = Array<
    { biz: BizType, option: string, profitImpact: number }
>

// export const highlightQuizResponses