import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Dialogue from '../../components/pageTemplates/Dialogue';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import WhatsInANumber from './WhatsInANumber';
import { BizModel } from '../../lib/bizModels';


interface PostSelectionQuizProps {

}

const PostSelectionQuiz: React.FunctionComponent<PostSelectionQuizProps> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <PageTemplate>
            {/* <Element className=''> */}
                <InnerElement {...props} />
            {/* </Element> */}
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<PostSelectionQuizProps> = (props) => {
    const model:BizModel = useSelector((state: any) => state.base.selectedModel);
    const dispatch = useDispatch();
    return (
        <Dialogue
            // title="Have you got what it takes?"
            buttons={
                <>
                    <Button className="nextButton" text="Let's see" onClick={() => {
                        dispatch(baseActions.setCurrentPage(WhatsInANumber));
                    }} />
                </>
            }
        >
            <p className="bold">
                For your business to make a profit, you're going to need revenue.
            </p>
            <p>
                Revenue is the amount of money made through sales. But how do you calculate it?
                </p>
        </Dialogue>
    );
}
export default PostSelectionQuiz;