import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { baseActions } from '../state/base';
import Button from '../components/Button';
import GameLogo from '../components/GameLogo';
import WelcomePage from './setup/welcome';


interface CoverPageProps {

}


const CoverPage: React.FunctionComponent<CoverPageProps> = (props) => {
    const Element = styled('div')`
        :root {
            overflow: hidden;
        }
        padding: 0;
        margin: 0;
        > div {
            padding: 0;
            margin: 0;
            min-height: 700px;
            max-height: 100vh;
            min-width: 1300px;
            max-width: 100vw;
        }
        > div > * {
            overflow: hidden;
        }
        min-width: 1360px;
        .leftBar{
            position: fixed;
            left:0; top: 0;
            width: 47px;
            height: 100%;
            background-color: #DC1928;
        }
        .main {
            margin-left: 87px;
            width: calc(100vw - 135px);
            height: 100vh;
            width: 100%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .contentArea {
            max-height: 100vh;
            overflow: hidden;
            max-width: 864px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .titleText {
                margin-top: 100px;
                width: 100%;
                .title {
                    
                    margin: 0;
                    width: 100%;
                    height: 208px;

                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 88px;
                    line-height: 104px;
                    color: #212129;
                }
                .subtitle {
                    margin: 0;
                    width: 100%;
                    height: 56px;

                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 48px;
                    line-height: 56px;
                    color: #DC1928;
                }
            }
            
        }
        .button {
            width: 300px;
            height: 70px;
        }
        .aupGameLogo {
            position: relative;
            left: -20px;
            width: 408px;
            height: 102px;
        }
        .coverImage {
            width: 40vw;
            max-width: calc(100vw - 187px);
            height: 100%;
            img {
                height: 100vh;
                width: auto;
                max-width: calc(100vw - 287px);
                object-fit: cover;
            }
        }
    `;
    return (
        <Element className='noScroll' tabIndex={0}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<CoverPageProps> = (props) => {
    const dispatch = useDispatch();
    return (
        <div>
            <div className='leftBar' />
            <div className='main'>
                <div className='contentArea'>
                    <div className="titleText">
                        <h1 className='title'>eCommerce Learning Game</h1>
                        <p className='subtitle'>Running an eCommerce business</p>
                    </div>
                    <Button className="button" text='Get Started' onClick={() => dispatch(baseActions.setCurrentPage(WelcomePage))} />
                    <GameLogo className='aupGameLogo' />
                </div>
                
                <div className="coverImage">
                    <img src="/images/cover.png" />
                </div>
            </div>
        </div>
    );
}
export default CoverPage;