import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { DownChevron, UpChevron, WhiteCloseIcon } from '../../util/SVG';
import { DefaultProps } from '../../components/DefaultProps';
import { CursorPointer, FlexCenter, SubtitleText, Text20 } from '../../util/css';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import StartupSpendPage from '../yearOne/StartupSpend';
import ProfitProjection from '../../components/ProfitProjection';
import { BizModel } from '../../lib/bizModels';
import Button from '../../components/Button';


interface TipOverlayProps extends DefaultProps {
    title: string;
    children: React.ReactNode;
    position?: string;
    chevron?: "down" | "up" | "right";
    hideClose?: boolean;
}

export const TipOverlay: React.FunctionComponent<TipOverlayProps> = (props) => {

    const Element = styled('div')`
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        

        position: fixed;
        ${props.position && props.position}
        user-select: none;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            padding: 30px 40px 40px;
            gap: 80px;

            max-width: 40vw;
            // width: 638px;
            // height: 198px;

            background: #31313D;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
            border-radius: 8px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
            .content {
                margin-left: 0px !important;
                background: #31313D;
                display: flex;
                flex-direction: column;
                padding: 0px;

                .title {
                    color: #fff;
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;
                    padding: 0px;
                    gap: 24px;
                    margin-left: 0px !important;
                    margin-bottom: 20px;

                    .label {
                        display: flex;
                        flex-direction: row;
                        padding: 0px;

                        margin: 0;

                        font-family: 'AP Type Pro Text';
                        font-style: normal;
                        font-weight: 500;
                        // font-size: 40px;
                        // line-height: 48px;
                        ${SubtitleText}
                    }
                    .closeIcon{
                        display: ${props.hideClose ? "none" : "block"};
                        position: absolute;
                        right: 20px;
                        ${CursorPointer}
                    }
                }
                .tip {
                    
                    max-width: 500px;
                    
                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 400;
                    // font-size: 20px;
                    // line-height: 32px;
                    ${Text20}
                    
                    color: #FFFFFF;
                }
            }
            .downChevron {
                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
                
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 100%;
                bottom: -100%;
                
                svg {
                    text-align: center;
                    transform: rotate(-180deg);
                }
                
            }
            
            

            .rightChevron {
                width: 53px;
                position: absolute;
                right: -38px;
                top: 0px; 
                bottom: 0px;
                margin: auto 0;
                transform: rotate(90deg);
            }
        }
        &.right .upChevron  {
            position: absolute;
            top: -25px;
            left: unset;
            margin: unset;
            right: 120px;
        }
        .upChevron {
            position: absolute;
            // left: 0;
            // right: 0;
            // margin: 0 auto;
            z-index: 2000000000000;
            top: -25px;
        }
    `;
    return (
        <Element id={props.id} className={props.className} >
            <TipInnerElement {...props} />
        </Element>
    );
}

const TipInnerElement: React.FunctionComponent<TipOverlayProps> = (props) => {
    return (
        <>
            {props.chevron === "up" && <UpChevron className="upChevron" />}

            <div>


                <div className='content'>
                    <div className="title">
                        <div className="label">
                            {props.title}
                        </div>

                        <div className="closeIcon" onClick={props.onClick}>
                            <WhiteCloseIcon />
                        </div>
                    </div>

                    <div className="tip">
                        {props.children}
                    </div>
                    {props.chevron === "right" && <UpChevron className="rightChevron" />}
                </div>
                {props.chevron === "down" && <DownChevron className="downChevron" />}

            </div>
        </>
    );
}
// export default TipOverlay;

interface Props { }

const NavigatingTheChallengePage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        height: 100%;
        .projectionTest {
            ${FlexCenter}
            gap: 20px;
        }
        .projection {
            position: absolute;
            right: 110px;
            top: 90px;
        }
    `;
    return (<Element>
        <InnerElement {...props} />
    </Element>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const [tipIndex, setTipIndex] = React.useState(0);
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const [showChange, setShowChange] = React.useState(false);
    return (
        <PageTemplateUpperText
            showNav={tipIndex > 0}
            whiteFill
            // noBackground
            textContent={<TextContentArea title="Navigating this challenge"
            >
                {/* <div className="projectionTest">
                    projection testing
                    <Button text="toggle change" onClick={() => setShowChange(!showChange)} />
                    <Button text="+20%" onClick={() => { dispatch(baseActions.adjustProjection(.2)); }} />
                    <Button text="-30%" onClick={() => { dispatch(baseActions.adjustProjection(-.3)); }} />
                </div>
                {tipIndex == 4 &&
                    <ProfitProjection showChange={showChange} className='projection' />
                } */}
                {tipIndex === 0 &&
                    <TipOverlay
                        title='Timeline'
                        chevron="down"
                        position="bottom: 150px;left:35%;"
                        onClick={() => setTipIndex(1)}
                    >
                        This line indicates the time period you're playing in. You have three years to hit your target profit.
                    </TipOverlay>
                }
                {tipIndex === 1 &&
                    <TipOverlay
                        className=''
                        title='Advice from the expert'
                        chevron="up"
                        position="top: 105px;right:113px;"
                        onClick={() => setTipIndex(2)}
                    >
                        If you need help during the game, you can click on the consultant's advice.
                        <br />
                        To start, this advice is free but as your needs become more complex it'll start to cost you.
                    </TipOverlay>
                }
                {tipIndex === 2 &&
                    <TipOverlay
                        className='right'
                        title='Check your set up'
                        chevron="up"
                        position="top: 105px;right:60px;"
                        onClick={() => setTipIndex(3)}
                    >
                        Need a reminder of your business setup? Click here for a summary.
                    </TipOverlay>
                }
                {tipIndex === 3 &&
                    <div>
                        <ProfitProjection showChange={showChange} className='projection' />
                        <TipOverlay
                            hideClose
                            className=''
                            title='How your business is tracking'
                            chevron="right"
                            position="top: 24vh;right:500px;"

                        >
                            <p>The purple bar indicates your target profitability for the full three years as a fixed amount.</p>
                            <p>As you make decisions in the game, the red bar will indicate the impact on your projected profitability so you can keep track of how you’re doing.</p>
                            <Button text="Got it" className='nextButton' onClick={() => dispatch(baseActions.setCurrentPage(StartupSpendPage))} />
                        </TipOverlay>
                    </div>
                }

            </TextContentArea>
            }
        >
        </PageTemplateUpperText >
    );
}
export default NavigatingTheChallengePage;