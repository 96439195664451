import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch } from 'react-redux';
import { Metric10, Metric20, Metric40, Metric60, Metric70 } from '../../util/SVG';
import Button from '../../components/Button';
import StartupSpendResultPage from './StartupSpendResult';
import BlackFridayResultPage from './BlackFridayResult';
import BlackFridayAdvice from '../../components/advice/BlackFridayAdvice';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import { TestingData } from '../../lib/util';

export const gety1s2TestData = (): TestingData => {
    return [
        { biz: "Coffee", option: "staff", profitImpact: -.2 },
        { biz: "Coffee", option: "campaign", profitImpact: .1 },
        { biz: "Beauty", option: "staff", profitImpact: -.2 },
        { biz: "Beauty", option: "campaign", profitImpact: .1 },
        { biz: "Homewares", option: "staff", profitImpact: .1 },
        { biz: "Homewares", option: "campaign", profitImpact: -.3 },
    ]
}

export const blackFridayOptions = {
    "Coffee": {
        "staff": {
            content: "Wow, your new staff member had quite a task ahead of them! You've managed to maintain your Net Promoter Score by providing exceptional customer service during this storm, but it has come at a significant operational cost and it may take you some months to recover your traffic.",
            profitImpact: -.2
        },
        "campaign": {
            content: "Well done! Although your customers didn't get a personalised response to their order delays, many are enticed to redeem their vouchers with you and place another order in the coming half. You have managed to maintain your sales trajectory despite taking a hit to you Net Promoter Score.",
            profitImpact: .1
        }
    },
    "Beauty": {
        "staff": {
            content: "Wow, your new staff member had quite a task ahead of them! You've managed to maintain your Net Promoter Score by providing exceptional customer service during this storm, but it has come at a significant operational cost and it may take you some months to recover your traffic.",
            profitImpact: -.2
        },
        "campaign": {
            content: "Well done! Although your customers didn't get a personalised response to their order delays, many are enticed to redeem their vouchers with you and place another order in the coming half. You have managed to maintain your sales trajectory despite taking a hit to your Net Promoter Score.",
            profitImpact: .1
        }
    },
    "Homewares": {
        "staff": {
            content: "Wow, your new staff member had quite a task ahead of them! You've managed to maintain your Net Promoter Score by providing exceptional customer service to the customers impacted. Your staff member also finds some extra time to pick & pack orders, increasing your fulfilment capacity. <br/><br/> You've offered a personal touch that resonates with your customer segment. Despite the setbacks, you manage to stay on track to forecast.",
            profitImpact: .1
        },
        "campaign": {
            content: "While the email campaign was successful in driving existing customers back to your website to spend their voucher, you simply didn't have enough capacity to fulfil all the additional orders. Remember that you need to balance your marketing investment with your capacity to fulfil.",
            profitImpact: -.3
        }
    }
}

interface Props { }

const BlackFridayPage: React.FunctionComponent<Props> = (props) => {
    return (
        <BusinessActionTemplate
            optionCount={2}
            decisionTitle='Your second major decision for Year 1'
            era="y1s2"
            advice={
                <>
                    <div className="bold">
                        Free advice from your consultant
                    </div>
                    <div>Have you ever had an order take aaaages to arrive? Yep, we have too, and it wasn't fun.</div>
                    <div>If you don't handle this storm correctly, your customers will not be happy, and they may even spread the word to their friends. You may be faced with a lower Net Promoter Score (customer satisfaction score) which will lower your customer retention and order frequency for the rest of the game.</div>
                    <div>How will you ensure that your customers want to shop with you again? The action you take very much depends on the type of business you have and the decisions you’ve made so far.</div>
                </>
            }
            showNav

        >
            <InnerElement {...props} />
        </BusinessActionTemplate >
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y1s2"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([BlackFridayResultPage, { choice: activeCard }]));
    }
    return (
        <>
            <p>Black Friday arrives and mayhem hits.</p>
            <p>
                Your shipping partner is experiencing an enormous backlog, leading to shipping delays, cancelled orders and grumpy customers. What do you do?
            </p>
            <p>
                Before you confirm, think about why you have chosen your approach. What are the implications?
            </p>
            <p className='bold'> Here's what's available. Choose one approach:</p>

            <div className="cardPanel">
                <div className={`card ${activeCard === "staff" && "active"}`} id="staffCard" onClick={() => setActiveCard(('staff'))}>
                    <div>
                        Hire an additional temporary staff member to handle customer service requests and respond to customer enquiries
                    </div>
                </div>
                <div className={`card ${activeCard === "campaign" && "active"}`} id="campaignCard" onClick={() => setActiveCard(('campaign'))}>
                    <div>
                        Send out an email campaign to all customers that ordered during the impacted period, offering an apology and a $10 voucher to spend on their next order
                    </div>
                </div>
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />
            }
        </>
    );
}
export default BlackFridayPage;