import { css } from "@emotion/react";

export const FlexCenterClass = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CursorPointer = `
    user-select: none;
    cursor: pointer;
`;

export const TitleText = `
    font-size: max(min(3vw, 56px), 24px);
    line-height: max(min(4vw, 67px), 30px);
`
export const SubtitleText = `
    font-size: max(min(2.05vw, 40px), 18px);
    line-height: max(min(2.9vw, 50px), 22px);
`
export const Text32 = `
    font-size: max(min(1.7vw, 32px), 16px);
    line-height: max(min(2.5vw, 40px), 20px);
`
export const Text28 = `
    font-size: max(min(1.6vw, 28px), 14px);
    line-height: max(min(2.35vw, 36px), 18px);
`

export const Text24 = `
    font-size: max(min(1.5vw, 24px), 12px);
    line-height: max(min(2.2vw, 32px), 16px);
`
export const Text20 = `
    font-size: max(min(1.4vw, 20px), 10px);
    line-height: max(min(1.8vw, 28px), 14px);
`
export const Text16 = `
    font-size: max(min(1.3vw, 16px), 8px);
    line-height: max(min(1.8vw, 20px), 12px);
`