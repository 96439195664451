import * as React from 'react';
import styled from '@emotion/styled';
import { CursorPointer, FlexCenter } from '../util/css';
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { RedCloseIcon, RedWrong, WhiteUpChevron } from '../util/SVG';
import { BizModel } from '../lib/bizModels';
import { getBizIcon } from '../lib/util';
import { DefaultProps } from './DefaultProps';


interface Props extends DefaultProps {
    onClose: () => void;
}

const CheckSetup: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        position: fixed;
        top: 110px;
        right: 30px;
        z-index: 5000;
        padding: 20px 40px;

        .closeIcon {
            position: absolute;
            right: 25px;
            top: 20px;
            svg {width: 45px;}
            ${CursorPointer}
        }
        .upChevron {
            position: absolute;
            right: 145px;
            top: -25px;
        }
        .setup {
            width: 400px;
            ${FlexCenter}
            flex-direction: column;
            > * {
                width: 100%;
                margin-bottom: 20px;

            }
            .header{
                display: flex;
                gap: 20px;
                align-items: center;
                
                svg {
                    width: 56px;
                }
                .name {
                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 48px;

                    color: #000000;
                }
                
            }
            .offering {
                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: #000000;
            }
            .setupItems {
                display: flex;
                gap: 40px;
                > div {
                    width: 180px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
                .setupItem {
                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 19px;
                    line-height: 30px;
                    .name {
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    `;
    return (
        <Element className={props.className}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const Icon = getBizIcon(model);
    return (
        <>
            <div className="upChevron">
                <WhiteUpChevron />
            </div>
            <div className="setup">
                <div className='header'>
                    <Icon />
                    <div className="name">
                        {model.name}
                    </div>
                </div>
                <div className="offering">
                    {model.setup.offering}
                </div>
                <div className='setupItems'>
                    <div>
                        <div className='setupItem'>
                            {/* <div className="name">Offering:</div>
                            <div className="value">
                                {model.setup.offering}
                            </div> */}
                            <div className="name">Web platform:</div>
                            <div className="value">
                                {model.setup.webPlatform}
                            </div>
                        </div>
                        <div className='setupItem'>
                            <div className="name">Fulfilment model:</div>
                            <div className="value">
                                {model.setup.fulfillmentModel}
                            </div>
                        </div>
                        <div className='setupItem'>
                            <div className="name">Stock lead time:</div>
                            <div className="value">
                                {model.setup.stockLeadTime}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='setupItem'>
                            <div className="name">Shipping policy:</div>
                            <div className="value">
                                {model.setup.shippingPolicy}
                            </div>
                        </div>
                        <div className='setupItem'>
                            <div className="name">Shipping model:</div>
                            <div className="value">
                                {model.setup.shippingModel}
                            </div>
                        </div>
                        <div className='setupItem'>
                            <div className="name">Change of mind return policy:</div>
                            <div className="value">
                                {model.setup.comReturnPolicy}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="closeIcon" onClick={() => props.onClose()}>
                <RedCloseIcon/>
            </div>
        </>
    );
}
export default CheckSetup;