import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import Dialogue from '../../components/pageTemplates/Dialogue';
import RatesIncreaseActionPage from './RatesIncreaseAction';

interface Props { }

const Y3SeguePage: React.FunctionComponent<Props> = (props) => {

    const Element = styled('div')`
        .ready {
            display: inline-block;
            margin: 0px 0px 30px;
        }
        ol li {
            list-style-type: none;
            counter-increment: step-counter;
        }
        ol li:before {
            font-family: sans-serif;
            font-weight: 700;
            content: counter(step-counter) ") ";
        }
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const modalActive = useSelector((state: any) => state.base.modalActive);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = React.useState(false)
    return (
        <Dialogue
            title="Well done! You've made it through your second year of operation."
            buttons={
                <>
                    <Button className="" text="Continue" onClick={() => {
                        dispatch(baseActions.setCurrentPage(RatesIncreaseActionPage));
                    }} />
                </>
            }
        >
            <p>
                How is your 3-year profit trajectory looking? Consider what you have learned so far about:
            </p>
            <ol>
                <li><span className='bold'>Average order value</span> (average order value for a single transaction)</li>
                <li><span className='bold'>Conversion rate</span> (percentage of traffic that ended up as transactions)</li>
                <li><span className='bold'>Shipping policy</span></li>
            </ol>
            <p>
                Keep monitoring and analysing your results, especially after a change of policy. Your data will tell you a lot about how your customers buy from you, which will reveal factors that affect their decision-making.
            </p>
            <p>
                Average order value , Conversion Rate, and shipping policy will continue to be important factors in your business as you're moving into your third year.
            </p>
        </Dialogue>
    );
}
export default Y3SeguePage;