import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch, useSelector } from 'react-redux';
import { Metric10, Metric20, Metric40, Metric60, Metric70 } from '../../util/SVG';
import Button from '../../components/Button';
import BlackFridayAdvice from '../../components/advice/BlackFridayAdvice';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import ChallengeContinuesResultPage, { ChallengeKeys, KeysType } from './ChallengeContinuesResult';
import { TestingData } from '../../lib/util';
import { BizType } from '../../lib/bizModels';


export const gety2s1TestData = (blackFridayChoice: string): TestingData => {
    const challengeIndex = blackFridayChoice === "staff" ? 0 : 1;
    const branches: Array<Array<{ biz: BizType, option: string, profitImpact: number }>> = [[
        { biz: "Coffee", option: "ads", profitImpact: .2 },
        { biz: "Coffee", option: "tool", profitImpact: 0 },
        { biz: "Beauty", option: "ads", profitImpact: .2 },
        { biz: "Beauty", option: "tool", profitImpact: 0 },
        { biz: "Homewares", option: "ads", profitImpact: -.1 },
        { biz: "Homewares", option: "tool", profitImpact: .3 },
    ],
    [
        { biz: "Coffee", option: "delivery", profitImpact: .1 },
        { biz: "Coffee", option: "personalisation", profitImpact: .02 },
        { biz: "Beauty", option: "delivery", profitImpact: .05 },
        { biz: "Beauty", option: "personalisation", profitImpact: -.02 },
        { biz: "Homewares", option: "delivery", profitImpact: 0 },
        { biz: "Homewares", option: "personalisation", profitImpact: .1 },
    ]]
    return branches[challengeIndex];
}

export const ChallengeOptions = [
    {
        content: <>
            <p>You weathered the Black Friday storm, but you've noticed that your sales have stagnated. You need to invest in your business, but where?</p>
            <p className='bold'>
                Which approach do you want to take? Choose one option:
            </p>
        </>,
        options: {
            "ads": "Invest in Google Ads",
            "tool": "Introduce a smart merchandising tool on your website to make it easier for customers to find and explore products"
        },
        consultantAdvice:
            <>
                <div className="bold">
                    Free advice from your consultant
                </div>
                <div>What is most important to you right now? Traffic or conversion? The right answer will depend on your business maturity and of course the decisions you’ve made so far.</div>
                <div>Investing in google ads increases your marketing spend, but will bring more customers to your website.</div>
                <div>Investing in smarter merchandising won't bring more customers, but you may be more likely to convert those that are already coming.</div>
            </>,
        "Coffee": {
            "ads": {
                label: "Google Ads",
                content: "Terrific. An investment in new customers is exactly what your business needs at this stage of the game. Your traffic increases and so do your orders.",
                imperative: "",
                profitImpact: .2
            },
            "tool": {
                label: "Merchandising tool",
                content: `Well, your website conversion rate is looking pretty strong, but unfortunately your traffic is still fairly low, which means you're not doing the volume that would make a material difference to your growth trajectory.`,
                imperative: "Perhaps you would have been better off growing your customer base and website traffic at this stage of the game.",
                profitImpact: 0
            }
        },
        "Beauty": {
            "ads": {
                label: "Google Ads",
                content: "Terrific. An investment in new customers is exactly what your business needs at this stage of the game. Your traffic increases and so do your orders.",
                imperative: "",
                profitImpact: .2
            },
            "tool": {
                label: "Merchandising tool",
                content: `Well, your website conversion rate is looking pretty strong, but unfortunately your traffic is still fairly low, which means you're not doing the volume that would make a material difference to your growth trajectory.`,
                imperative: "Perhaps you would have been better off growing your customer base and website traffic at this stage of the game.",
                profitImpact: 0
            }
        },
        "Homewares": {
            "ads": {
                label: "Google Ads",
                content: "D'oh! Remember that you still can't fulfil orders for the traffic you already have, given you're still operating out of your garage. Your marketing dollars have been wasted.",
                imperative: "",
                profitImpact: -.1
            },
            "tool": {
                label: "Merchandising tool",
                content: "Good stuff. The investment you've made in improving your website has made it easier for customers to group matching homewares in their basket, making a significant impact on average order value. You're still fulfilling the same number of orders, but the profitability of each order has increased dramatically.",
                imperative: "",
                profitImpact: .3
            }
        }
    },
    {
        content:
            <>
                <p>While traffic is at an all-time high and orders are coming through at pace, you've noticed your Net Promoter Score has fallen pretty significantly. Your customers need your attention. How will you do that?</p>
                <p className='bold'>
                    Here’s what’s available. Choose one option:
                </p>
            </>,
        options: {
            "delivery": "Make some changes to your fulfilment processes and introduce a \'same day\' delivery promise (i.e. despatch orders on the same day that they are received)",
            "personalisation": "Introduce personalisation in your email communications (eg. using customers' first names and introducing personalised product recommendations for them)"
        },
        consultantAdvice:
            <>
                <div className="bold">
                    Free advice from your consultant
                </div>
                <div>How well do you know your customer?</div>
                <div>Think about your product category and what types of strategies will make an impact to your customers' satisfaction?</div>
                <div>Does your customer care about getting your product exactly when they want it?</div>
                <div>Does your customer care about being understood as an individual? Are they inspired by personalised messages?</div>
                <div>The right path will depend on your business and the profile of your customers.</div>
            </>,
        "Coffee": {
            "delivery": {
                label: "Same day delivery",
                content: "Fantastic. A same day delivery promise resonates incredibly well with coffee customers, and your Net Promoter Score improves, resulting in customers returning more often.",
                imperative: "",
                profitImpact: .1
            },
            "personalisation": {
                label: "Email personalisation",
                content: "Your email open rates have improved, but unfortunately your personalisation strategy has done little to turn the dial on Net Promoter Score You see a marginal improvement in your trajectory.",
                imperative: "",
                profitImpact: .02
            }
        },
        "Beauty": {
            "delivery": {
                label: "Same day delivery",
                content: "Fantastic. A same day delivery promise resonates very well for beauty customers, and your Net Promoter Score improves, resulting in customers returning more often.",
                imperative: "",
                profitImpact: .05
            },
            "personalisation": {
                label: "Email personalisation",
                content: "Your email open rates have improved, but unfortunately your personalisation strategy has done little to turn the dial on Net Promoter Score. You see a marginal improvement in your trajectory.",
                imperative: "",
                profitImpact: .02
            }
        },
        "Homewares": {
            "delivery": {
                label: "Same day delivery",
                content: "Same day delivery doesn't resonate strongly with your customer base given the relative infrequency of purchase, so this strategy does little to turn the dial on Net Promoter Score.",
                imperative: "",
                profitImpact: 0
            },
            "personalisation": {
                label: "Email personalisation",
                content: "Terrific. As lovers of artisan products, your customer base enjoys a personalised experience. Your Net Promoter Score increases.",
                imperative: "",
                profitImpact: .1
            }
        }
    }
]

interface Props { }

const ChallengeContinuesPage: React.FunctionComponent<Props> = (props) => {
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const blackFridayChoice = choices.blackFriday as "staff" | "campaign";
    const challengeIndex = blackFridayChoice === "staff" ? 0 : 1;
    return (
        <BusinessActionTemplate
            era="y2s1"
            advice={ChallengeOptions[challengeIndex].consultantAdvice}
            optionCount={ChallengeKeys[blackFridayChoice].length}
            showNav
            decisionTitle='Your challenge continues'
        >
            <InnerElement {...props} />
        </BusinessActionTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y2s1"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([ChallengeContinuesResultPage, { choice: activeCard }]));
    }

    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const blackFridayChoice = choices.blackFriday as "staff" | "campaign";
    const challengeIndex = blackFridayChoice === "staff" ? 0 : 1;

    return (
        <>
            {ChallengeOptions[challengeIndex].content}
            <div className="cardPanel">
                {ChallengeKeys[blackFridayChoice].map((val, index) => {
                    return (
                        <div className={`card ${activeCard === val && "active"}`} id={`${val}Card`} onClick={() => setActiveCard((val))}>
                            {ChallengeOptions[challengeIndex].options[val as KeysType] || ""}
                        </div>
                    )
                })}
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />
            }
        </>
    );
}
export default ChallengeContinuesPage;