import * as React from 'react';
import styled from '@emotion/styled';
import DollarValue from './DollarValue';
import { useSelector } from 'react-redux';
import { BizModel } from '../lib/bizModels';
import { DefaultProps } from './DefaultProps';
import { BlackDownArrow } from '../util/SVG';
import { FlexCenter, Text20, Text24, Text32 } from '../util/css';
import { keyframes } from '@emotion/react';
import { transform } from 'typescript';

interface ProfitProjectionProps extends DefaultProps {
    target?: number;
    projected?: number;
    lastImpact?: number;
    graphHeight?: string;
    showChange?: boolean;
}

const ProfitProjection: React.FunctionComponent<ProfitProjectionProps> = (props) => {

    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const previousProjectedProfit: number = useSelector((state: any) => state.base.previousProjectedProfit);
    const lastDecisionImpact: number = useSelector((state: any) => state.base.lastDecisionImpact);
    const currentEra: string = useSelector((state: any) => state.base.currentEra);

    const target = props.target || model.targetProfit;
    const projected = (props.projected || projectedProfit);
    const formerProjected = props.projected || projectedProfit;
    const change = props.lastImpact || lastDecisionImpact;


    console.log(target, projected, projected / target);
    const height = props.graphHeight || "500px";
    const columnWidth = "178px";
    const axisRatio = 3 / 2; // ratio of total area above axis

    const axisY = `calc(${height} / ${axisRatio})`;
    const baseHeight = "95px";
    const projectedHeight = `calc(${baseHeight} * ${Math.abs(projected / target)})`;
    const formerProjectedHeight = () => {
        return currentEra === "y1s1" ? `calc(baseHeight + 200px)` :
            ((previousProjectedProfit >= 0 && projectedProfit <= 0) || (previousProjectedProfit <= 0 && projectedProfit > 0)) ?
                `calc(-1 * ${baseHeight} * ${Math.abs(previousProjectedProfit / target)})`
                :
                // projectedProfit < 0 ?
                //     `calc(-1 * ${baseHeight} * ${Math.abs(previousProjectedProfit / target)})`
                //     :
                    `calc(${baseHeight} * ${Math.abs(previousProjectedProfit / target)})`;
    }
    const getProjectedTop = (height?: string) => {
        // if(currentEra==="y1s1") return `calc(${axisY} + ${baseHeight})`;
        if (projected >= 0) return `calc(${axisY} - ${height || projectedHeight})`;
        return axisY;
    }
    console.log(previousProjectedProfit)
    console.log(`projectedHeight: ${projectedHeight}`)
    console.log(`formerProjectedHeight: ${formerProjectedHeight()}`)
    const projectionAnimationAboveToBelow = keyframes`
        0% {
            top: calc(${axisY} + ${formerProjectedHeight()});
            height: calc(-1 * ${formerProjectedHeight()})
        }
        50% {
            top: ${axisY};
            height: 0px;
        }
        100% {
            top: ${axisY};
            height: calc(${projectedHeight});
            padding: 0;
            margin: 0;
        }
    `;
    const projectionAnimationBelowToAbove = keyframes`
        0% {
            top: ${axisY};
                height: calc(${projectedHeight});
                padding: 0;
                margin: 0;
        }
        50% {
            top: ${axisY};
            height: 0px;
        }
        100% {
            top: calc(${axisY} + ${formerProjectedHeight()});
            height: calc(-1 * ${formerProjectedHeight()})
                
        }
    `;
    const projectionAnimationAbove = keyframes`
        from {
            top: ${getProjectedTop(formerProjectedHeight())};
            height: ${formerProjectedHeight()};
        }
        to {
            top: ${getProjectedTop()};
            height: ${projectedHeight};
        }
    `;
    const projectionAnimationBelow = keyframes`
    ${change < 0 ?
            `
        from {
            top: ${axisY};
            height: calc(${formerProjectedHeight()});
            padding: 0;
            margin: 0;
        }

        to {
            top: ${axisY};
            height: calc(${projectedHeight});
            padding: 0;
            margin: 0;
        }
        `
            :
            `
        from {
            top: ${axisY};
            height: calc(${formerProjectedHeight()});
            padding: 0;
            margin: 0;
        }
        to {
            top: ${axisY};
            height: calc(${projectedHeight} + 00px);
            padding: 0;
            margin: 0;
        }
        `
        }
    `;

    const Element = styled('div')`
        position: relative;
        width: calc(${columnWidth} * 2);
        height: 610px;
        .axis {
            position: absolute;
            width: 100%;
            height: ${axisY};
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            z-index: 2;
        }
        .componentLabel {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 500;
            ${Text32}
            color: #212129;
            margin-bottom: 10px
        }
        .graph {
            position: relative;
        }
        .targetColumn {
            position: absolute;
            top: calc(${axisY} - ${baseHeight});
            width: 178px;
            height: ${baseHeight};
            background: #892D52;
            text-align: center;
            .targetColumnLabel {
                position: absolute;
                bottom: -40px;
                left: 0; right: 0;
                margin: 0 auto;
            }
            .targetAmountLabel {
                padding-top: 20px;
                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 700;
                ${Text24}
                color: #FFFFFF;
            }

        }
        .targetColumnLabel, .projectedColumnLabel {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 500;
            ${Text20}
            text-align: center;
            color: #212129;
        }
        .projectedColumn {
            &.showChange {
                // animation: ${projectionAnimationAboveToBelow} 2s linear;
                animation: ${previousProjectedProfit > 0 && projected < 0 ? projectionAnimationAboveToBelow :  previousProjectedProfit < 0 && projected > 0? projectionAnimationBelowToAbove: projected > 0 ? projectionAnimationAbove : projectionAnimationBelow} 0.8s linear;
            }
            position: absolute;

            top: ${getProjectedTop()};
            left: 178px;
            width: 178px;
            height: ${projectedHeight};
            background: #CA3332;
            text-align: center;
            .projectedColumnLabel {
                position: absolute;
                bottom: ${projected >= 0 ?
            `-40px`
            :
            `calc(-40px)`
        };
                left: 0; right: 0;
                margin: 0 auto;
            }
            &.showChange .changeLabel {
                visibility: visible;
            }
            .changeLabel {
                visibility: hidden;
                ${FlexCenter}
                gap: 10px;
                position: relative;
                bottom: ${projected > (target / 2) ? "100px" : "85px"};
                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                ${Text24}
                text-align: center;
                color: #000000;
                svg {
                    &.invert {
                        transform: rotate(180deg);
                    }
                    &.hide {
                        display: none;
                    }
                }
            }
            .projectedAmountLabel {
                position: relative;
                padding-top: 20px;
                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 700;
                ${Text24}
                color: ${(projected > (target / 2) || projected <= -(target / 1.5)) ?
                    "#FFFFFF" : projected < 0 ? "red" : "black"};
                top: ${projected > (target / 2) ? "0px" : projected <= -(target / 1.5) ? "0px" : "-90px"};
        }
    `;
    // const [scale, setScale] = React.useState(1);
    // React.useEffect(() => {
    //     window.onresize = () => {
    //         const scalable = window.innerWidth < 1600 ? Math.min(
    //             window.innerWidth / 1920,
    //             window.innerHeight / 800
    //         ) : 1;
    //         console.log(window.innerWidth, scalable)
    //         setScale(scalable);
    //     }
    // }, []);
    return (
        <Element
            // style={{transform:`scale(${scale})`}}
            className={props.className}>
            <InnerElement  {...props} target={target} projected={projected} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<ProfitProjectionProps> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const lastDecisionImpact: number = useSelector((state: any) => state.base.lastDecisionImpact);
    const currentEra: string = useSelector((state: any) => state.base.currentEra);
    const target = props.target || model.targetProfit;
    const projected = props.projected || projectedProfit;
    const change = props.lastImpact || lastDecisionImpact;

    const showChange = props.showChange && currentEra !== "wrapup";
    return (
        <div>
            <div className="componentLabel">
                Profit trajectory
            </div>
            <div className='graph'>
                <div className="axis" />
                <div className="targetColumn">
                    <div className="targetAmountLabel">
                        <DollarValue value={target} dollarSign />
                    </div>
                    <div className="targetColumnLabel">
                        3-Year Target
                    </div>
                </div>
                <div className={`projectedColumn ${showChange ? "showChange" : ""}`}>

                    <div className="projectedAmountLabel">
                        <DollarValue value={projected} dollarSign />
                    </div>
                    <div className="changeLabel">
                        {/* {lastDecisionImpact * 100}% */}
                        {change === 0 ?
                            <span>no change</span>
                            :
                            <>
                                <BlackDownArrow className={change > 0 ? "invert" : ""} />
                                <span>{Math.round((change) * 100)}%</span>
                            </>
                        }
                    </div>
                    <div className="projectedColumnLabel">
                        Projected Profit
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProfitProjection;