import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import CoverPage from '../cover';
import Dialogue from '../../components/pageTemplates/Dialogue';
import IntroPage from './ChooseYourBusiness';
import ExitButton from '../../components/ExitButton';
import { BizModel } from '../../lib/bizModels';
import { GoogleAnalytics, GoogleTagManager } from '../../util/SVG';
import OfferAdvicePage from './OfferAdvice';

interface Props { }

const InstalledToolsPage: React.FunctionComponent<Props> = (props) => {

    const Element = styled('div')`
        .tools {
            font-style: normal;
            font-weight: 400;
            // font-size: 24px;
            // line-height: 36px;
            .tool {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 32px;
                margin-top: 30px;

                .icon {
                    display: block;
                    svg {
                        // width: 50px;
                        // width: 50px !important;
                        // height: 152px;
                    }
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    padding: 0px;
                    gap: 10px;
                    .header {
                        font-family: 'AP Type Pro Text';
                        font-style: normal;
                        font-weight: 700;
                        // font-size: 32px;
                        // line-height: 36px;
                    }

                    color: #212129;
                }
            }
            margin-bottom: 30px;
        }
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const dispatch = useDispatch();
    return (
        <Dialogue
            title={`You’re nearly ready to get trading with your ${model.name.toLowerCase()} business`}
            buttons={
                <>
                    <Button className="nextButton" text="Continue" onClick={() => {
                        dispatch(baseActions.setCurrentPage(OfferAdvicePage));
                    }} />
                </>
            }
        >
            <p>
                As part of your business’ platform set-up, the following analytic tools have also been installed at no extra cost:
            </p>
            <div className="tools">
                <div className="tool">
                    <div className="icon">
                        <GoogleAnalytics />
                    </div>

                    <div className='text'>
                        <div className='header text32'>
                            Google Analytics
                        </div>
                        <div className='text24'>
                            You'll start receiving customer insights from Day 1, making it easier for you to understand your customer, and optimise your website and channel mix.
                        </div>
                    </div>

                </div>
                <div className="tool">
                    <div className="icon"><GoogleTagManager /></div>
                    <div className='text'>
                        <div className='header text32'>
                            Google Tag Manager
                        </div>
                        <div className='text24'>
                            This will make installation of marketing tags easy to administer.
                        </div>
                    </div>
                </div>
            </div>
        </Dialogue>
    );
}
export default InstalledToolsPage;