import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Y2SeguePage from '../yearTwo/Y2Segue';
import { blackFridayOptions } from './BlackFriday';


interface Props {
    choice: "staff" | "campaign";
}

const BlackFridayResultPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <ActionOutcomeTemplate
            showNav
        >
            <Element>
                <InnerElement {...props} />
            </Element>

        </ActionOutcomeTemplate>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);

    const dispatch = useDispatch();
    React.useEffect(() => {
        const newChoices = JSON.parse(JSON.stringify(choices));
        newChoices.blackFriday = props.choice;
        dispatch(baseActions.setChoices(newChoices));
        dispatch(baseActions.adjustProjection(blackFridayOptions[model.type][props.choice].profitImpact));
    }, []);

    return (
        <>
            <div className="title titleText">
                Spend result
            </div>
            <p dangerouslySetInnerHTML={{ __html: blackFridayOptions[model.type][props.choice].content }}>

            </p>
            <Button text="Continue" className='nextButton' onClick={() => { dispatch(baseActions.setCurrentPage(Y2SeguePage)); }} />
        </>
    );
}
export default BlackFridayResultPage;

