import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import { RatesIncreaseKeys, RatesIncreaseOptions } from './RatesIncreaseAction';
import { GrantSpendOptions } from './GrantSpendAction';
import WrapUpPage from '../wrapup/WrapUp';
import WrapUpOnePage from '../wrapup/WrapUpOne';
import WrapUpTwo from '../wrapup/WrapUpTwo';

export type KeysType = "website" | "socialMedia" | "balance";

interface Props {
    choice: KeysType;
}

const GrantSpendResultPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <ActionOutcomeTemplate
            showNav
        >
            <Element>
                <InnerElement {...props} />
            </Element>

        </ActionOutcomeTemplate>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);

    const optionIndex = GrantSpendOptions.options.findIndex(val => val.label === props.choice);
    React.useEffect(() => {
        const newChoices = JSON.parse(JSON.stringify(choices));
        newChoices.grantSpend = props.choice;
        dispatch(baseActions.setChoices(newChoices));
        console.log(newChoices);
        dispatch(baseActions.adjustProjection(GrantSpendOptions.options[optionIndex].profitImpact));
    }, []);

    return (
        <>
            <div className="title titleText">
                Spend result
            </div>
            <p dangerouslySetInnerHTML={{ __html: GrantSpendOptions.options[optionIndex].result }} />
            <Button text="Continue" className='nextButton' onClick={() => { dispatch(baseActions.setCurrentPage(WrapUpOnePage)); }} />
        </>
    );
}
export default GrantSpendResultPage;

