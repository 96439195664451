import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch } from 'react-redux';
import { Metric10, Metric20, Metric40, Metric60, Metric70 } from '../../util/SVG';
import Button from '../../components/Button';
import StartupSpendResultPage from './StartupSpendResult';
import ProfitProjection from '../../components/ProfitProjection';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import { TestingData } from '../../lib/util';

export const StartupSpendOptions = {
    "Coffee": {
        "stock": {
            congrats: "Ok, you've got plenty of stock on hand to sell, however, at this growth stage of your business you haven't created enough demand for it. This will hurt in the long run as it'll take time to build an audience and create demand for your product.",
            profitImpact: -.8,
            impactText: "Your projected profit by the end of Y3 is only 20% of what was forecast. Fortunately it's only year one, so you have time to try to recover your profit position.",
            imperative: "Did you take up the offer of free advice? It might've helped you reach a better outcome. Keep it in mind for next time."
        },
        "marketing": {
            congrats: "Congrats, you've got loads of customers on your website and a better online experience driving conversion. At this stage of the game, while you don't have much stock in the bank, your focus on building an audience will massively pay off in the long run, as your organic customer base is already significant.",
            profitImpact: 0,
            impactText: "Your projected profit at the end of Y3 is the same.",
            imperative: ""
        },
        "balance": {
            congrats: "Congrats, you've got plenty of stock available to sell and you're generating some interest in the market for your product. At this growth stage, you may have been better off putting some more money into marketing to grow demand for your product.",
            profitImpact: -.3,
            impactText: "Your projected profit by end of Y3 is 70% of what was forecast.",
            imperative: ""
        },
    },
    "Beauty": {
        "stock": {
            congrats: "Congrats, you've got plenty of stock on hand to sell, unfortunately you haven't created enough demand for it. This will significantly hurt you in the long-run as it'll take time to build an audience and create demand for your product.",
            profitImpact: -1.2,
            impactText: "This decision, coupled with your business profile, will put you in negative profit at the end of Y3. Fortunately it's only year one, so you have time to try to recover your profit position.",
            imperative: "Did you take up the offer of free advice? It might've helped you reach a better outcome. Keep it in mind for next time."
        },
        "marketing": {
            congrats: "Congrats, you've got loads of customers on your website and a better online experience driving conversion. At this stage of the game, while you don't have much stock in the bank, your focus on building an audience will massively pay off in the long run, as your organic customer base is already significant.",
            profitImpact: 0,
            impactText: "Your projected profit at the end of Y3 is the same.",
            imperative: ""
        },
        "balance": {
            congrats: "Congrats, you've got plenty of stock available to sell and you're generating some interest in the market for your product. At this growth stage, you may have been better off putting some more money into marketing to grow demand for your product.",            profitImpact: -.3,
            impactText: "Your projected profit by end of Y3 is 70% of what was forecast.",
            imperative: ""
        },
    },
    "Homewares": {
        "stock": {
            congrats: "Congrats, you've got plenty of stock on hand to sell, unfortunately you haven't created enough demand for it. This will hurt in the long-run as it'll take time to build an audience and create demand for your product.",
            profitImpact: -1.5,
            impactText: "This decision, coupled with your business profile, will put you in negative profit at the end of Y3. Fortunately it's only year one, so you have time to try to recover your profit position.",
            imperative: "Did you take up the offer of free advice? It might've helped you reach a better outcome. Keep it in mind for next time."
        },
        "marketing": {
            congrats: "Congrats, you've got loads of customers on your website and a better online experience driving conversion. However, given that you are constrained by your fulfilment model (manufacturing and shipping from your garage) you are driving a little too much traffic vs. the investment you've made in stock.",
            profitImpact: -1.3,
            impactText: "If you keep this up, your project profit at the end of Y3 will be negative. Fortunately it's only year one, so you have time to try to recover your profit position.",
            imperative: "Did you take up the offer of free advice? It might've helped you reach a better outcome. Keep it in mind for next time."
        },
        "balance": {
            congrats: "Congrats, you've got great traffic coming in and you're also manufacturing enough stock to keep up with demand. This is the best balance for your business type at this stage of the game.",
            profitImpact: 0,
            impactText: "Your projected profit at the end of Y3 is the same.",
            imperative: ""
        },
    }
}

export const gety1s1TestData = (): TestingData => {
    return [
        { biz: "Coffee", option: "stock", profitImpact: -.8 },
        { biz: "Coffee", option: "marketing", profitImpact: 0 },
        { biz: "Coffee", option: "balance", profitImpact: -.3 },
        { biz: "Beauty", option: "stock", profitImpact: -1.2 },
        { biz: "Beauty", option: "marketing", profitImpact: 0 },
        { biz: "Beauty", option: "balance", profitImpact: -.3 },
        { biz: "Homewares", option: "stock", profitImpact: -1.5 },
        { biz: "Homewares", option: "marketing", profitImpact: -1.3 },
        { biz: "Homewares", option: "balance", profitImpact: 0 }
    ]
}

interface Props { }

const StartupSpendPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        .cardPanel:has(.active) {
            .card {
                // justify-content: center;
                // align-items: center;
            }
        }
        .card {
            height: fit-content;
            padding: 18px 36px 40px !important;
            .closed, .open {
                // width: 263px !important;
                
            }
            .closed > div{
                justify-content: center;
            }
            .open {
                height: 260px !important;
                > div{
                    // padding: 0px;
                }
            }
        }
        .card.active {
            *{transition: all 0.3s linear;}
            // padding: 0px !important;
            svg {
                width: 100% !important;
                text-align: left;
                transform: scaleY(3.5);
            }
        }
        .info {
            font-weight: 400 !important;
        }
    `;
    


    return (
        // <PageTemplateUpperText
        //     whiteFill
        //     showNav
        //     textContent={<TextContentArea title="Your first significant business decision" >
        //         <Element>
        <BusinessActionTemplate
            decisionTitle='Your first significant business decision'
            optionCount={3}
            era="y1s1"
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </BusinessActionTemplate>
        //             <ProfitProjection className='projection'/>
        //         </Element>
        //     </TextContentArea>
        //     }
        // >
        // </PageTemplateUpperText >
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y1s1"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([StartupSpendResultPage, { choice: activeCard }]));
    }
    return (
        <>
            <p>
                You're now in Year 1. You will have $100,000 worth of start-up capital to spend on your business. How will you invest your money?
                <br/>
            </p>
            <p className="bold">
                Click on each plan then confirm your choice.
            </p>
            <div className="cardPanel">
                <div className={`card ${activeCard === "stock" && "active"}`} id="stockCard" onClick={() => setActiveCard(('stock'))}>
                    <div className="closed">
                        Strong focus on stock
                    </div>
                    <div className="open">
                        <div>
                            <div className="label">
                                Strong focus on stock
                            </div>
                            <div className="metrics">
                                <div className="metric">
                                    <Metric70 />
                                    <div className="info">
                                        <span className='bold'>70%</span> Stock
                                    </div>
                                </div>
                                <div className="metric">
                                    <Metric10 />
                                    <div className="info">
                                        <span className='bold'>10%</span> Marketing
                                    </div>
                                </div>
                                <div className="metric">
                                    <Metric20 />
                                    <div className="info">
                                        <span className='bold'>20%</span> Website optimisation
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`card ${activeCard === "marketing" && "active"}`} id="marketingCard" onClick={() => setActiveCard(('marketing'))}>
                    <div className="closed">
                        Strong focus on marketing
                    </div>
                    <div className="open">
                        <div>
                            <div className="label">
                                Strong focus on marketing
                            </div>
                            <div className="metrics">
                                <div className="metric">
                                    <Metric20 />
                                    <div className="info">
                                        <span className='bold'>20%</span> Stock
                                    </div>
                                </div>
                                <div className="metric">
                                    <Metric70 />
                                    <div className="info">
                                        <span className='bold'>70%</span> Marketing
                                    </div>
                                </div>
                                <div className="metric">
                                    <Metric10 />
                                    <div className="info">
                                        <span className='bold'>10%</span> Website optimisation
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`card ${activeCard === "balance" && "active"}`} id="balanceCard" onClick={() => setActiveCard(('balance'))}>
                    <div className="closed">
                        Balance stock and marketing
                    </div>
                    <div className="open">
                        <div>
                            <div className="label">
                                Balance stock and marketing
                            </div>
                            <div className="metrics">
                                <div className="metric">
                                    <Metric60 />
                                    <div className="info">
                                        <span className='bold'>60%</span> Stock
                                    </div>
                                </div>
                                <div className="metric">
                                    <Metric40 />
                                    <div className="info">
                                        <span className='bold'>40%</span> Marketing and brand awareness
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />}
        </>
    );
}
export default StartupSpendPage;