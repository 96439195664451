import * as React from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import { DefaultProps } from './DefaultProps';
import { WhiteLeftChevron } from '../util/SVG';

interface InfoPanelProps extends DefaultProps {
    onGotIt?: () => void;
    nextText?: string;
    headerIcon?: React.ReactNode;
    headerTitle?: string;
    children?: React.ReactNode;
    defaultChevron?: boolean;
}

const InfoPanel: React.FunctionComponent<InfoPanelProps> = (props) => {
    const Element = styled('div')`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 40px 48px;
        gap: 0px;
        position: relative;

        background: #FFFFFF;
        border-radius: 8px;
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;

                // width: 634px;
                // height: 524px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                // font-size: 40px;
                // line-height: 48px;

            }
            .detail {
                // width: 634px;
                // height: 452px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 400;
                // font-size: 24px;
                // line-height: 32px;
                /* or 150% */

                color: #212129;


                /* Inside auto layout */
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                ul {
                    // margin-top: 100px;
                }
                li {
                    margin-top: 30px;
                }
            }
        }
        .defaultChevron{
            position: absolute;
            left: -25px;
            top: 45%;
        }
    `;
    return (
        <Element className={props.className}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<InfoPanelProps> = (props) => {
    return (
        <>
            {props.defaultChevron && <WhiteLeftChevron className="defaultChevron" /> }
            <div className='info'>
                {(props.headerIcon || props.headerTitle) &&
                    <div className='header subtitleText'>
                        {props.headerIcon}
                        {props.headerTitle}
                    </div>
                }
                <div className='detail text24'>
                    {props.children}
                </div>
            </div>
            <Button className="nextButton" text={props.nextText || "Got it"} onClick={props.onGotIt} />
        </>
    );
}
export default InfoPanel;