import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import styled from '@emotion/styled';

import { useSelector } from 'react-redux';
import CoverPage from './pages/cover';
import { BlurFadeIn } from './util/Transitions';
import { SubtitleText, Text16, Text20, Text24, Text32, TitleText } from './util/css';

function App() {
    const Base = styled('div')`
        width: 100vw;
        overflow: auto;
        * {
        }
        * {font-family: 'AP Type Pro Text';}
        
        .bold {
            font-weight: 700;
        }
        .hidden {
            display: none;
        }
        .button.animate, .animate .button {
            animation: ${BlurFadeIn("0.3","0px")} 0.3s ease;
        }
        .nextButton{
            > * {
                padding: 0px 5vw;
            }
            &, > * {
                
                width: fit-content !important;
                
            };
        }
        .textShadow {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .titleText {
            ${TitleText}
        }
        .subtitleText{
            ${SubtitleText}
        }
        .text32{
            ${Text32}
        }
        .text24{
            ${Text24}
        }
        .text20{
            ${Text20}
        }
        .text16{
            ${Text16}
        }
    `

    const CurrentPage = useSelector((state: any) => state.base.currentPage);
    const currentProps = useSelector((state: any) => state.base.currentPageProps);
    const [isValidHash, setIsValidHash] = React.useState(false);

    const [scale, setScale] = React.useState(1);
    useEffect(()=>{
        // setIsValidHash(true);
        setIsValidHash(document.location.hash === "#AhRcwq72wVggFEiyyracX4r2CN" && document.location.pathname === "/")
        window.onresize=()=>{
            // const scalable = window.innerWidth< 1600?Math.min( 
            //     window.innerWidth / 1920, 
            //     window.innerHeight / 800 
            //   ): 1;
            //   console.log(window.innerWidth, scalable)
            //   setScale(scalable);
        }
    },[]);
    // useEffect(()=>{
    //     const scalable = window.innerWidth< 1600?Math.min( 
    //         window.innerWidth / 1920, 
    //         window.innerHeight / 900 
    //       ): 1;
    //       console.log(window.innerWidth, scalable)
    //       setScale(scalable);
    // })
    useEffect(()=>{
        document.querySelector('body')?.scrollIntoView({behavior:"smooth"});
    })
    return (
        <>
            { isValidHash?
                <Base 
                    // style={{transform:`scale(${scale})`}}
                >
                    {CurrentPage ? <CurrentPage {...currentProps} /> : <CoverPage />}
                </Base>
                : ""}
        </>
    );
}

export default App;
