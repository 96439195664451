import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultProps } from './DefaultProps';

export interface TextContentAreaProps extends DefaultProps {
    title?: string;
    children: React.ReactNode;
    maxWidth?: string;
}

const TextContentArea: React.FunctionComponent<TextContentAreaProps> = (props) => {
    const Element = styled('div')`
        margin-left: -47px;

        // background: #FFFFFF;
        > div {
            border-left: 4px solid #DC1928;
            padding: 0px 20px;
            height: 100%;
            width: fit-content;
            margin: 10px 48px 40px;
  
            // display: grid;
            // grid-template-rows: ${props.title && ".2fr"} auto 1fr;
        }
        .title, .content {
            ${props.maxWidth? `max-width: ${props.maxWidth};`: ""}
        }
        .title {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 500;
            // font-size: 56px;
            // line-height: 67px;
            color: #212129;
            margin: 0;
            margin-left: 30px;
        }
        .content {
            width: 100%;
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 400;
            margin-left: 30px;
            color: #212129;
        }
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<TextContentAreaProps> = (props) => {
    return (
        <>
            <div>
                {props.title && <h1 className="title titleText" dangerouslySetInnerHTML={{__html:props.title}}></h1>}
                <div className="content text24">
                    {props.children}
                </div>
            </div>
        </>
    );
}
export default TextContentArea;