import * as React from 'react';
import styled from '@emotion/styled';
import { handleScreenReaderEnterKey, randomString } from '../../lib/util';

interface CheckableDivProps {
    id?: string;
    className?: string
    label?: string;
    value?: string;
    children: React.ReactNode;
    onClick?: () => any;
    active?: boolean;
}

const CheckableDiv: React.FunctionComponent<CheckableDivProps> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<CheckableDivProps> = (props) => {
    const id = props.id || randomString(16);
    // const [active, setActive] = React.useState(false);
    const onClick = () => {
        props.onClick && props.onClick();
        // setActive(!active);
    };
    // document.getElementById(id)?.setAttribute("aria-checked", (!document.getElementById(id)?.ariaChecked || false).toString());
    return (
        <div role="checkbox" aria-label={props.label} aria-checked={props.active} tabIndex={0}
            id={id} className={`${props.className || ''} ${props.active && 'active'}`}
            onClick={onClick} onKeyDown={(e: any) => handleScreenReaderEnterKey(e, onClick)}
        >
            {props.children}
        </div>
    );
}
export default CheckableDiv;