import * as React from 'react';
import styled from '@emotion/styled';

import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';

interface Props {
    children: React.ReactNode;
    whiteBackground?: boolean;
    applyMargin?: boolean;
}

const PageTemplate: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        // position: absolute;
        // top: 0px;
        ${props.whiteBackground? "background: #fff;": "background: #EEEEEE;"}
        
        width: 100vw;
        height: 100vh;
        .pageContent {
            // height: calc(100vh - 185px);
            ${props.applyMargin && "left: 47px;"}
            position:absolute;
            top: 80px;
            width: 100vw;
            margin-top: 3px;
        }
    `;

    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const dispatch = useDispatch();
    return (
        <>
            <Header />
            <div className="pageContent text24">
                {props.children}
            </div>
        </>
    )
}

export default PageTemplate;