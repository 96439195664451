import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultProps } from './DefaultProps';
import { HasQuizAnswer, IQuizQuestion } from '../lib/quiz';
import CheckableDiv from './wcag/CheckableDiv';
import { CursorPointer, FlexCenter, Text24 } from '../util/css';


interface Props extends DefaultProps, HasQuizAnswer {
    options?: Array<string>;
    onSelect?: (value: any, index: number) => void;
    selected?: number;
    disabled?: boolean;
}

const QuizCheckableDivs: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        // position: relative;
        // z-index: 2000;
        width:100%;
        .options {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            // padding: 16px 0px 0px;
            gap: 40px;
            flex-wrap: wrap;
            // flex: 0 1 1;
            width: 100%;
            // height: 204px;
        }
        
        .option {
            background: #31313D;
            border-radius: 8px;
            width: 10vw;
            height: 130px;
            padding: 3vw;

            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 700;
            ${Text24}

            color: #FFFFFF;
            transition all 0.3s;
            ${CursorPointer}
            &:hover {
                filter: brightness(110%);
                opacity: .92;
            }
        }
        &.disabled .active{
            border: 3px solid #CA3332;
        }
        &.disabled .correct {
            border: 3px solid #158744 !important;
        }
        .option.active, &.disabled .option {
            background: #FFFFFF;
            color: #212129;
            
        }
    `;
    return (
        <Element id={props.id} className={`${props.className} ${props.disabled && "disabled"}`}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const [selected, setSelected] = React.useState(props.selected);
    const onSelect = (val: string, index: number) => {
        if (props.onSelect)
            props.onSelect(val, index);
        console.log(val);
        setSelected(index);
    }
    return (
        <div className="options">
            {props.options?.map((opt, index) => {
                return (
                    <CheckableDiv
                        className={`option text24 ${index === selected? "active":""} ${(opt === (props.correct as string))? "correct": ""}`}
                        onClick={!props.disabled ? () => onSelect(opt, index) : () => { }}
                    >
                        {opt}
                    </CheckableDiv>
                )
            })}
        </div>
    );
}
export default QuizCheckableDivs;