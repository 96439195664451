import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import CompetitorActionPage from './CompetitorAction';
import { ChallengeOptions } from './ChallengeContinues';

export type KeysType = "ads" | "tool" | "delivery" | "personalisation";

export const ChallengeKeys = {
    "staff": ["ads", "tool"],
    "campaign": ["delivery", "personalisation"]
};

interface Props {
    choice: KeysType;
}

const ChallengeContinuesResultPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <ActionOutcomeTemplate
            showNav
        >
            <Element>
                <InnerElement {...props} />
            </Element>

        </ActionOutcomeTemplate>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);

    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const blackFridayChoice = choices.blackFriday as "staff" | "campaign";
    console.log(choices);

    const challengeIndex = blackFridayChoice === "staff" ? 0 : 1;
    const dispatch = useDispatch();
    console.log("choice", props.choice)
    React.useEffect(() => {
        const newChoices = JSON.parse(JSON.stringify(choices));
        newChoices.challengeContinues = props.choice;
        dispatch(baseActions.setChoices(newChoices));
        console.log(newChoices);
        dispatch(baseActions.adjustProjection(ChallengeOptions[challengeIndex][model.type][props.choice]!.profitImpact));
    }, []);

    return (
        <>
            <div className="title titleText">
                Spend result
            </div>
            {/* <p className='bold'>{ChallengeOptions[challengeIndex][model.type][props.choice]!.label}</p> */}
            <p dangerouslySetInnerHTML={{ __html: ChallengeOptions[challengeIndex][model.type][props.choice]!.content }} />
            <p>{ChallengeOptions[challengeIndex][model.type][props.choice]!.imperative}</p>
            <Button text="Continue" className='nextButton' onClick={() => { dispatch(baseActions.setCurrentPage(CompetitorActionPage)); }} />
        </>
    );
}
export default ChallengeContinuesResultPage;

