import { createSlice } from '@reduxjs/toolkit';
import { BizModel } from '../lib/bizModels';
import { AnsweredQuiz } from '../lib/quiz';


const initialState: {
    selectedModel: BizModel | undefined;
    previousProjectedProfit: number | undefined;
    projectedProfit: number;
    lastDecisionImpact: number;
    currentPage: React.ReactNode | undefined;
    currentPageProps: {} | null;
    choices: { [key: string]: string; }
    quizAnswers: AnsweredQuiz;
    currentEra: string;
    modalActive: boolean;
} = { selectedModel: undefined, projectedProfit: 0, previousProjectedProfit: undefined, currentPage: undefined, currentPageProps: null, choices: {}, currentEra: "setup", quizAnswers: {}, modalActive: false, lastDecisionImpact: 0 };

export const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            if (typeof (action.payload) !== typeof ([])) {
                state.currentPage = action.payload;
                state.currentPageProps = null;
            }
            else {
                state.currentPage = action.payload[0];
                state.currentPageProps = action.payload[1];
            }
        },
        setChoices(state, action) { state.choices = action.payload; },
        setCurrentEra(state, action) { state.currentEra = action.payload; },
        setModalActive(state, action) { state.modalActive = action.payload; },
        setQuizAnswered(state, action) {
            state.quizAnswers[action.payload.quizID] = action.payload.answers;
            console.log(state.quizAnswers);
        },
        selectBusiness(state, action) {
            state.selectedModel = action.payload;
            state.projectedProfit = action.payload.targetProfit;
            // state.previousProjectedProfit = action.payload.targetProfit;
            console.log(action.payload);
        },
        adjustProjection(state, action) {
            const profitImpact: number = action.payload * state.selectedModel!.targetProfit;
            const newProjection = Math.round(state.projectedProfit + profitImpact);
            state.lastDecisionImpact = action.payload;//newProjection > state.projectedProfit ? 1 : newProjection < state.projectedProfit ? -1 : 0;
            // if (state.projectedProfit !== undefined)
            state.previousProjectedProfit = state.projectedProfit;
            state.projectedProfit = newProjection;

            console.log(profitImpact, state.previousProjectedProfit, state.projectedProfit, state.lastDecisionImpact);
        },
        deductAdviceCost(state) {
            state.projectedProfit -= 1000;
        },
        clearAll(state) {
            state.selectedModel = undefined;
            state.projectedProfit = 0;
            state.currentPage = undefined
            state.currentPageProps = null;
            state.choices = {};
            state.currentEra = "setup";
            state.quizAnswers = {};
            state.modalActive = false;
            state.lastDecisionImpact = 0
        }
    }
});

export const baseActions = baseSlice.actions;
