export type HasQuizAnswer = {
    correct: boolean | string;
    highlightCorrect?: boolean;
}
export interface IQuizQuestion {
    id: string;
    question: string;
    options: Array<string>;
    correct: string;
}

export interface IQuiz {
    id: string;
    questions: Array<IQuizQuestion>;
}

export type AnsweredQuiz = {
    [s: string]: Array<{ id: string, answer: string }>;
}
export const WHatsInANumberQuiz: IQuiz = {
    id: "1",
    questions: [
        {
            id: "1a",
            question: "Website traffic is:",
            options: [
                "The number of website visitors",
                "The number of website sessions",
                "The number of visitors who buy from your site"
            ],
            correct: "The number of website sessions",
        },
        {
            id: "1b",
            question: "Conversion rate is the:",
            options: [
                "Mark-up percentage per item",
                "Percentage of visitors who clicked your ads",
                "Percentage of traffic that ended up as transactions"
            ],
            correct: "Percentage of traffic that ended up as transactions"
        },
        {
            id: "1c",
            question: "AOV is the:",
            options: [
                "Average value of items sold",
                "Average order value for a single transaction",
                "Average value of stock you ordered"
            ],
            correct: "Average order value for a single transaction"
        }
    ]
}


export const Quiz2: IQuiz = {
    id: "2",
    questions: [
        {
            id: "2a",
            question: "Sounds like you know your stuff. Which one of these activities should be undertaken, if you were trying to set up your organic website traffic?",
            options: [
                "Determine the right fulfilment model",
                "Invest in Search Engine Optimisation",
                "Improve my platform experience"
            ],
            correct: "Invest in Search Engine Optimisation",
        }
    ]
}