import * as React from 'react';
import styled from '@emotion/styled';

import models, { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Button from '../../components/Button';
import TextContentArea from '../../components/TextContentArea';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import { RedDollarSign, RedTick } from '../../util/SVG';
import DollarValue from '../../components/DollarValue';
import { FlexCenter } from '../../util/css';
import { FromRight } from '../../util/Transitions';
import InfoPanel from '../../components/InfoPanel';
import BlackFridayPage from './BlackFriday';

interface Props { }

const CostOfDoingBusinessPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        overflow: hidden;
        
        .panels{
            display: flex;
            // gap: 20px;

            // position: relative;
        }
        
        .panels {
            .leftPanel {
                // width: 100%;
                // min-width: 800px;
                // max-width: 60vw;
                width: 60vw;
            }
            .rightPanel {
                width: 30vw;
                // width: 100%;
                // min-width: 400px;
                // max-width: 700px;
                z-index: 50;
                margin-top: -10px;
                animation: ${FromRight()} 0.33s ease;
                .bold {
                    margin: 0;
                }
            }
        }
        .flipCardPanel {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px;
            gap: 10px;
            max-width: 900px;
            
            &.complete .card {
                background: #FFFFFF;
                // box-shadow: 0px 0px 3px 2px ;
                border: 3px solid #158744;
                margin: -3px;
                // border: 3px solid #158744;
                color: #212129;
            }
            .card {
                transition: all 0.3s ease;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 30px;
                gap: 10px;
                // flex-grow: 1.2;
                // width: 240px;
                width: calc(40vw / 5);
                // max-width: 200px;
                height: 152px;

                background: #31313D;
                border-radius: 8px;

                user-select: none;
                cursor: pointer;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 700;
                // font-size: 24px;
                // line-height: 36px;

                color: #FFFFFF;
                justify-content: flex-start;
                
                &.active {
                    // .closed{display: none;}
                    // .open{display: flex;}
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    color: #212129;
                    background: #EEEEEE;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                   
                    border-radius: 8px;
                }
            }
            // > * {flex: 1 1 0;}
            
        }
        .button {
            margin-top: 30px;
        }
        @media(max-height:920px) {
            .card {
                height: 120px !important;
            }
        }
    `;
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const cardsChecked = React.useRef(0);
    const [complete, setComplete] = React.useState(false);
    const checkCard = (cardID: string) => {
        if(complete) return;
        try {
            document.getElementById(cardID)?.classList.toggle("active")
            cardsChecked.current += 1;
            if (cardsChecked.current > 0) {
                document.getElementsByClassName('nextButton')[0].classList.remove('hidden');
            }
        } catch (error) {

        }
    }

    const next = () => {
        setComplete(true);
    }

    const options = [
        `Cost of goods <br/>(stock)`,
        "Wages and salaries",
        "Marketing",
        "Payment gateway fees",
        "Overheads",
        "Shipping",
        "Platform",
        "Warehousing"
    ]
    return (
        <>
            <PageTemplateUpperText
                showNav={false}
                whiteFill={!complete}
                noBackground={complete}
                hideFooter
                textContent={<TextContentArea title={"The cost of doing business"}>
                    <p>
                        Operating profit is the difference between revenue and costs. So what costs do we have to think about?
                    </p>
                    <p className='bold'>
                        Select as many as you think relevant:
                    </p>
                    <div className="panels">


                        <div className="leftPanel">


                            <div className={`flipCardPanel ${complete ? "complete" : ""}`}>
                                {options.map((val, index) => {
                                    const cardID = `card${index}`;
                                    return (
                                        <div className="card text24" id={cardID} onClick={() => checkCard((cardID))} dangerouslySetInnerHTML={{ __html: val }}>
                                        </div>
                                    );
                                })}
                            </div>
                            {!complete && <Button className="nextButton hidden" text="Check Answer" onClick={next} />}
                        </div>
                        {complete &&
                            <div className="rightPanel">
                                <InfoPanel
                                    defaultChevron
                                    // headerIcon={<RedTick />}
                                    // headerTitle="Correct"
                                    onGotIt={() => dispatch(baseActions.setCurrentPage(BlackFridayPage))}
                                >
                                    <p className='bold'>All of these are typical costs for your eCommerce Business.</p>
                                    <p>The amounts will vary for each business, and some may have other costs as well. But these are the main costs that an eCommerce Business like yours will need to consider and manage.</p>
                                </InfoPanel>

                            </div>
                        }
                    </div>
                </TextContentArea>}>

            </PageTemplateUpperText>
        </>
    );
}
export default CostOfDoingBusinessPage;