import * as React from 'react';
import styled from '@emotion/styled';

import models, { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Button from '../../components/Button';
import TextContentArea from '../../components/TextContentArea';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import { RedDollarSign } from '../../util/SVG';
import DollarValue from '../../components/DollarValue';
import { SubtitleText, Text24, Text32, TitleText } from '../../util/css';
import WrapUpTwo from './WrapUpTwo';

interface Props { }

const WrapUpOnePage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        overflow: hidden;
        min-height: 300px;
        height: 100%;
        .flipCardPanel {
            display: none !important;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px;
            gap: 22px;
            
            .card {
                -webkit-perspective: 150rem;
                perspective: 150rem;
                -moz-perspective: 150rem;
                position: relative;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                
                padding: 70px 36px;
                height: 60px;
                width: 22vw;
                min-width: 133px;
                background: #31313D;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                user-select: none;
                cursor: pointer;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                ${Text32}

                color: #FFFFFF;
                justify-content: start;
                // padding-left: 50px;
                .open, .closed {
                    -webkit-transition: all 0.8s ease;
                    transition: all 0.8s ease;
                    -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                }
                .closed {
                    display: block;
                    text-align: left;
                    
                }
                .open{
                    .label {
                        font-weight: 400;
                        // font-size: 24px;
                        ${Text24}

                    }
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);

                    //display: none;


                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    position: absolute;
                }
                &.active {
                    .closed{
                        // display: none;
                        -webkit-transform: rotateY(-180deg);
                        transform: rotateY(-180deg);
                    }
                    .open{
                        display: flex;
                        flex-direction: column;
                        -webkit-transform: rotateY(0);
                        transform: rotateY(0);
                        // color: #212129;
                        // background: #FFFFFF;
                        .label {
                            color: #212129;
                            ${Text24}
                        }
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    color: #212129;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                }
            }
        }
        .button {
            margin-top: 30px;
        }
    `;
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    return (
        <PageTemplateUpperText
            whiteFill
            hideFooter
            textContent={<TextContentArea title={`You've reached the end of the game.<br/>
            What an effort.`}>
                <p>
                    {/* Before we look at your final result, remember, this result takes into account the following components, which were influenced by your decisions. */}
                    Ready to see your final result?
                </p>
                {/* <Button className="nextButton hidden animate" text="Show me my results" onClick={next} /> */}
            </TextContentArea>}
        // whiteBackground
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </PageTemplateUpperText>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const cardsChecked = React.useRef(0);

    React.useEffect(() => {
        dispatch(baseActions.setCurrentEra("wrapup"));
    }, []);

    const checkCard = (cardID: string) => {
        try {
            document.getElementById(cardID)?.classList.add("active")
            cardsChecked.current += 1;
            if (cardsChecked.current === 3) {
                document.getElementsByClassName('nextButton')[0].classList.remove('hidden');
            }
        } catch (error) {

        }
    }


    const next = () => {
        // if (cardsChecked.current === 3)
        dispatch(baseActions.setCurrentPage(WrapUpTwo));
    }
    return (
        <>
            <div className="flipCardPanel">
                <div className="card" id="revenueCard" onClick={() => checkCard(('revenueCard'))}>

                    <div className="closed">
                        Revenue
                    </div>
                    <div className="open">
                        <div className="label">
                            Revenue
                        </div>
                        <div>Traffic  x  Conversion rate  x  Average Order Value</div>
                    </div>
                </div>
                <div className="card" id="operatingCard" onClick={() => checkCard('operatingCard')}>
                    <div className="closed">
                        Operating profit
                    </div>
                    <div className="open">
                        <div className="label">
                            Operating profit
                        </div>
                        <div>Revenue – Operating Costs</div>
                    </div>
                </div>
                <div className="card" id="targetCard" onClick={() => checkCard('targetCard')}>
                    <div className="closed">
                        Net promoter score
                    </div>
                    <div className="open">
                        <div className="label">
                            Net promoter score
                        </div>
                        <div>Customer loyalty and satisfaction measurement</div>
                    </div>
                </div>
            </div>
            <Button className="nextButton animate" text="Show me my results" onClick={next} />
        </>
    );
}
export default WrapUpOnePage;