import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import Dialogue from '../../components/pageTemplates/Dialogue';
import ExpertAdvice from '../../components/Advice';
import Quiz2 from './Quiz2';
import AdvicePage from './AdvicePage';

interface Props { }

const OfferAdvicePage: React.FunctionComponent<Props> = (props) => {

    const Element = styled('div')`
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const modalActive = useSelector((state: any) => state.base.modalActive);
    const dispatch = useDispatch();
    return (
        <Dialogue
            title="Have you got what it takes?"
            buttons={
                <>
                    <Button className="nextButton" text="Yes" onClick={() => {
                        dispatch(baseActions.setCurrentPage(AdvicePage));
                    }} />
                    <Button className="nextButton" text="No" onClick={() => {
                        dispatch(baseActions.setCurrentPage(Quiz2));
                    }} />
                </>
            }
        >
            <p></p>Every new business is faced with decisions – some harder than others.
            <p>Where do you invest your money when you’re getting started?</p>
            <p>It’s time for you to decide.</p>
            <p>Would you like free advice before you proceed?</p>
        </Dialogue>
    );
}
export default OfferAdvicePage;