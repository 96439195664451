import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import CoverPage from '../cover';
import Dialogue from '../../components/pageTemplates/Dialogue';
import IntroPage from './ChooseYourBusiness';
import ExitButton from '../../components/ExitButton';

interface Props { }

const PreIntroPage: React.FunctionComponent<Props> = (props) => {
    
    const Element = styled('div')`
        .ready {
            display: inline-block;
            margin: 0px 0px 30px;
        }
        .introButtons button {
            width: min(330px, 26vw);
            height: 61px;
        }
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const modalActive = useSelector((state: any) => state.base.modalActive);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = React.useState(false)
    return (
        <Dialogue
            title="Have you got what it takes?"
            buttons={
                <>
                    <Button className="introButtons" text="Choose your business" onClick={() => {
                        dispatch(baseActions.setCurrentPage(IntroPage));
                    }} />
                    <ExitButton className="introButtons" text="Exit" onClick={() => {
                        dispatch(baseActions.setCurrentPage(CoverPage))
                    }} />
                </>
            }
        >
            <p>
                Running a business is no easy feat. You’re best to start small, spend wisely, and then try to make a profit.
            </p>
            <p className="bold">Can you make it through 3 years with money left in the bank?</p>
        </Dialogue>
    );
}
export default PreIntroPage;