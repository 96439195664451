import * as React from 'react';
import styled from '@emotion/styled';
import { Text24 } from '../util/css';


export interface ButtonProps {
    text: string;
    className?: string;
    onClick?: () => any;
    invertColours?: boolean;
    tooltip?: string;
    disable?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const baseColour = "#FFFFFF";
    const baseBackground = "#DC1928";

    const colour = props.invertColours ? baseBackground : baseColour;
    const background = props.invertColours ? baseColour : baseBackground;
    const Element = styled('div')`
        height: 61px;
        // max-width: 300px;
    
        button {
            height: 61px;
            // width: fit-content;
            color: ${colour};
            background: ${background};

            padding: 16px 32px;
            gap: 16px;
            border-radius: 8px;
            
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 500;
            ${Text24}

            text-align: center;

            user-select: none;
            cursor: pointer;
            transition: all 0.3s;
            border: 0;
            &:hover {
                ${!props.invertColours? "background: #A82A29":""};
            }
        }
        @media(max-width:1600px) {
            button{
                // font-size: 90%;
                // line-height: 90%;
            }
        }
    `;
    return (
        <Element className={`${props.className} button `}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<ButtonProps> = (props) => {
    const handleKeyDown = (e: any) => {
        const enterKey = 13;
        const spaceKey = 32;
        if (e.keyCode === enterKey || e.keyCode === spaceKey) {
            e.preventDefault();
            props.onClick!();
        }
    }
    return (
        <button aria-label={props.text} onClick={props.onClick} tabIndex={0} onKeyDown={handleKeyDown} title={props.tooltip} disabled={!!props.disable}>
            {props.text}
        </button>
    );
}
export default Button;