import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import { BeautyModel, BizModel, BizType, CoffeeModel, HomewaresModel } from '../../lib/bizModels';
import { getBizIcon } from '../../lib/util';
import Button from '../../components/Button';
import { BlueInfo, GreenUpArrow, RedDownArrow, RedLeftArrow, RedRightArrow } from '../../util/SVG';
import { keyframes } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { baseActions } from '../../state/base';
import BusinessSelectedPage from './BusinessSelected';
import { BlurFadeIn } from '../../util/Transitions';
import { FlexCenter, Text16, Text20, Text24 } from '../../util/css';

import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css'
import Info from '../../components/Info';

interface Props {
    selected: BizModel;
}
const BusinessDetailSliderPage: React.FunctionComponent<Props> = (props) => {

    const Element = styled('div')`
        min-height: 100vh;
        background: #eee;
        
        .sideBar {
            position: fixed;
            bottom: 60px;
            
            width: 80px;
            height: calc(100vh - 300px);
            min-height: 300px;

            &.sideBar.left {
                left: 0px;
                border-radius: 0px 8px 8px 0px;
            }
            &.right {
                right: 0px;
                border-radius: 8px 0px 0px 8px;
            }

            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 24px 20px;
            gap: 10px;

            background: #FFFFFF;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
            // border-radius: 8px;
            padding-bottom: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .biz{
                display: none;
                &.active{display: flex;}

                position: fixed;
                bottom: 220px;
                
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 20px;
                transform: rotate(-90deg);

                .icon svg {
                    width: 56px;
                    transform: rotate(90deg);
                }
                .name {
                    
                    width: 270px;
                    height: 48px;

                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 48px;

                    color: #000000;
                }
            }
        }
        .detailPanel {
            filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
            &.transition {animation: ${BlurFadeIn()} .3s ease !important};
            
            // position: absolute;
            // top: 20px;
            // left:-10px;
            right: 0;
            margin: 10px auto 0px;
            // padding-bottom: 20px;
            width: 79vw;
            height: calc(100vh - 110px);
            // min-width: 1000px;

            background: white;
            border-radius: 8px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            


            .navArrow {
                position: absolute;
                top: 0px;
                bottom: 0px;
                margin: auto;
                width: 64px;
                height: 64px;
                z-index: 300;
                cursor: pointer;
                user-select: none;
                &.left {
                    left: 3px;
                }
                &.right {
                    right: 9px;
                }
                transition: opacity 0.3s ease;
            }
            .slider, .slides, .slide {
                width: 100%;
                border-radius: 8px;
            }
            .slider, .slide {
                // width: 1430px;
                // height: 100%;
                // max-height: 800px;
                // position: relative;
                
            }
            .slider{
                @media(max-width: 1800px) {
                    overflow-y: scroll !important;
                }
                .flickity-viewport{

                }
            }
            .slides {
                // min-height: 850px;
                height: 100%;
            }
            .slide {
                width: 100%;
                // min-width: 1000px;
                // padding-right: 20px;
                display: flex;
                flex-direction: column;
                padding: 24px 90px 0px;

                .header {
                    display: flex;
                    flex-direction: row;
                    // justify-content: center;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 20px;
                    .icon{
                        margin-top: 20px;
                    }
                    .title {
                        font-weight: 500;
                    }
                    .content {
                        font-weight: 500;
                    }
                }
                > div {
                    display: flex;
                    gap: 30px;
                }
                .setup {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    align-items: space-between;
                    justify-content: space-between;
                    padding: 20px 0px;
                    
                    width: 280px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;

                    .setupItems {
                        display: flex;
                        flex-direction: column;
                        gap: 17px;
                        .setupItem {
                            font-family: 'AP Type Pro Text';
                            font-style: normal;
                            
                            // font-size: 19px;
                            // line-height: 30px;
                            .name {
                                
                                font-weight: 700;
                            }
                        }
                    }
                    
                }
                .metrics {
                    text-align: left;
                    padding-right: 15%;
                    box-sizing: border-box;
                    .names {
                        font-family: 'AP Type Pro Text';
                        color: #4D4D55;
                        font-style: normal;
                        font-weight: 500;
                        // font-size: 15px;
                        // line-height: 24px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }
                    .row {
                        padding: 8px 0px;
                        display: grid;
                        grid-template-columns: 275px 80px auto;
                        box-sizing: border-box;
                        > * {
                            display: inline-block;
                        }
                    }
                    .row ~ .row {
                        border-top: 1px solid #7A7A80 !important;
                    }
                    .metric {
                        font-family: 'AP Type Pro Text';
                        font-weight: 500;
                        // font-size: 20px;
                        // line-height: 30px;
                        display: flex;
                        align-items: center;
                    }
                    .two {
                        justify-content: space-between  !important;
                    }
                    .one {
                        justify-content: center !important;
                    }
                    .impact {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0px;
                        gap: 10px;

                        width: 40px;
                        svg {
                            width: 28px !important;
                        }
                        .invert {
                            transform: rotate(180deg) !important;
                        }
                    }
                    .detail {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        
                        // font-size: 16px;
                        // line-height: 26px;
                        font-weight: 400;
                    }
                }
                .chooseAndInfo {
                    padding-bottom: 30px;
                    .button {
                        > *{width: 290px; padding: 0;}
                    }
                    .moreInfo {
                        ${Text20}
                        ${FlexCenter}
                        gap: 12px;
                        > * {
                            display: inline-block;
                        }
                        a, a:visited {
                            font-weight: 600;
                            color: black;
                        }
                    }
                }
            }

        }
    `;

    return (
        <Element>
            <PageTemplate>
                <InnerElement {...props} />

            </PageTemplate>
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const businesses = [CoffeeModel, BeautyModel, HomewaresModel];
    const index = React.useRef(businesses.findIndex(val => val.type === props.selected.type));
    
    const metrics = {
        Coffee:
            <>
                <div className="row">
                    <div className="metric text20">Customer</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        High retention and frequency
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Net Promoter Score (NPS)</div>
                    <div className="impact teo">
                        <GreenUpArrow />
                        <RedDownArrow />
                    </div>
                    <div className="detail text16 two">
                        <div>
                            High service quality through own DC
                        </div>
                        <div>Potentially poorer experience through shipping aggregator

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Average Order Value (AOV)</div>
                    <div className="impact one">
                        <GreenUpArrow /></div>
                    <div className="detail text16 one">
                        <div>Free shipping threshold incentivises customer to order more</div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Conversion Rate (CR)</div>
                    <div className="impact one">
                        <RedDownArrow /></div>
                    <div className="detail text16 one">
                        <div>Minimum spend required to get free shipping. Returns policy may deter first-time customers</div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Cost of Goods Sold (COGS)</div>
                    <div className="impact one">
                        <GreenUpArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>International sourcing lowers cost of goods </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Lead Time</div>
                    <div className="impact one">
                        <RedDownArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>Long lead time on stock arriving </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Fulfilment Costs</div>
                    <div className="impact two">
                        <RedDownArrow className="invert" />
                        <GreenUpArrow className="invert" />
                    </div>
                    <div className="detail text16 two">
                        <div>High fixed costs with own DC</div>
                        <div>Lower per-order costs</div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Shipping Costs</div>
                    <div className="impact one">
                        <GreenUpArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>Better rates</div>
                    </div>
                </div>
            </>,
        Beauty:
            <>
                <div className="row">
                    <div className="metric text20">Customer</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Good retention and frequency
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Net Promoter Score (NPS)</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>High service quality through own DC, AP shipping partner and generous returns policy </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Average Order Value (AOV)</div>
                    <div className="impact one">
                        <RedDownArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Low average spend per order from generous shipping policy
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Conversion Rate (CR)</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Free shipping policy increases conversion
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Cost of Goods Sold (COGS)</div>
                    <div className="impact one">
                        <RedDownArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            High cost of goods from local sourcing
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Lead Time</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Short lead time with local sourcing
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Fulfilment Costs</div>
                    <div className="impact two">
                        <RedDownArrow className="invert" />
                        <GreenUpArrow className="invert" />
                    </div>
                    <div className="detail text16 two">
                        <div>
                            High fixed costs with own DC

                        </div>
                        <div>
                            Lower per-order costs
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Shipping Costs</div>
                    <div className="impact one">
                        <RedDownArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Potentially higher shipping costs through AP
                        </div>
                    </div>
                </div>
            </>,
        Homewares:
            <>
                <div className="row">
                    <div className="metric text20">Customer</div>
                    <div className="impact one">
                        <RedDownArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Low frequency
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Net Promoter Score (NPS)</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            High service quality and a personal touch
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Average Order Value (AOV)</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            High average order value from product type and shipping policy
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Conversion Rate (CR)</div>
                    <div className="impact one">
                        <RedDownArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Low conversion rate from shipping policy and high average sale price
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Cost of Goods Sold (COGS)</div>
                    <div className="impact one">
                        <RedDownArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            High cost of goods from local sourcing
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Lead Time</div>
                    <div className="impact one">
                        <GreenUpArrow />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Short lead time with local sourcing and agile operation
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Fulfilment Costs</div>
                    <div className="impact">
                        <RedDownArrow />
                    </div>
                    <div className="detail text16">
                        <div>
                            Low fulfilment with small garage operation
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="metric text20">Shipping Costs</div>
                    <div className="impact one">
                        <RedDownArrow className="invert" />
                    </div>
                    <div className="detail text16 one">
                        <div>
                            Potentially higher shipping costs through AP and product type
                        </div>
                    </div>
                </div>
            </>
    }

    const dispatch = useDispatch();
    const chooseBusiness = () => {
        dispatch(baseActions.selectBusiness(businesses[index.current]));
        dispatch(baseActions.setCurrentPage(BusinessSelectedPage));
    }

    const updateSideBars = () => {
        document.querySelectorAll('.biz').forEach((el, index) => {
            el.classList.remove('active');
        });
        (document.getElementById(`leftSideBiz${getPreviousIndex()}`)! as HTMLElement).classList.add('active');
        (document.getElementById(`rightSideBiz${getNextIndex()}`)! as HTMLElement).classList.add('active');
    }
    const getPreviousIndex = (_idx?: number) => {
        const idx = ((_idx || index.current) - 1) % businesses.length;
        return (idx < 0 ? businesses.length - 1 : idx);
    }
    const getNextIndex = (_idx?: number) => {
        const idx = (_idx || index.current + 1) % businesses.length;
        return idx;
    }
    const [flky, setflky] = React.useState<any>();
    React.useEffect(() => {
        setflky(new Flickity('.carousel', {
            accessibility: true,
            adaptiveHeight: true,
            autoPlay: false,
            cellAlign: 'left',

            cellSelector: undefined,
            // specify selector for cell elements

            contain: true,
            // will contain cells to container
            // so no excess scroll at beginning or end
            // has no effect if wrapAround is enabled

            draggable: true,
            // enables dragging & flicking
            // if at least 2 cells

            dragThreshold: 3,
            // number of pixels a user must scroll horizontally to start dragging
            // increase to allow more room for vertical scroll for touch devices

            freeScroll: false,
            // enables content to be freely scrolled and flicked
            // without aligning cells

            friction: 0.5,
            // smaller number = easier to flick farther

            groupCells: false,
            // group cells together in slides

            initialIndex: index.current,
            // zero-based index of the initial selected cell

            lazyLoad: true,
            // enable lazy-loading images
            // set img data-flickity-lazyload="src.jpg"
            // set to number to load images adjacent cells

            percentPosition: true,
            // sets positioning in percent values, rather than pixels
            // Enable if items have percent widths
            // Disable if items have pixel widths, like images

            prevNextButtons: false,
            // creates and enables buttons to click to previous & next cells

            pageDots: false,
            // create and enable page dots

            resize: true,
            // listens to window resize events to adjust size & positions

            rightToLeft: false,
            // enables right-to-left layout

            setGallerySize: true,
            // sets the height of gallery
            // disable if gallery already has height set with CSS

            watchCSS: false,
            // watches the content of :after of the element
            // activates if #element:after { content: 'flickity' }

            wrapAround: true,
            // at end of cells, wraps-around to first for infinite scrolling
            on: {
                ready: function () {
                },
                change: function (idx) {
                    index.current = idx!;
                    updateSideBars()
                }
            }
        }));
    }, []);
    return (
        <>
            <div className="sideBar left">
                {businesses.map((val, idx) => {
                    return (
                        <div id={`leftSideBiz${idx}`} className={`biz ${idx === getPreviousIndex() ? "active" : ""}`}>
                            <div className="icon">
                                {getBizIcon(businesses[getPreviousIndex(idx+1)])()}
                            </div>
                            <div className="name">
                                {val.type}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="sideBar right">

                {businesses.map((val, idx) => {
                    return (

                        <div id={`rightSideBiz${idx}`} className={`biz ${idx === getNextIndex() ? "active" : ""}`}>
                            <div className="icon">
                                {getBizIcon(businesses[getPreviousIndex(idx+1)])()}
                            </div>
                            <div className="name">
                                {val.type}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='detailPanel'>
                <div className='navArrow left'>
                    <RedLeftArrow
                        onClick={() => {
                            flky.previous(true, false)
                            // const idx = (index.current - 1) % businesses.length;
                            // index.current = (idx < 0 ? businesses.length - 1 : idx);
                            // document.location.href = `#slide${index.current}`;
                            // validateArrows();
                            // updateSideBars();
                        }
                        }
                    />
                </div>
                <div className='navArrow right'>
                    <RedRightArrow
                        onClick={() => {
                            flky.next(true, false)
                            // const idx = (index.current + 1) % businesses.length;
                            // index.current = idx;
                            // document.location.href = `#slide${index.current}`;
                            // validateArrows();
                            // updateSideBars();
                        }
                        } />
                </div>
                <div className="slider">
                    <div id="slides" className="slides carousel">
                        {businesses.map((val, idx) => {
                            const Icon = getBizIcon(val);
                            return (
                                <div id={`slide${idx}`} className="slide carousel-cell">
                                    <div className='header'>
                                        <div className='icon'>
                                            <Icon />
                                        </div>
                                        <div>
                                            <div className='title titleText'>
                                                {val.name}
                                            </div>
                                            <div className='content'>
                                                {val.setup.offering}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='setup'>
                                            <div className='setupItems contentText'>
                                                <div className='setupItem text20'>
                                                    <div className="name">Web platform:</div>
                                                    <div className="value">
                                                        {val.setup.webPlatform}
                                                    </div>
                                                </div>
                                                <div className='setupItem text20'>
                                                    <div className="name">Fulfilment model:</div>
                                                    <div className="value">
                                                        {val.setup.fulfillmentModel}
                                                    </div>
                                                </div>
                                                <div className='setupItem text20'>
                                                    <div className="name">Stock lead time:</div>
                                                    <div className="value">
                                                        {val.setup.stockLeadTime}
                                                    </div>
                                                </div>
                                                <div className='setupItem text20'>
                                                    <div className="name">Shipping policy:</div>
                                                    <div className="value">
                                                        {val.setup.shippingPolicy}
                                                    </div>
                                                </div>
                                                <div className='setupItem text20'>
                                                    <div className="name">Shipping model:</div>
                                                    <div className="value">
                                                        {val.setup.shippingModel}
                                                    </div>
                                                </div>

                                                <div className='setupItem text20'>
                                                    <div className="name">Change of mind return policy:</div>
                                                    <div className="value">
                                                        {val.setup.comReturnPolicy}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="metrics">
                                            <div className="row names text16">
                                                <div>METRIC</div>
                                                <div>IMPACT</div>
                                                <div>DETAIL</div>
                                            </div>
                                            {(metrics)[val.type as BizType]}
                                        </div>
                                    </div>
                                    <div className="chooseAndInfo">
                                        <Button text="Choose this business" onClick={chooseBusiness} />
                                        <div className="moreInfo">
                                            <BlueInfo/>
                                            <a href="/files/slides.pdf" target="__blank">More info about website and shipping platforms</a>
                                        
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div >
        </>
    );
}
export default BusinessDetailSliderPage;