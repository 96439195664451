import { css, keyframes } from "@emotion/react";

export const BlurFadeIn = (startOpacity: string=".8", startBlur: string="3px") => {
    return keyframes`
        from {
            opacity: ${startOpacity};
            filter: blur(${startBlur});
        }
        to {
            opacity: 1;
            filter: blur(0px);
        }
    `
};

export const FromRight = () => {
    return keyframes`
        from {
            position: relative;
            right: -100%;
            max-width: 10px;
        }
        to {
            position: relative;
            right: 0px;
            max-width: 50%;
        }
    `
};
export const FromLeft = () => {
    return keyframes`
        from {
            position: relative;
            right: 100%;
            max-width: 10px;
        }
        to {
            position: relative;
            right: 0px;
            max-width: 50%;
        }
    `
};

export const ExpandHeight = () => {
    return keyframes`
        from {
            max-height: 0px;
            overflow: hidden;
        }
        to {
            max-height: 100%;
            overflow: unset;
        }
    `
};

export const RotateFadeIn = () => {
    return keyframes`
        0% {
            opacity: 0.1;
            transform: rotate(0deg) scale(0.01);
            
        }
        100% {
            opacity: 1;
            transform: rotate(720deg)  scale(1);
        }
    `
};

export const ScaleFadeIn = () => {
    return keyframes`
        0% {
            opacity: 0.1;
            transform: scale(0.01);
            
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    `
};

export const PageLoadTransition2 = css`animation: ${RotateFadeIn()} 0.3s ease;`;
export const PageLoadTransition = css`animation: ${BlurFadeIn()} 0.3s ease;`;

export const BlurHover = css`&:hover{
    transition: all 0.3s ease;
    opacity: .9;
    filter: drop-shadow(0px 0px 5px blue) blur(2px);
}`;