import * as React from 'react';
import styled from '@emotion/styled';

import models, { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Button from '../../components/Button';
import TextContentArea from '../../components/TextContentArea';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import { RedDollarSign } from '../../util/SVG';
import DollarValue from '../../components/DollarValue';
import PostSelectionQuiz from './PostSelectionQuiz';
import { SubtitleText, Text24, Text32, TitleText } from '../../util/css';

interface BusinessSelectedPageProps { }

const BusinessSelectedPage: React.FunctionComponent<BusinessSelectedPageProps> = (props) => {
    const Element = styled('div')`
        overflow: hidden;
        min-height: 300px;
        height: 100%;
        .flipCardPanel {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px;
            gap: 10px;
            
            >*:not(.card) {
                width: 53px;
                // height: 50px;
                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 400;
                // font-size: 50px;
                // line-height: 30px;
                ${TitleText}
                /* identical to box height, or 60% */
                text-align: center;

                color: #212129;
            }
            .card {
                -webkit-perspective: 150rem;
                perspective: 150rem;
                -moz-perspective: 150rem;
                // 
                position: relative;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                
                padding: 60px 36px;
                height: 60px;
                width: 19vw;
                min-width: 133px;
                background: #31313D;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                user-select: none;
                cursor: pointer;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                // font-size: 30px;
                // line-height: 36px;
                ${Text32}

                color: #FFFFFF;
                justify-content: start;
                // padding-left: 50px;
                .open, .closed {//card_side
                    -webkit-transition: all 0.8s ease;
                    transition: all 0.8s ease;
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                }
                .closed {
                    display: block;
                    text-align: left;
                    
                }
                .open{
                    .label {
                        font-weight: 400;
                        // font-size: 24px;
                        ${Text24}
                    }
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);

                    //display: none;


                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    position: absolute;
                    > div {
                        width: 320px;
                    }
                    >div:first-child {
                        height: 48px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        svg {
                            position: absolute;
                            left: 0px;
                            width: 48px;
                        }
                        svg ~ * {
                            position: absolute;
                            left: 60px;
                        }
                        font-family: 'AP Type Pro Text';
                        font-style: normal;
                        font-weight: 500;
                        // font-size: 40px;
                        // line-height: 48px;
                        ${SubtitleText}
                        gap: 14px;
                    }
                    >div:last-child {
                        position: relative;
                        // left: 100px;
                        // bottom: 0px;
                        // margin-left: 90px;
                    }
                }
                &.active {
                    .closed{
                        // display: none;
                        -webkit-transform: rotateY(-180deg);
                        transform: rotateY(-180deg);
                    }
                    .open{
                        // display: flex;
                        -webkit-transform: rotateY(0);
                        transform: rotateY(0);
                        // color: #212129;
                        // background: #FFFFFF;
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    color: #212129;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                }
            }
        }
        .button {
            margin-top: 30px;
        }
    `;
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    return (
        <PageTemplateUpperText
            hideFooter
            textContent={<TextContentArea title={`${model.name} business selected`}>
                <p>
                    <span className="bold">Now you've made your selection, let's give you some more details about your business.</span>
                    <br />
                    <br />
                    Here are the numbers you will be working with across the 3 years:
                </p>
            </TextContentArea>}
        // whiteBackground
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </PageTemplateUpperText>
    );
}

const InnerElement: React.FunctionComponent<BusinessSelectedPageProps> = (props) => {
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const cardsChecked = React.useRef(0);
    const checkCard = (cardID: string) => {
        try {
            document.getElementById(cardID)?.classList.add("active")
            cardsChecked.current += 1;
            if (cardsChecked.current === 3) {
                document.getElementsByClassName('nextButton')[0].classList.remove('hidden');
            }
        } catch (error) {

        }
    }


    const next = () => {
        // if (cardsChecked.current === 3)
            dispatch(baseActions.setCurrentPage(PostSelectionQuiz));
    }
    return (
        <>
            <div className="flipCardPanel">
                <div className="card" id="revenueCard" onClick={() => checkCard(('revenueCard'))}>

                    <div className="closed">
                        Revenue before costs
                    </div>
                    <div className="open">
                        <div>
                            <RedDollarSign />
                            <DollarValue value={model.incomeAnalysis.revenueBeforeCosts} />
                        </div>
                        <div className="label">Revenue before costs</div>
                    </div>
                </div>
                <div>–</div>
                <div className="card" id="operatingCard" onClick={() => checkCard('operatingCard')}>
                    <div className="closed">
                        Operating costs
                    </div>
                    <div className="open">
                        <div>
                            <RedDollarSign />
                            <DollarValue value={model.incomeAnalysis.operatingCosts} />
                        </div>
                        <div className="label">Operating costs</div>
                    </div>
                </div>
                <div>=</div>
                <div className="card" id="targetCard" onClick={() => checkCard('targetCard')}>
                    <div className="closed">
                        Target 3-year operating profit
                    </div>
                    <div className="open">
                        <div>
                            <RedDollarSign />
                            <DollarValue value={model.targetProfit} />
                        </div>
                        <div className="label">Target 3-year operating profit</div>
                    </div>
                </div>
            </div>
            <Button className="nextButton hidden animate" text="Got it" onClick={next} />
        </>
    );
}
export default BusinessSelectedPage;