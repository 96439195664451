import * as React from 'react';
import styled from '@emotion/styled';

import { BizModel } from '../../lib/bizModels';
import { useSelector } from 'react-redux';
import Header from '../Header';
import ProgressBar from '../ProgressBar';
import Advice from '../Advice';
import CheckSetup from '../CheckSetup';
import ProgressBarAlternate from '../ProgressBarAlternate';

interface Props {
    textContent: React.ReactNode;
    children: React.ReactNode;
    noBackground?: boolean;
    whiteFill?: boolean;
    hideFooter?: boolean;
    showNav?: boolean;
    advice?: React.ReactNode;
}

const PageTemplateUpperText: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        position: absolute;
        top: 80px;
        left: 0px;
        background: ${props.whiteFill ? "white" : "#EEEEEE"};
        width: 100vw;
        // height: 100%;
        // height: 100vh;
        // min-height: 800px;
        // min-width: 1368px;
        > * {
            // min-width: 1200px;
        }
        // height: calc(100vh);

        .adviceOverlay, .setupOverlay {
            position: fixed;
            top: 80px;
            left: 0px;
            width: 100vw;
            height: calc(100vh - 80px);
            background: #2229;
            z-index: 5;
            > div {
                background: white;
            }
        }
        .background {
            position: fixed;
            top: 0; left: 0;
            width:100vw;
            height: 100vh;

            z-index: -1;
            background: ${props.whiteFill ? "white" : "#EEEEEE"};
        }
        .textContent {
            
            padding: 10px 47px;
            // padding-top: 82px;
            ${!props.noBackground && "background: white;"}
            
            > * {
                margin-top: 30px;
            }
        }
        .pageContent {
            // position:relative;
            margin-top: 30px;
            width: calc(100% - 47px);
            margin-left: 47px;
            // height: 100%;
        }
    `;

    return (
        <Element>
            <div className="background" />
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const [showAdvice, setShowAdvice] = React.useState(false);
    const [showSetup, setShowSetup] = React.useState(false);
    return (
        <>
            <Header
                advice={props.advice}
                showOptions={props.showNav}
                onAdviceSelect={() => { setShowAdvice(true); setShowSetup(false); }}
                onCheckSetupSelect={() => { setShowSetup(true); setShowAdvice(false); }}
            />
            {/* {showAdvice &&
                <div className="adviceOverlay">
                    <Advice onClose={() => setShowAdvice(false)} />
                </div>
            }
            {showSetup &&
                <div className="setupOverlay">
                    <CheckSetup onClose={() => setShowSetup(false)} />
                </div>
            } */}
            <div className="textContent text24">
                {props.textContent}
            </div>
            <div className="pageContent text24">
                {props.children}
            </div>
            {props.hideFooter || !model ? "" : <ProgressBar />}
        </>
    )
}

export default PageTemplateUpperText;