import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import { FlexCenter, Text20 } from '../../util/css';
import Button from '../../components/Button';
import { useDispatch } from 'react-redux';
import { baseActions } from '../../state/base';
import Quiz2Page from './Quiz2';
import NavigatingTheChallengePage from './NavigatingTheChallenge';


interface AdvicePageProps { }

const AdvicePage: React.FunctionComponent<AdvicePageProps> = (props) => {
    const Element = styled('div')`
        ${FlexCenter}
        .advicePanel {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 400;
            ${Text20}
            line-height: 32px;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 16px 45px;
            gap: 48px;

            background: #FFFFFF;
            border-radius: 8px;
            .leftPanel, .rightPanel {
                width: 22vw;
                >div >div:first-child {
                    margin-bottom: 20px;
                }
                height: 100%;
                @media(max-width: 1300px) {
                    width: 40vw;
                }
            }
            .leftPanel {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 20px;
            }
            .rightPanel {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 0px;
                gap: 40px;

                img {
                    // position: absolute;
                    bottom: 10px;
                    width: 100%;
                    // height: 290px;
                }
            }
        }
    `;
    return (
        <PageTemplate
            whiteBackground
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<AdvicePageProps> = (props) => {
    const dispatch = useDispatch();
    return (
        <div className="advicePanel">
            <div className='leftPanel'>
                <div>
                    <div className="bold">Stock investment</div>
                    <div>You'll need to have enough stock to meet demand. But you also need to balance this with other areas that might need investment. How much do you predict you’ll sell in your first year, and will your fulfilment model and lead time play a role?</div>
                </div>
                <div>
                    <div className="bold">Marketing & brand awareness</div>
                    <div>The money you invest now in marketing will form the basis of your organic (free) traffic throughout the game. How much do you want to spend on building awareness of your brand before your online store opens? How important is this to you now?</div>
                </div>
                <Button className="nextButton" text="Got it" onClick={() => dispatch(baseActions.setCurrentPage(NavigatingTheChallengePage))} />

            </div>
            <div className='rightPanel'>
                <div>
                    <div className='bold'>Optimising your website</div>
                    Your Shopify store comes with the basics, but investment in improving your website experience (e.g., improved checkout, easier navigation, subscription offerings) could help you attract more transactions for every customer that visits your site. How important is this to you now?
                </div>
                {/* <div> */}
                <img src="/images/consultant.png" />
                {/* </div> */}
            </div>
        </div>
    );
}
export default AdvicePage;