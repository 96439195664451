export const enum FulfillmentModel {
    coffee = "International supply and ship from my local warehouse",
    beauty = "Local manufacture/supply and ship from my warehouse",
    homewares = "Make and ship from my garage (with locally supplied materials)"
}

export const enum ShippingPolicy {
    free = "Free shipping",
    freeThreshhold = "Free shipping with $50 threshold",
    customerPays = "Customer pays"
}

export const enum ShippingModel {
    ausPost = "Australia Post",
    shippit = "Shipping Aggregator (Shippit)"
}

export const enum COMReturnPolicy {
    customerPays = "Customer Pays",
    businessPays = "Business pays"
}

export type BizType = "Coffee" | "Beauty" | "Homewares";

export type BizModel = {
    name: string;
    type: BizType;
    setup: {
        offering: string;
        webPlatform: "Shopify";
        fulfillmentModel: FulfillmentModel;
        stockLeadTime: string;
        shippingPolicy: ShippingPolicy;
        shippingModel: ShippingModel;
        comReturnPolicy: COMReturnPolicy;
    }
    advantages: Array<string>;
    disadvantages: Array<string>;
    incomeAnalysis: {
        averageOrderValue: number;
        revenueBeforeCosts: number;
        operatingCosts: number;
        
        hopefullyText: string;
        averageValueText: string;
    }
    targetProfit: number;
}


export const CoffeeModel: BizModel = {
    name: "Bloom Coffee",
    type: "Coffee",
    setup: {
        offering: "250gm-1kg specialty coffee beans",
        webPlatform: "Shopify",
        fulfillmentModel: FulfillmentModel.coffee,
        stockLeadTime: "3 months",
        shippingPolicy: ShippingPolicy.freeThreshhold,
        shippingModel: ShippingModel.shippit,
        comReturnPolicy: COMReturnPolicy.customerPays
    },
    advantages: [
        "High customer retention rate and purchase frequency",
        "Pick and pack cost is manageable for this type of business",
        "Fulfilling from your own DC allows to maintain a high service quality and improve your NPS, per-order, fulfilment costs are also lower because you can hire your own staff",
        "Sourcing goods internationally lowers COGS",
        "Shipping policy increases AOV, because customers are incentivised to order enough to reach the free shipping threshold",
        "Using a shipping aggregator may enable better rates",
        "Returns policy may reduce returns rate and reduce operating costs",
    ],
    disadvantages: [
        "International supply means 3-month lead time for stock",
        "Fulfilling from own DC attracts a higher monthly fixed cost",
        "Shipping costs may be a disincentive for customers who don't want to spend more than $50, leading to a lower conversion rate",
        "Using a shipping aggregator may impact customer retention rate",
        "Return policy may be a disincentive for first-time customers",
    ],
    incomeAnalysis: {
        averageOrderValue: 55,
        revenueBeforeCosts: 1211000,
        operatingCosts: 900000,

        hopefullyText: "Let's hope you sell some stock.",
        averageValueText: "The average order value for this product is $55. And you have to cover your operating costs.",

    },
    targetProfit: 311000
}

export const BeautyModel: BizModel = {
    name: "Miki Skin",
    type: "Beauty",
    setup: {
        offering: "High quality, organic 50ml skincare",
        webPlatform: "Shopify",
        fulfillmentModel: FulfillmentModel.beauty,
        stockLeadTime: "3 days",
        shippingPolicy: ShippingPolicy.free,
        shippingModel: ShippingModel.ausPost,
        comReturnPolicy: COMReturnPolicy.businessPays
    },
    advantages: [
        "Per-order fulfilment costs are lower because you can hire your own staff",
        "Fulfilling from your own DC allows you to maintain a high service quality and improve your NPS",
        "Local supply means short turn-around time for stock",
        "Free shipping will boost your conversion rate , but will also increase your costs if it's not built into your product pricing",
        "Using Australia Post gives you better account management, and availability of data will help improve your NPS score",
        "Returns policy may improve conversion and customer retention rate",
    ],
    disadvantages: [
        "Fulfilling from own DC attracts a higher monthly fixed cost",
        "The cost of goods to manufacture locally is higher than if you were to manufacture internationally or in your garage",
        "Shipping policy will decrease AOV because customers will not be incentivised to increase their basket size",
        "Using Australia Post may come at a higher cost of shipping",
        "Return policy means increased number and cost of returns",
    ],
    incomeAnalysis: {
        averageOrderValue: 95,
        revenueBeforeCosts: 1151000,
        operatingCosts: 1000000,

        hopefullyText: "Let's hope you can keep them coming back.",
        averageValueText: "The average order value for this product is $95. And you have to cover all shipping expenses.",
    },
    targetProfit: 151000
}

export const HomewaresModel: BizModel = {
    name: "Urban Artisan",
    type:"Homewares",
    setup: {
        offering: "Contemporary handcrafted artisan plates and bowls",
        webPlatform: "Shopify",
        fulfillmentModel: FulfillmentModel.homewares,
        stockLeadTime: "Minimal, produced in own garage",
        shippingPolicy: ShippingPolicy.customerPays,
        shippingModel: ShippingModel.ausPost,
        comReturnPolicy: COMReturnPolicy.customerPays
    },
    advantages: [
        "Cost of goods and Pick-and-Pack cost are generally low",
        "Making and ship from own garage is relatively cost-effective",
        "Shipping policy will decrease your costs",
        "Using Australia Post gives you better account management, and availability of data will help improve your NPS score",
        "Returns policy means reduced number and cost of returns",
    ],
    disadvantages: [
        "Fulfillment model limits the amount manufactured and number of orders you can fulfil per week",
        "Shipping policy will decrease your conversion rate because customers may be put off by having to pay for shipping",
        "Using Australia Post may come at a higher cost of shipping",
        "Returns policy may affect conversion, customer retention rate and NPS",
    ],
    incomeAnalysis: {
        averageOrderValue: 250,
        revenueBeforeCosts: 550000,
        operatingCosts: 471000,

        hopefullyText: "Let's hope they see the value.",
        averageValueText: "The average order value for this product is $250. And you have to make everything, so your fulfilment capacity might be limited.",
    },
    targetProfit: 79000
}

const businesses = [CoffeeModel, BeautyModel, HomewaresModel]
export default businesses;
