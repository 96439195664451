import { NumericFormat } from "react-number-format";

interface DollarValueProps {
    value: number;
    dollarSign?: boolean;
}


const DollarValue: React.FunctionComponent<DollarValueProps> = (props) => {
    return (
        <NumericFormat value={props.value} displayType={'text'} thousandSeparator={true} prefix={props.dollarSign?'$':undefined} />
    );
}

export default DollarValue;