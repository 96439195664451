import * as React from 'react';
import styled from '@emotion/styled';
import businesses, { BizType } from '../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../state/base';
import StartupSpendPage, { gety1s1TestData } from '../pages/yearOne/StartupSpend';
import { TestingData } from '../lib/util';
import { gety1s2TestData } from '../pages/yearOne/BlackFriday';
import { gety2s1TestData } from '../pages/yearTwo/ChallengeContinues';
import { gety3s1TestData } from '../pages/yearThree/RatesIncreaseAction';
import RatesIncreaseResultPage from '../pages/yearThree/RatesIncreaseResult';
import CompetitorResultPage from '../pages/yearTwo/CompetitorResult';
import ChallengeContinuesResultPage from '../pages/yearTwo/ChallengeContinuesResult';
import BlackFridayResultPage from '../pages/yearOne/BlackFridayResult';
import StartupSpendResultPage from '../pages/yearOne/StartupSpendResult';
import { gety3s2TestData } from '../pages/yearThree/GrantSpendAction';
import GrantSpendResultPage from '../pages/yearThree/GrantSpendResult';
import { gety2s2TestData } from '../pages/yearTwo/CompetitorAction';
import Button from './Button';
import NavigatingTheChallengePage from '../pages/setup/NavigatingTheChallenge';

//  TODO
//  ADD props where needed in setCurrentPage
//  update choices before setting current page
//
//




interface TestingProps { }

const Testing: React.FunctionComponent<TestingProps> = (props) => {
    const Element = styled('div')`
    z-index: 100;
    background: white;
    height: 120px;
    display: flex;
        * {
            margin-left: 5px;
        }
        *:has(button) {
            display: inline-block;
        }
        button {
            height: 40px;
            padding: 10px 30px;
        }
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<TestingProps> = (props) => {
    const [business, setBusiness] = React.useState<string>("Bloom Coffee");
    const [y1s1, sety1s1] = React.useState<string>();
    const [y1s2, sety1s2] = React.useState<string>();
    const [y2s1, sety2s1] = React.useState<string>();
    const [y2s2, sety2s2] = React.useState<string>();
    const [y3s1, sety3s1] = React.useState<string>();
    const [y3s2, sety3s2] = React.useState<string>();

    React.useEffect(() => {
        console.log("hello")
        sety1s1(undefined);
        sety1s2(undefined);
        sety2s1(undefined);
        sety2s2(undefined);
        sety3s1(undefined);
        sety3s2(undefined);
        console.log(y1s1)
    }, [business]);

    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const dispatch = useDispatch();

    const getImpact = (options: TestingData, biz: string, option: string) => {
        return options.find(val => val.biz == biz && val.option == option)?.profitImpact || 0;
    }
    const goToDecision = () => {
        dispatch(baseActions.clearAll());
        dispatch(baseActions.selectBusiness(businesses.find(val => val.name === business)));

        if (!y1s1) {
            dispatch(baseActions.setCurrentPage(StartupSpendPage));
            return;
        }
        if (y1s1) {
            const y1s1Data = gety1s1TestData();
            if (!y1s2) {
                dispatch(baseActions.setCurrentPage([StartupSpendResultPage, { choice: y1s1! }]));
                return;
            }
            else {
                const newChoices = JSON.parse(JSON.stringify(choices));
                newChoices.startupSpend = y1s1!;
                dispatch(baseActions.setChoices(newChoices));
                dispatch(baseActions.adjustProjection(getImpact(y1s1Data, business, y1s1)));
            }
        }
        if (y1s2) {
            const data = gety1s2TestData();
            if (!y2s1) {
                dispatch(baseActions.setCurrentPage([BlackFridayResultPage, { choice: y1s2! }]));
                return;
            }
            else {
                const newChoices = JSON.parse(JSON.stringify(choices));
                newChoices.blackFriday = y1s2!;
                dispatch(baseActions.setChoices(newChoices));
                dispatch(baseActions.adjustProjection(getImpact(data, business, y1s2)));
            }
        }
        if (y2s1) {
            const data = gety2s1TestData(y1s2!);
            if (!y2s2) {
                dispatch(baseActions.setCurrentPage([ChallengeContinuesResultPage, { choice: y2s1! }]));
                return;
            }
            else {
                const newChoices = JSON.parse(JSON.stringify(choices));
                newChoices.challengeContinues = y2s1!;
                dispatch(baseActions.setChoices(newChoices));
                dispatch(baseActions.adjustProjection(getImpact(data, business, y2s1)));
            }
        }
        if (y2s2) {
            const data = gety2s2TestData();
            if (!y3s1) {
                dispatch(baseActions.setCurrentPage([CompetitorResultPage, { choice: y2s2! }]));
                return;
            }
            else {
                const newChoices = JSON.parse(JSON.stringify(choices));
                newChoices.competitor = y2s2!;
                dispatch(baseActions.setChoices(newChoices));
                dispatch(baseActions.adjustProjection(getImpact(data, business, y2s2)));
            }
        }
        if (y3s1) {
            const data = gety3s1TestData(y2s2!);
            if (!y3s2) {
                dispatch(baseActions.setCurrentPage([RatesIncreaseResultPage, { choice: y3s1! }]));
                return;
            }
            else {
                const newChoices = JSON.parse(JSON.stringify(choices));
                newChoices.ratesIncrease = y3s1!;
                dispatch(baseActions.setChoices(newChoices));
                dispatch(baseActions.adjustProjection(getImpact(data, business, y3s1)));
            }
        }
        if (y3s2) {
            const data = gety3s2TestData();
            // const newChoices = JSON.parse(JSON.stringify(choices));
            // newChoices.grantSpend = y3s2!;
            // dispatch(baseActions.setChoices(newChoices));
            // dispatch(baseActions.adjustProjection(getImpact(data, business, y3s2)));
            dispatch(baseActions.setCurrentPage([GrantSpendResultPage, { choice: y3s2! }]));
            return;
        }
    }
    return (
        <div className="testArea">
            <label>
                Business
                <select id="business"
                    onChange={
                        e => {
                            const val = (e.target as HTMLSelectElement).value as BizType;
                            setBusiness(val);
                        }}
                >
                    <option value="Bloom Coffee">Coffee</option>
                    <option value="Miki Skin">Miki Skin</option>
                    <option value="Urban Artisan">Urban Artisan</option>
                </select>

            </label>
            {business &&
                <label>
                    <Button text="navigate" onClick={()=>{
                        dispatch(baseActions.selectBusiness(businesses.find(val => val.name === business)));
                        dispatch(baseActions.setCurrentPage(NavigatingTheChallengePage))
                    }}/>
                    y1 h1
                    <select id="y1s1"
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety1s1(val);
                            }}
                    >
                        <option value={undefined} selected={y1s1 === undefined}></option>
                        <option value={"stock"} selected={y1s1 === "stock"}>Stock</option>
                        <option value={"marketing"} selected={y1s1 === "marketing"}>Marketing</option>
                        <option value={"balance"} selected={y1s1 === "balance"}>Balance</option>
                    </select>
                </label>
            }
            {y1s1 &&
                <label>
                    y1 h2
                    <select id="y1s2"
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety1s2(val);
                            }}
                    >
                        <option value={undefined} selected={y1s2 === undefined}></option>
                        <option value={"staff"} selected={y1s2 === "staff"}>Staff</option>
                        <option value={"campaign"} selected={y1s2 === "campaign"}>Campaign</option>
                    </select>
                </label>
            }
            {y1s2 &&
                <label>
                    y2 h1
                    <select
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety2s1(val);
                            }}
                    >
                        <option value={undefined} selected={y2s1 === undefined}></option>
                        <option value={"ads"} disabled={y1s2 === "campaign"} selected={y2s1 === "ads"}>Ads</option>
                        <option value={"tool"} disabled={y1s2 === "campaign"} selected={y2s1 === "tool"}>Tool</option>
                        <option value={"delivery"} disabled={y1s2 === "staff"} selected={y2s1 === "delivery"}>Delivery</option>
                        <option value={"personalisation"} disabled={y1s2 === "staff"} selected={y2s1 === "personalisation"}>Personalisation</option>
                    </select>
                </label>
            }
            {y2s1 &&
                <label>
                    y2 h2
                    <select
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety2s2(val);
                            }}>
                        <option value={undefined} selected={y2s1 === undefined}></option>
                        <option value={"shipping"} disabled={business !== "Bloom Coffee"} selected={y2s2 === "shipping"}>Shipping</option>
                        <option value={"marketing"} disabled={business !== "Bloom Coffee"} selected={y2s2 === "marketing"}>Marketing</option>
                        <option value={"campaign"} disabled={business !== "Miki Skin"} selected={y2s2 === "campaign"}>Campaign</option>
                        <option value={"adsThreshold"} disabled={business !== "Miki Skin"} selected={y2s2 === "adsThreshold"}>Ads Threshold</option>
                        <option value={"freeShipping"} disabled={business !== "Urban Artisan"} selected={y2s2 === "freeShipping"}>Free Shipping</option>
                        <option value={"conditionalShipping"} disabled={business !== "Urban Artisan"} selected={y2s2 === "conditionalShipping"}>Conditional Shipping</option>
                    </select>
                </label>
            }
            {y2s2 &&
                <label>
                    y3 h1
                    <select id="y3s1"
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety3s1(val);
                            }}>
                        <option value={undefined}></option>
                        <option value={"threshold"} selected={y3s1 === "shipping"}> Threshold Shipping</option>
                        <option value={"paidShipping"} selected={y3s1 === "marketing"}>Paid Shipping</option>
                    </select>
                </label>
            }
            {y3s1 &&
                <label>
                    y3 h2
                    <select id="y3s1"
                        onChange={
                            e => {
                                const val = (e.target as HTMLSelectElement).value;
                                sety3s2(val);
                            }}>
                        <option value={undefined}></option>
                        <option value={"website"} selected={y3s1 === "website"}>Website</option>
                        <option value={"socialMedia"} selected={y3s1 === "socialMedia"}>Social Media</option>
                        <option value={"balance"} selected={y3s1 === "balance"}>Balance</option>
                    </select>
                </label>
            }
            <Button text="GO" onClick={goToDecision} />
        </div>
    );
}
export default Testing;