import * as React from 'react';
import styled from '@emotion/styled';
import TextContentArea from '../../components/TextContentArea';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import Info from '../../components/Info';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { RedDollarSign, RedTick, RedWrong, WhiteLeftChevron } from '../../util/SVG';
import { FromLeft, FromRight } from '../../util/Transitions';
import DollarValue from '../../components/DollarValue';
import { useDispatch, useSelector } from 'react-redux';
import { BizModel } from '../../lib/bizModels';
import { baseActions } from '../../state/base';
import InstalledToolsPage from './InstalledTools';
import InfoPanel from '../../components/InfoPanel';
import QuizSelect from '../../components/QuizSelect';
import { AnsweredQuiz, WHatsInANumberQuiz } from '../../lib/quiz';


interface WhatsInANumberProps {

}

const WhatsInANumber: React.FunctionComponent<WhatsInANumberProps> = (props) => {
    const Element = styled('div')`
    width: 100%;
    label {
        display: inline-block;
        width: 100%;
    }
    display: flex;
    gap: 40px;
    .leftPanel {
        height: 100%;
        width: min(800px, 44vw);
        margin-top: 20px;
    }
    .rightPanel {
        width: min(900px, 40vw);
        // display: none;
        position: relative;
        z-index: 50;
        .chevron1, .chevron2, .chevron3{
            display: none;
            // background: blue;
            z-index: 1000;
            position: absolute;
            left: -25px;
        }
        
        &:has(.quizFeedback){
            
            margin-top: -10px;
            animation: ${FromRight()} 0.33s ease;
            .chevron1, .chevron2, .chevron3{
                display: block;
            }
            .chevron1{
                top: 36%;
            }
            .chevron2{
                top: 57.5%;
            }
            .chevron3{
                top: 80%;
            }
        }
        &:has(.aovInfo){
            .aovInfo{
                margin-top: 50px;
            }
            margin-top: 200px;
            animation: ${FromRight()} 0.33s ease;
            .chevron1 {
                display: block;
                top: 65%;
            }
        }
    }
    .quizQuestions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 38px;
    }
    .quizFeedback {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 40px 48px;
        // gap: 40px;

        background: #FFFFFF;
        border-radius: 8px;
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;

                // width: 634px;
                // height: 524px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;

            }
            .detail {
                // width: 634px;
                // height: 452px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;

                color: #212129;
                ul {
                    // margin-top: 100px;
                }
                li {
                    margin-top: 15px;
                }
            }
        }

    }
    `;

    const [trafficAnswer, setTrafficAnswer] = React.useState<string>();
    const [conversionAnswer, setConversionAnswer] = React.useState<string>();
    const [aovAnswer, setAOVAnswer] = React.useState<string>();
    const [showFeedBack, setshowFeedBack] = React.useState(false);
    const [gotIt, setGotIt] = React.useState(false);
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);

    const quiz = WHatsInANumberQuiz;
    const quizID = `quiz_${quiz.id}`;

    const answeredQuiz = useSelector((state: any) => state.base.quizAnswers);
    const dispatch = useDispatch();



    const checkAnswer = () => {
        var incorrect = false;
        var allAnswered = true;
        let elements = document.querySelectorAll(`.quizQuestion`);
        var answers: Array<{ id: string, answer: string }> = [];
        console.log(elements);
        elements.forEach((el, index) => {
            const val = (el as HTMLInputElement).value;
            if (val === undefined)
                allAnswered = false;
            const correct = val === quiz.questions[index].correct
            answers.push({ id: quiz.questions[index].id, answer: val });
            if (!correct) {
                incorrect = true;
            }
        });
        if (allAnswered) {
            dispatch(baseActions.setQuizAnswered({ quizID, answers }));
            setshowFeedBack(true);
        }
    }

    const isCorrect = () => {
        return (
            trafficAnswer === quiz.questions[0].correct &&
            conversionAnswer === quiz.questions[1].correct &&
            aovAnswer === quiz.questions[2].correct
        )
    }
    return (
        <PageTemplateUpperText
            hideFooter
            whiteFill={!showFeedBack}
            noBackground={showFeedBack}
            textContent={<TextContentArea title="What's in a number?" >
                <Element>
                    <div className='leftPanel'>
                        <Info text="Revenue = Traffic x Conversion rate x AOV" />
                        <p>
                            Revenue is made by multiplying these three metrics. Have a go at matching what each component means.
                        </p>
                        <div id={quizID} className='quizQuestions'>
                            {quiz.questions.map((val, index) => {
                                return (
                                    <>
                                        <label className="bold">
                                            {val.question}
                                            <QuizSelect

                                                className="quizQuestion"
                                                id={val.id}
                                                correct={val.correct === (answeredQuiz[quizID] ? answeredQuiz[quizID][index].answer : undefined)}
                                                selected={
                                                    answeredQuiz[quizID] ? answeredQuiz[quizID][index].answer :
                                                        (val.id === "1a") ?
                                                            trafficAnswer :
                                                            (val.id === "1b") ?
                                                                conversionAnswer :
                                                                aovAnswer
                                                }
                                                disabled={answeredQuiz[quizID] !== undefined}

                                                onSelect={(selected) => {
                                                    if (val.id === "1a")
                                                        setTrafficAnswer(selected);
                                                    if (val.id === "1b")
                                                        setConversionAnswer(selected);
                                                    if (val.id === "1c")
                                                        setAOVAnswer(selected);
                                                }}
                                            >
                                                <option value={undefined}>Select</option>
                                                {val.options.map(opt => {
                                                    return (
                                                        <option
                                                            value={opt}
                                                        >
                                                            {opt}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </QuizSelect>
                                        </label>
                                    </>
                                )

                            })}
                            {!showFeedBack && (trafficAnswer && conversionAnswer && aovAnswer) && <Button text="Check answer" className='' onClick={checkAnswer} />}
                        </div>
                    </div>
                    {showFeedBack &&
                        <div className='rightPanel'>
                            <WhiteLeftChevron className="chevron1" />
                            <WhiteLeftChevron className="chevron2" />
                            <WhiteLeftChevron className="chevron3" />
                            {!gotIt &&
                                <InfoPanel
                                    className="quizFeedback"
                                    headerIcon={isCorrect() ? <RedTick /> : <RedWrong />
                                    }
                                    headerTitle={isCorrect() ? "That's right!" : "Not Quite"}
                                    onGotIt={() => setGotIt((true))}
                                >
                                    {/* {trafficAnswer} - {conversionAnswer} - {aovAnswer} */}
                                    <p className=' text24'>Let’s see what they all mean and how you did.</p>
                                    <ul className='text24'>
                                        <li><b>Traffic</b><br />The number of website sessions you have in a certain period of time. These sessions can be created by new or returning visitors.</li>
                                        <li><b>Conversion rate</b><br />The percentage of traffic that ended up as transactions.</li>
                                        <li><b>AOV</b><br />The average value of each order for a single transaction. </li>
                                    </ul>

                                </InfoPanel>
                            }
                            {gotIt &&
                                <InfoPanel
                                    className="aovInfo"
                                    nextText='Next'
                                    headerIcon={<RedDollarSign />}
                                    headerTitle='Average order value'
                                    onGotIt={() => dispatch(baseActions.setCurrentPage(InstalledToolsPage))}
                                >
                                    <p>Now we know the importance of these numbers, {model.name}&apos;s average order value is <span className="bold"><DollarValue dollarSign value={model.incomeAnalysis.averageOrderValue} /></span> </p>
                                    <p>Remember this is the average value for each order in a single transaction.</p>
                                </InfoPanel>
                            }
                        </div>
                    }
                </Element>
            </TextContentArea >}
        >
        </PageTemplateUpperText >
    );
}

const InnerElement: React.FunctionComponent<WhatsInANumberProps> = (props) => {
    return (
        <>

        </>
    );
}
export default WhatsInANumber;
