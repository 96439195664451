import * as React from 'react';
import { baseActions } from '../../state/base';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import CompetitorResultPage from './CompetitorResult';
import { BizModel } from '../../lib/bizModels';
import { TestingData } from '../../lib/util';


interface Props { }

export const gety2s2TestData = (): TestingData => {
    return [
        { biz: "Coffee", option: "shipping", profitImpact: -.5 },
        { biz: "Coffee", option: "marketing", profitImpact: .05 },
        { biz: "Beauty", option: "campaign", profitImpact: 0 },
        { biz: "Beauty", option: "adsThreshold", profitImpact: .5 },
        { biz: "Homewares", option: "freeShipping", profitImpact: -.7 },
        { biz: "Homewares", option: "conditionalShipping", profitImpact: .5 },
    ]
}
// export type CompetitorKeysType = "shipping" | "marketing" | "campaign" | "adsThreshold" | "freeShipping" | "conditionalShipping";

export const CompetitorOptions = {
    "Coffee": {
        text: "A new competitor enters the market, driving up the cost of advertising. You're now getting less traffic from the same marketing spend.<br/>There are a couple of options available. Think about why you will choose one option over the other. What do you think the impacts will be?<br/><br/><b>Choose one</b>:",
        options: {
            "shipping": {
                text: "Don't change your marketing investment, but change your shipping policy to free shipping on all orders to convert more customers",
                resultText: "Oh dear! You have successfully increased your conversion rate by offering free shipping on all orders, but your average order value has dropped dramatically, which makes all those new orders much more costly to fulfil.",
                imperative: "",
                profitImpact: -.5
            },
            "marketing": {
                text: "Increase your marketing spend but don't make any changes to your policies",
                resultText: "Congratulations! You understand that with a low average sell price, it's important to keep your average order value high to maintain profitability.",
                imperative: "",
                profitImpact: .05
            }
        },
        consultantAdvice:
            <>
                <div className="bold">
                    Paid advice from your consultant:
                </div>
                <div>In the coffee business, you're dealing with products with low per-product selling price. To be profitable, you're going to want to maintain your high average order value. </div>
                <div>Having been in the eCommerce business for more than a decade, I know for a fact that providing free shipping without a threshold on your type of consumable product will incentivise customers to buy fewer bags of coffee in each transaction, driving your Average Order Value down. </div>
                <div>Given you already have the most favourable delivery policy and you’re still in growth phase, choosing an option that increases the traffic to your website is the way to go.</div>
                <div>Good luck!</div>
            </>
    },
    "Beauty": {
        text: "A new competitor enters the market and is offering a similar product to you, driving up the cost of advertising.<br/> What do you do? Choose one:",
        options: {
            "campaign": {
                text: "Launch an email and social campaign to your existing audience to highlight your unique selling point of free shipping on all orders",
                resultText: "Your campaigns get great traction and you're definitely driving additional traffic to your website (and converting it well). However, your average order value remains quite low, making it difficult to turn a profit.",
                imperative: "",
                profitImpact: 0
            },
            "adsThreshold": {
                text: "Increase your spend on google ads and introduce a minimum spend threshold to qualify for free shipping",
                resultText: "Congratulations! Introducing a minimum spend threshold to qualify for free shipping means that your average order value has increased significantly, making every order much more profitable. Conversion rate has dipped only marginally, but overall profitability has gone through the roof!",
                imperative: "",
                profitImpact: .5
            }
        },
        consultantAdvice:
            <>
                <div className="bold">
                    Paid advice from your consultant:
                </div>
                <div>Launching an email campaign is a low-cost way of promoting your business. However, you're still trying to grow your business, so you may want to focus your efforts on reaching a new audience (rather than marketing to a relatively small pool of existing customers).</div>
                <div>In the beauty industry, you tend to have a medium to high Average Sell Price (ASP) with relatively low shipping costs, so you've been able to remain profitable, despite absorbing all shipping costs.</div>
                <div>Do you know what will really boost your profitability though? Introducing a minimum spend for free shipping. This will incentivise customers to buy more in one transaction, which will significantly increasing the average value of each order.</div>
                <div>With this strategy, you&apos;ll see an improvement in your bottom line.</div>
                <div className='bold'>Good luck!</div>
            </>
    },
    "Homewares": {
        text: "Congratulations! You've finally outgrown your garage and have migrated to a small warehouse, increasing your capacity to fulfil orders. You've decided to update your shipping policy to help convert more customers that visit your website.<br/> Which policy will you choose?",
        options: {
            "freeShipping": {
                text: "Free shipping on all orders (no minimum spend required)",
                resultText: "Wow! Introducing free shipping has massively increased your conversion rate and sales overall! However, your average order value has plummeted to $150, which, coupled with high costs to pick and send each order, means your profitability has plummetted.",
                imperative: "",
                profitImpact: -.7
            },
            "conditionalShipping": {
                text: "Free shipping with all orders over $200",
                resultText: "Wow! Introducing a minimum spend threshold to qualify for free shipping has increased your conversion rate significantly without materially impacting your average order value! Demand for your product is higher than ever and now you have the capacity to deal with all the additional orders.",
                imperative: "",
                profitImpact: .5
            }
        },
        consultantAdvice:
            <>
                <div className="bold">
                    Paid advice from your consultant:
                </div>
                <div>Up until now, you've maintained a relatively high average order value by requiring customers to pay for shipping. Generally speaking, if customers are required to pay for shipping, they will spend more in each transaction to get the best value for their shipping dollar. But how many transactions are you losing at checkout, once customers realise that they have to pay for shipping?</div>
                <div>Free shipping can boost your conversion rate (the percentage of traffic that ends up as transactions). However, to stay profitable, you want to maintain your high average order value. This is especially true when it comes to a homewares business like yours, where shipping costs tend to be high.</div>
                <div>One way to protect your profitability is to introduce a minimum spend for free shipping. This will incentivise customers to buy more in one transaction, increasing your average order value.</div>
                <div>Consider this before you decide to offer free shipping on all orders.</div>
                <div className='bold'>Good luck!</div>
            </>
    }
}


const CompetitorActionPage: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    return (
        <BusinessActionTemplate
            era="y2s2"
            decisionTitle='Your second major decision in Year 2'
            optionCount={Object.keys(CompetitorOptions[model.type].options).length}
            advice={CompetitorOptions[model.type].consultantAdvice}
            showNav

        >
            <InnerElement {...props} />
        </BusinessActionTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);

    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y2s2"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([CompetitorResultPage, { choice: activeCard }]));
    }

    return (
        <>
            <p dangerouslySetInnerHTML={{ __html: CompetitorOptions[model.type].text }} />
            <div className="cardPanel">
                {Object.keys(CompetitorOptions[model.type].options).map((val, index) => {
                    return (
                        <div className={`card ${activeCard === val && "active"}`} id={`${val}Card`} onClick={() => setActiveCard((val))}>
                            {(CompetitorOptions[model.type].options as any)[val].text}
                        </div>
                    )
                })}
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />
            }
        </>
    );
}
export default CompetitorActionPage;