

export const CoffeeIcon = () => {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_547_5585)">
                <path d="M3 47.9925C3 23.6735 22.38 3.79797 46.5 3.00235L46.5 1.89015e-07C20.73 0.795622 9.38823e-08 22.0222 2.04596e-07 47.9925C3.15309e-07 73.9628 20.73 95.1894 46.5 95.9851L46.5 92.9827C22.38 92.1871 3 72.3116 3 47.9925Z" fill="black" />
                <path d="M49.5 0L49.5 3.00235C73.62 3.79797 93 23.6735 93 47.9925C93 72.3116 73.62 92.1871 49.5 92.9827L49.5 95.9851C75.27 95.1894 96 73.9628 96 47.9925C96 22.0222 75.27 0.795622 49.5 0Z" fill="black" />
                <circle cx="48" cy="48" r="48" fill="#892D52" />
                <path d="M70.9047 43.504C72.5847 43.6991 74.0847 44.5398 75.1347 45.8758C76.1847 47.2119 76.6497 48.8632 76.4547 50.5595C76.2597 52.2408 75.4197 53.742 74.0847 54.7928C72.7497 55.8436 71.0847 56.309 69.4047 56.1138C68.8797 56.0538 68.3847 55.9037 67.8897 55.7235C67.5597 56.6843 67.1697 57.615 66.7197 58.5007C67.4547 58.7859 68.2347 58.9961 69.0447 59.1012C69.4197 59.1462 69.7797 59.1612 70.1547 59.1612C72.2397 59.1612 74.2647 58.4707 75.9297 57.1496C77.8947 55.6034 79.1247 53.3817 79.4247 50.9048C79.7247 48.4278 79.0197 45.9809 77.4747 44.0144C75.9297 42.0478 73.7097 40.8169 71.2347 40.5166C70.4997 40.4266 69.7797 40.4416 69.0747 40.5166L69.0747 43.564C69.6747 43.4589 70.2747 43.4139 70.8897 43.489L70.9047 43.504Z" fill="white" />
                <path d="M27 48.713C27 53.6369 28.92 58.1254 32.04 61.473L36.54 61.473C32.58 58.6057 30 53.9671 30 48.713L30 35.953L63.075 35.953L63.075 48.713C63.075 53.9671 60.495 58.6057 56.535 61.473L61.035 61.473C64.155 58.1254 66.075 53.6369 66.075 48.713L66.075 32.9507L27 32.9507L27 48.713Z" fill="white" />
                <path d="M42.0448 23.3883C43.4698 20.9714 45.2098 18.0141 43.6798 13.4355L40.4248 13.4355C42.1048 17.2335 40.9048 19.3352 39.4948 21.737C38.2498 23.8537 36.7498 26.4057 37.9198 29.9485L41.1898 29.9485C39.8098 27.2313 40.6948 25.6701 42.0448 23.3883Z" fill="white" />
                <path d="M50.19 29.9483C49.125 27.8467 49.845 27.1111 52.065 25.3697C54.15 23.7485 57.165 21.3616 55.635 16.4978L52.41 16.4978C53.955 19.9505 52.5 21.1364 50.28 22.8628C48.48 24.2589 45.915 26.2854 46.965 29.9483L50.205 29.9483L50.19 29.9483Z" fill="white" />
                <path d="M47.325 64.4753L19.5 64.4753L19.5 66.2017C19.5 70.2098 22.77 73.4824 26.775 73.4824L66.3 73.4824C70.305 73.4824 73.575 70.2098 73.575 66.2017L73.575 64.4753L47.325 64.4753ZM66.3 70.48L26.775 70.48C24.855 70.48 23.235 69.219 22.695 67.4777L70.38 67.4777C69.84 69.219 68.205 70.48 66.3 70.48Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_547_5585">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
};

export const BeautyIcon = () => {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_547_5701)">
                <path d="M2.04596e-07 47.9921C3.15374e-07 73.9774 20.73 95.189 46.5 95.9846L46.5 92.9823C22.38 92.1867 3 72.3111 3 47.9921C3 23.6731 22.38 3.7976 46.5 3.00198L46.5 -0.000366022C20.73 0.795255 9.38826e-08 22.0218 2.04596e-07 47.9921Z" fill="#DC1928" />
                <path d="M49.5 -0.000366211L49.5 3.00198C73.62 3.7976 93 23.6731 93 47.9921C93 72.3111 73.62 92.1867 49.5 92.9823L49.5 95.9846C75.27 95.189 96 73.9624 96 47.9921C96 22.0218 75.27 0.795255 49.5 -0.000366211Z" fill="#DC1928" />
                <circle cx="48" cy="48.0001" r="48" fill="#6EC4C7" />
                <path d="M59.1296 78.0307L36.9896 78.0307C33.9896 78.0307 31.5596 75.5988 31.5596 72.5964L31.5596 38.4297C31.5596 35.4274 33.9896 32.9955 36.9896 32.9955L59.1296 32.9955C62.1296 32.9955 64.5596 35.4274 64.5596 38.4297L64.5596 72.5964C64.5596 75.5988 62.1296 78.0307 59.1296 78.0307ZM36.9896 35.9978C35.6546 35.9978 34.5596 37.0937 34.5596 38.4297L34.5596 72.5964C34.5596 73.9325 35.6546 75.0283 36.9896 75.0283L59.1296 75.0283C60.4646 75.0283 61.5596 73.9325 61.5596 72.5964L61.5596 38.4297C61.5596 37.0937 60.4646 35.9978 59.1296 35.9978L36.9896 35.9978Z" fill="white" />
                <path d="M57.0596 29.9931L54.0596 29.9931L54.0596 28.4919C54.0596 27.6062 53.3396 26.8856 52.4546 26.8856L43.6646 26.8856C42.7796 26.8856 42.0596 27.6062 42.0596 28.4919L42.0596 29.9931L39.0596 29.9931L39.0596 28.4919C39.0596 25.9549 41.1296 23.8833 43.6646 23.8833L52.4546 23.8833C54.9896 23.8833 57.0596 25.9549 57.0596 28.4919L57.0596 29.9931Z" fill="white" />
                <path d="M54.0599 20.9861L51.0599 20.9861L51.0599 18.6593C51.0599 17.4583 50.0849 16.4826 48.8849 16.4826L42.7499 16.4826C42.6149 16.4826 42.5099 16.5426 42.4349 16.6777C42.4049 16.7528 42.3749 16.8579 42.4349 16.978L44.2949 18.7644C44.7899 19.3948 45.0599 20.1905 45.0599 20.9861L42.0599 20.9861C42.0599 20.881 42.0299 20.7609 41.9699 20.6708L40.0649 18.8394C39.2999 17.8186 39.1799 16.4676 39.7499 15.3267C40.3199 14.1858 41.4599 13.4802 42.7499 13.4802L48.8849 13.4802C51.7349 13.4802 54.0599 15.807 54.0599 18.6593L54.0599 20.9861Z" fill="white" />
                <path d="M55.5596 49.5084L40.5596 49.5084L40.5596 52.5108L55.5596 52.5108L55.5596 49.5084Z" fill="white" />
                <path d="M52.5596 57.0143L43.5596 57.0143L43.5596 60.0166L52.5596 60.0166L52.5596 57.0143Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_547_5701">
                    <rect width="96" height="96" fill="white" transform="translate(0 -0.000366211)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const HomewaresIcon = () => {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_547_5782)">
                <path d="M3 48C3 23.67 22.38 3.79503 46.5 3.00003L46.5 3.07066e-05C20.73 0.795031 9.38732e-08 22.02 2.04628e-07 48C3.15383e-07 73.98 20.73 95.205 46.5 96L46.5 93C22.38 92.205 3 72.33 3 48Z" fill="#DC1928" />
                <path d="M49.5 3.05176e-05L49.5 3.00003C73.62 3.79503 93 23.67 93 48C93 72.33 73.62 92.205 49.5 93L49.5 96C75.27 95.205 96 73.98 96 48C96 22.02 75.27 0.795031 49.5 3.05176e-05Z" fill="#DC1928" />
                <circle cx="48" cy="48.0003" r="48" fill="#ED792F" />
                <path d="M30.0599 43.5C30.0599 31.08 40.1549 20.985 52.5599 20.985C64.9649 20.985 75.0599 31.08 75.0599 43.5C75.0599 53.31 68.7449 61.65 59.9849 64.725C59.2799 66.06 58.4549 67.32 57.5399 68.52C69.2249 66.195 78.0449 55.86 78.0449 43.5C78.0449 29.43 66.5999 17.985 52.5449 17.985C38.4899 17.985 27.0449 29.43 27.0449 43.5C27.0449 44.505 27.1199 45.51 27.2399 46.5L30.2549 46.5C30.1199 45.51 30.0449 44.505 30.0449 43.5L30.0599 43.5Z" fill="white" />
                <path d="M52.5596 31.5C59.1746 31.5 64.5596 36.885 64.5596 43.515L67.5596 43.515C67.5596 35.235 60.8246 28.5 52.5596 28.5C44.2946 28.5 37.5596 35.235 37.5596 43.515C37.5596 44.535 37.6946 45.54 37.9046 46.515L40.9646 46.515C40.7096 45.54 40.5596 44.535 40.5596 43.515C40.5596 36.9 45.9446 31.5 52.5596 31.5Z" fill="white" />
                <path d="M31.2746 74.94L31.5296 75.03L43.8446 75.03L44.0996 74.94C53.7896 71.37 60.2996 62.025 60.2996 51.72L60.2996 49.515L15.0596 49.515L15.0596 51.72C15.0596 62.04 21.5696 71.37 31.2596 74.94L31.2746 74.94ZM57.2846 52.515C56.9696 61.17 51.4346 68.925 43.3046 72.03L32.0696 72.03C23.9396 68.91 18.4046 61.17 18.0896 52.515L57.2846 52.515Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_547_5782">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const GreenUpArrow = (props: any) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 0.000244206C7.16719 0.00024417 3.05543e-08 7.16744 6.82091e-08 16.0002C1.05864e-07 24.8331 7.16719 32.0002 16 32.0002C24.8328 32.0002 32 24.8331 32 16.0002C32 7.16744 24.8328 0.000244242 16 0.000244206ZM24.3617 18.5239L17.2618 10.8856L17.2618 27.0707L14.7382 27.0707L14.7382 10.8856L7.6551 18.5071L5.83807 16.8078L16.0168 5.87196L26.1956 16.8078L24.3785 18.5071L24.3617 18.5239Z" fill="#158744" />
        </svg>
    )
}
export const RedDownArrow = (props: any) => {
    return (

        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32.0002C24.8328 32.0002 32 24.8331 32 16.0002C32 7.16744 24.8328 0.000244242 16 0.000244206C7.16719 0.00024417 3.05543e-08 7.16744 6.82091e-08 16.0002C1.05864e-07 24.8331 7.16719 32.0002 16 32.0002ZM7.63828 13.4766L14.7382 21.1149L14.7382 4.92979L17.2618 4.92979L17.2618 21.1149L24.3449 13.4934L26.1619 15.1927L15.9832 26.1285L5.80442 15.1927L7.62145 13.4934L7.63828 13.4766Z" fill="#DC1928" />
        </svg>
    )
}

export const RedLeftArrow = (props: any) => {
    return (
        <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" transform="rotate(-180 35 35)" fill="#DC1928" />
            <g clip-path="url(#clip0_1_1834)">
                <path d="M38.0556 22.0454L26.6163 32.6767L50.9092 32.6767L50.9092 36.4646L26.6163 36.4645L38.0556 47.1211L35.5304 49.8484L19.091 34.5706L35.5304 19.2928L38.0556 22.0454Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1_1834">
                    <rect width="31.8182" height="30.5556" fill="white" transform="translate(50.9092 49.8484) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const RedRightArrow = (props: any) => {
    return (
        <svg {...props} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" fill="#DC1928" />
            <g clip-path="url(#clip0_1_1830)">
                <path d="M31.9444 47.9546L43.3837 37.3233H19.0908V33.5354H43.3837L31.9444 22.8789L34.4696 20.1516L50.909 35.4294L34.4696 50.7072L31.9444 47.9546Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1_1830">
                    <rect width="31.8182" height="30.5556" fill="white" transform="translate(19.0908 20.1516)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const RedDollarSign = (props: any) => {
    return (
        <svg {...props} width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_1700)">
                <path d="M0 23.9962C0 36.9889 10.3731 47.5947 23.2682 47.9925V46.4913C11.1988 46.0935 1.50117 36.1557 1.50117 23.9962C1.50117 11.8367 11.1988 1.89898 23.2682 1.50117V0C10.3731 0.397811 0 11.0111 0 23.9962Z" fill="#DC1928" />
                <path d="M24.7695 0V1.50117C36.839 1.89898 46.5365 11.8367 46.5365 23.9962C46.5365 36.1557 36.839 46.0935 24.7695 46.4913V47.9925C37.6646 47.5947 48.0377 36.9814 48.0377 23.9962C48.0377 11.0111 37.6646 0.397811 24.7695 0Z" fill="#DC1928" />
                <path d="M16.5283 28.5148H19.4256C19.6057 29.6557 19.996 30.5414 20.5965 31.1869C21.197 31.8249 22.0151 32.2302 23.0509 32.3953V24.6493L22.6831 24.5592C20.7541 24.1539 19.3205 23.4784 18.3823 22.5476C17.444 21.6169 16.9787 20.3785 16.9787 18.8548C16.9787 17.1434 17.5416 15.7098 18.66 14.5539C19.7784 13.398 21.2495 12.7225 23.0809 12.5423V9.88525H25.0325V12.5724C26.6762 12.7375 28.0348 13.3079 29.0931 14.2837C30.1515 15.2595 30.7895 16.4829 31.0146 17.9466H28.1474C27.9823 17.0909 27.6445 16.4078 27.1266 15.905C26.6087 15.3946 25.9106 15.0643 25.04 14.8992V22.4651L25.9257 22.6452C27.7796 23.028 29.1757 23.7035 30.1214 24.6568C31.0672 25.61 31.54 26.856 31.54 28.3797C31.54 30.2111 30.9546 31.6973 29.7837 32.8532C28.6128 34.0091 27.0215 34.6396 25.0099 34.7597V38.1148H23.0584V34.7297C21.2045 34.5045 19.7108 33.844 18.5549 32.7481C17.399 31.6523 16.731 30.2487 16.5283 28.5373V28.5148ZM19.6433 18.6596C19.6433 19.5528 19.9135 20.2584 20.4539 20.7838C20.9943 21.3017 21.8725 21.7145 23.096 22.0222V14.8541C22.0151 14.9967 21.1744 15.4021 20.5665 16.0776C19.9585 16.7456 19.6508 17.6163 19.6508 18.6746L19.6433 18.6596ZM28.8605 28.6649C28.8605 27.6291 28.5827 26.841 28.0348 26.3006C27.4869 25.7602 26.5111 25.3473 25.1075 25.0621L25.0175 25.0321V32.4479C26.2559 32.3428 27.2092 31.9675 27.8697 31.307C28.5302 30.6465 28.8605 29.7683 28.8605 28.6649Z" fill="#DC1928" />
            </g>
            <defs>
                <clipPath id="clip0_1_1700">
                    <rect width="48.0375" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const BlueInfo = (props: any) => {
    return (
        <svg {...props} width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31 15.5C31 24.0604 24.0604 31 15.5 31C6.93959 31 0 24.0604 0 15.5C0 6.93959 6.93959 0 15.5 0C24.0604 0 31 6.93959 31 15.5ZM17.338 21.5887V13.3325H12V14.7438H13.662V21.5887H12V23H19V21.5887H17.338ZM17.5463 10.0686C17.5463 11.2111 16.6136 12.1373 15.463 12.1373C14.3124 12.1373 13.3796 11.2111 13.3796 10.0686C13.3796 8.92617 14.3124 8 15.463 8C16.6136 8 17.5463 8.92617 17.5463 10.0686Z" fill="#4884D9" />
        </svg>

    )
}

export const RedWrong = (props: any) => {
    return (
        <svg {...props} width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.956055 28C0.956055 43.1605 13.0599 55.5358 28.1065 56V54.2484C14.0233 53.7842 2.7077 42.1883 2.7077 28C2.7077 13.8117 14.0233 2.21583 28.1065 1.75164V0C13.0599 0.464185 0.956055 12.8483 0.956055 28Z" fill="#DC1928" />
            <path d="M29.8589 0V1.75164C43.9421 2.21583 55.2577 13.8117 55.2577 28C55.2577 42.1883 43.9421 53.7842 29.8589 54.2484V56C44.9055 55.5358 57.0093 43.1517 57.0093 28C57.0093 12.8483 44.9055 0.464185 29.8589 0Z" fill="#DC1928" />
            <path d="M40.4559 17.7608L39.221 16.5259L29.0002 26.738L18.7881 16.5259L17.5444 17.7608L27.7653 27.9816L17.5444 38.2024L18.7881 39.4374L29.0002 29.2165L39.221 39.4374L40.4559 38.2024L30.2438 27.9816L40.4559 17.7608Z" fill="#DC1928" />
        </svg>
    )
}

export const RedTick = (props: any) => {
    return (
        <svg {...props} width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.956055 28C0.956055 43.1605 13.0599 55.5358 28.1065 56V54.2484C14.0233 53.7842 2.7077 42.1883 2.7077 28C2.7077 13.8117 14.0233 2.21583 28.1065 1.75164V0C13.0599 0.464185 0.956055 12.8483 0.956055 28Z" fill="#DC1928" />
            <path d="M29.8618 0V1.75164C43.945 2.21583 55.2606 13.8117 55.2606 28C55.2606 42.1883 43.945 53.7842 29.8618 54.2484V56C44.9084 55.5358 57.0123 43.1517 57.0123 28C57.0123 12.8483 44.9084 0.464185 29.8618 0Z" fill="#DC1928" />
            <path d="M42.7456 20.7223L26.359 37.1089L16.541 27.291L17.7759 26.0561L26.3502 34.6304L41.4932 19.4874L42.7281 20.7223H42.7456Z" fill="#DC1928" />
        </svg>
    )
}

export const GoogleAnalytics = (props: any) => {
    return (
        <svg {...props} width="65" height="73" viewBox="0 0 65 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_1544)">
                <path d="M64.786 63.465C64.786 68.3615 60.8264 72.4945 55.9493 72.4945H54.8876C50.3116 71.8757 46.9543 67.7568 47.1125 63.1612V9.90372C46.9543 5.3095 50.3116 1.33403 54.8876 0.570439C59.7647 -0.0483112 64.1838 3.47997 64.8 8.37653V63.465H64.786Z" fill="#F9AB00" />
                <path d="M8.84092 54.7517C13.718 54.7517 17.6777 58.7272 17.6777 63.6238C17.6777 68.5203 13.718 72.4958 8.84092 72.4958C3.96383 72.4958 0.00417204 68.5203 0.00417204 63.6238C-0.154102 58.7272 3.96383 54.7517 8.84092 54.7517ZM32.1661 27.6617C27.289 27.9641 23.4736 32.097 23.4736 36.995V61.018C23.4736 67.4417 26.3715 71.4313 30.4881 72.3383C35.3652 73.2594 39.9411 70.1924 41.0028 65.2958C41.1611 64.6771 41.1611 64.071 41.1611 63.4522V36.6645C41.1611 31.768 37.2014 27.635 32.3243 27.635H32.1661V27.6631V27.6617Z" fill="#E37400" />
            </g>
            <defs>
                <clipPath id="clip0_1_1544">
                    <rect width="64.8" height="72" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export const GoogleTagManager = (props: any) => {
    return (
        <svg width="70" height="73" viewBox="0 0 70 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.091 69.761L28.9377 57.2468L55.0439 30.1223L67.421 42.8494L41.091 69.761Z" fill="#8AB4F8" />
            <path d="M41.1788 15.8652L28.8045 3.13525L2.5651 30.1227C-0.852994 33.6353 -0.855829 39.3284 2.55943 42.8439C2.56226 42.8468 2.5651 42.8497 2.5651 42.8497L28.8045 69.8372L40.9435 57.2938L21.1152 36.5037L41.1788 15.8652Z" fill="#4285F4" />
            <path d="M67.4181 30.1226L41.1787 3.13519C37.7606 -0.380349 32.2197 -0.380349 28.8016 3.13519C25.3835 6.65073 25.3835 12.3496 28.8016 15.8623L55.0551 42.8497C58.4732 46.3652 64.0142 46.3652 67.4294 42.8497C70.8475 39.3342 70.8475 33.6352 67.4294 30.1226H67.4181Z" fill="#8AB4F8" />
            <path d="M34.8952 72.4985C39.685 72.4985 43.5679 68.5049 43.5679 63.5785C43.5679 58.6521 39.685 54.6584 34.8952 54.6584C30.1053 54.6584 26.2224 58.6521 26.2224 63.5785C26.2224 68.5049 30.1053 72.4985 34.8952 72.4985Z" fill="#246FDB" />
        </svg>

    )
}

export const Half1 = (props: any) => {
    return (
        <svg {...props} width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_2551)">
                <path d="M15.0185 0.129395V2.16631C21.0023 2.68442 25.713 7.70305 25.713 13.8036C25.713 19.0051 22.2857 23.4243 17.5648 24.933V22.9469L15.0694 25.4359C15.0694 25.4359 15.0338 25.4359 15.0185 25.4359V25.4816L13.9644 26.533L15.0185 27.5845L17.5648 30.1243V27.041C23.4213 25.4714 27.75 20.1277 27.75 13.7985C27.75 6.58046 22.1278 0.652591 15.0185 0.129395Z" fill="#DA1F33" />
                <path d="M2.28704 13.8036C2.28704 7.70305 6.99768 2.68442 12.9815 2.16631V0.129395C5.87222 0.652591 0.25 6.58046 0.25 13.8036C0.25 21.0268 5.87222 26.9547 12.9815 27.4779V25.4409C6.99768 24.9228 2.28704 19.9042 2.28704 13.8036Z" fill="#AAAAAA" />
            </g>
            <defs>
                <clipPath id="clip0_1_2551">
                    <rect width="27.5" height="30" fill="white" transform="translate(0.25 0.129395)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const Half2 = (props: any) => {
    return (
        <svg {...props} width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_2560)">
                <path d="M15.5181 2.71582V4.75757C21.5008 5.27692 26.2105 10.3075 26.2105 16.4225C26.2105 22.5376 21.5008 27.5682 15.5181 28.0875V30.1293C22.626 29.6048 28.2472 23.6629 28.2472 16.4225C28.2472 9.18222 22.626 3.24026 15.5181 2.71582Z" fill="#E97885" />
                <path d="M2.7891 16.4227C2.7891 11.2088 6.21578 6.77909 10.9357 5.26687V7.32899L13.4816 4.78316L14.5355 3.72919L13.4816 2.67522L10.9357 0.129395V3.14874C5.08034 4.72206 0.752441 10.0734 0.752441 16.4227C0.752441 23.6579 6.37362 29.605 13.4816 30.1294V28.0876C7.49888 27.5683 2.7891 22.5377 2.7891 16.4227Z" fill="#DA1F33" />
            </g>
            <defs>
                <clipPath id="clip0_1_2560">
                    <rect width="27.4949" height="30" fill="white" transform="translate(0.752441 0.129395)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const RedCloseIcon = (props: any) => {
    return (
        <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 34L34 16" stroke="#892D52" stroke-width="4" stroke-miterlimit="10" />
            <path d="M34 34L16 16" stroke="#892D52" stroke-width="4" stroke-miterlimit="10" />
        </svg>

    )
}
export const WhiteCloseIcon = (props: any) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 29L29 11" stroke="white" stroke-width="4" stroke-miterlimit="10" />
            <path d="M29 29L11 11" stroke="white" stroke-width="4" stroke-miterlimit="10" />
        </svg>
    )
}

export const DownChevron = (props: any) => {
    return (
        <svg {...props} width="54" height="26" viewBox="0 0 54 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_73_1856)">
                <path d="M53.5 0L26.9943 26L0.499998 -4.63341e-06L53.5 0Z" fill="#31313D" />
            </g>
            <defs>
                <clipPath id="clip0_73_1856">
                    <rect width="53" height="26" fill="white" transform="translate(53.5 26) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const UpChevron = (props: any) => {
    return (
        <svg {...props} width="54" height="26" viewBox="0 0 54 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_73_741)">
                <path d="M0.5 26L27.0057 0L53.5 26H0.5Z" fill="#31313D" />
            </g>
            <defs>
                <clipPath id="clip0_73_741">
                    <rect width="53" height="26" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const RightChevron = (props: any) => {
    return (
        <svg {...props} width="26" height="54" viewBox="0 0 26 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_73_846)">
                <path d="M0 0.499999L26 27.0057L-2.3167e-06 53.5L0 0.499999Z" fill="#31313D" />
            </g>
            <defs>
                <clipPath id="clip0_73_846">
                    <rect width="53" height="26" fill="white" transform="translate(26 0.5) rotate(90)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const WhiteTimelineMarker = (props: any) => {
    return (
        <svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_73_1138)">
                <circle cx="10.5" cy="8.12988" r="8" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_73_1138" x="0.5" y="0.129883" width="20" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_73_1138" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_73_1138" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const RedTimelineMarker = (props: any) => {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_73_1129)">
                <circle cx="12" cy="10.1299" r="10" fill="#DC1928" />
            </g>
            <defs>
                <filter id="filter0_d_73_1129" x="0" y="0.129883" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_73_1129" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_73_1129" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export const Metric10 = (props: any) => {
    return (
        <svg {...props} width="502" height="6" viewBox="0 0 502 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48.4" height="6" fill="#DC1928" />
            <rect x="50.4004" width="48.4" height="6" fill="white" />
            <rect x="100.801" width="48.4" height="6" fill="white" />
            <rect x="151.199" width="48.4" height="6" fill="white" />
            <rect x="201.6" width="48.4" height="6" fill="white" />
            <rect x="252" width="48.4" height="6" fill="white" />
            <rect x="302.4" width="48.4" height="6" fill="white" />
            <rect x="352.801" width="48.4" height="6" fill="white" />
            <rect x="403.199" width="48.4" height="6" fill="white" />
            <rect x="453.6" width="48.4" height="6" fill="white" />
        </svg>

    )
}

export const Metric20 = (props: any) => {
    return (
        <svg {...props} width="502" height="6" viewBox="0 0 502 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48.4" height="6" fill="#DC1928" />
            <rect x="50.4004" width="48.4" height="6" fill="#DC1928" />
            <rect x="100.801" width="48.4" height="6" fill="white" />
            <rect x="151.199" width="48.4" height="6" fill="white" />
            <rect x="201.6" width="48.4" height="6" fill="white" />
            <rect x="252" width="48.4" height="6" fill="white" />
            <rect x="302.4" width="48.4" height="6" fill="white" />
            <rect x="352.801" width="48.4" height="6" fill="white" />
            <rect x="403.199" width="48.4" height="6" fill="white" />
            <rect x="453.6" width="48.4" height="6" fill="white" />
        </svg>
    )
}

export const Metric40 = (props: any) => {
    return (
        <svg {...props} width="502" height="6" viewBox="0 0 502 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.000488281" width="48.4" height="6" fill="#DC1928" />
            <rect x="50.4009" width="48.4" height="6" fill="#DC1928" />
            <rect x="100.801" width="48.4" height="6" fill="#DC1928" />
            <rect x="151.2" width="48.4" height="6" fill="#DC1928" />
            <rect x="201.6" width="48.4" height="6" fill="white" />
            <rect x="252" width="48.4" height="6" fill="white" />
            <rect x="302.401" width="48.4" height="6" fill="white" />
            <rect x="352.801" width="48.4" height="6" fill="white" />
            <rect x="403.2" width="48.4" height="6" fill="white" />
            <rect x="453.6" width="48.4" height="6" fill="white" />
        </svg>
    )
}
export const Metric60 = (props: any) => {
    return (
        <svg {...props} width="502" height="6" viewBox="0 0 502 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.000488281" width="48.4" height="6" fill="#DC1928" />
            <rect x="50.4009" width="48.4" height="6" fill="#DC1928" />
            <rect x="100.801" width="48.4" height="6" fill="#DC1928" />
            <rect x="151.2" width="48.4" height="6" fill="#DC1928" />
            <rect x="201.6" width="48.4" height="6" fill="#DC1928" />
            <rect x="252" width="48.4" height="6" fill="#DC1928" />
            <rect x="302.401" width="48.4" height="6" fill="white" />
            <rect x="352.801" width="48.4" height="6" fill="white" />
            <rect x="403.2" width="48.4" height="6" fill="white" />
            <rect x="453.6" width="48.4" height="6" fill="white" />
        </svg>
    )
}
export const Metric70 = (props: any) => {
    return (
        <svg {...props} width="502" height="6" viewBox="0 0 502 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48.4" height="6" fill="#DC1928" />
            <rect x="50.4004" width="48.4" height="6" fill="#DC1928" />
            <rect x="100.801" width="48.4" height="6" fill="#DC1928" />
            <rect x="151.199" width="48.4" height="6" fill="#DC1928" />
            <rect x="201.6" width="48.4" height="6" fill="#DC1928" />
            <rect x="252" width="48.4" height="6" fill="#DC1928" />
            <rect x="302.4" width="48.4" height="6" fill="#DC1928" />
            <rect x="352.801" width="48.4" height="6" fill="white" />
            <rect x="403.199" width="48.4" height="6" fill="white" />
            <rect x="453.6" width="48.4" height="6" fill="white" />
        </svg>
    )
}
export const BlackDownArrow = (props: any) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_845)">
                <path d="M17.8827 8.07808L11.1861 15.2853V0H8.84371V15.2853L2.11698 8.07808L0.405273 9.66967L10.0149 20L19.5945 9.66967L17.8827 8.07808Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1_845">
                    <rect width="19.1892" height="20" fill="white" transform="translate(0.405273)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const WhiteRightChevron = (props: any) => {
    return (
        <svg {...props} width="28" height="57" viewBox="0 0 28 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1.90735e-06 0.506102L28 28.5061L-4.35466e-06 56.4941L-1.90735e-06 0.506102Z" fill="white" />
        </svg>
    )
}

export const WhiteUpChevron = (props: any) => {
    return (
        <svg {...props} width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 30L30 0L59.9871 30H0Z" fill="white" />
        </svg>

    )
}


export const WhiteLeftChevron = (props: any) => {
    return (
        <svg {...props} width="25" height="50" viewBox="0 0 25 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 50L0 24.9984L25 0V50Z" fill="white" />
        </svg>
    )
}