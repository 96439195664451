import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from './PageTemplateUpperText';
import TextContentArea from '../TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch } from 'react-redux';
import Button from '../Button';
import ProfitProjection from '../ProfitProjection';
import { DefaultProps } from '../DefaultProps';
import { css } from '@emotion/css';
import { Text20, Text24 } from '../../util/css';
import { FreeAdvice } from '../../lib/consts';
import { TipOverlay } from '../../pages/setup/NavigatingTheChallenge';


interface Props extends DefaultProps {
    decisionTitle: string;
    optionCount: number;
    children?: React.ReactNode;
    onConfirm?: () => void;
    era: string;
    showNav?: boolean;
    advice?: React.ReactNode;
}

const BusinessActionTemplate: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        padding: 0px;
        margin-bottom: 114px;

        .actionArea {
            padding-bottom: 20px;
        }
        .projection {
            position: absolute;
            top: 65px;
            right: 63px;
            @media(max-width:1350px) {
                right: 1vw;
                top: 0px;
            }
        }
        .cardPanel {
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: space-between;
            flex-wrap: wrap;
            padding: 0px;
            gap: 24px;  // original 10px for responsiveness
            width: 100%;
            
            .card {
                transition: width 0.3s ease, background 0.2s linear;
                
                display: flex;
                flex-direction: row;
                align-items: start;
                ${props.optionCount === 2 ?
            `
                    width: 24vw;
                    `:
            // props.optionCount === 3?
            // `
            // width: 15.5vw;
            // `:
            `width: calc(50vw / ${props.optionCount * 1.37});`
        }

                padding: 36px;

                background: #31313D;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                user-select: none;
                cursor: pointer;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                ${Text24}

                color: #FFFFFF;
                // justify-content: center;

                &:hover{
                    background: #494859;
                }

                .closed {
                    display: block;
                    font-family: 'AP Type Pro Text';
                    font-style: normal;
                    font-weight: 700;
                    ${Text24}

                    height: 64px;
                }

                .open{
                    display: none;
                    flex-direction: column;
                    align-items: flex-start;
                    
                    gap: 10px;
                    border-radius: 8px;
                    
                    .label {
                        font-family: 'AP Type Pro Text';
                        font-style: normal;
                        font-weight: 700;
                        ${Text24}
                        color: #212129;
                    }
                }
                &.active {
                    .closed{display: none;}
                    .open{display: flex;}
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    gap: 10px;

                    color: #212129;
                    background: #EEEEEE;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                }
            }
        }
        .nextButton {
            margin-top: 20px;
        }
        
    `;
    const [advicePromptClosed, setAdvicePromptClosed] = React.useState(false);
    return (
        <PageTemplateUpperText
            whiteFill
            showNav={props.showNav !== undefined ? props.showNav : true}
            advice={props.advice}
            textContent={"" || <TextContentArea
                title={props.decisionTitle} maxWidth='61vw'>
                <Element>
                    <div className={`actionArea ${props.className}`}>
                        <InnerElement {...props} />
                    </div>
                    {/* {!advicePromptClosed && !FreeAdvice.includes(props.era) &&
                        <TipOverlay
                            id="paidAdvicePrompt"
                            className='adviceRemind'
                            title='Advice from the expert'
                            chevron="up"
                            position="top: 105px;right:113px;"
                            // hideClose
                            onClick={() => { setAdvicePromptClosed(true) }}
                        >
                            <div>
                                Unsure about what's best for your business? 
                                <br/>
                                Click below to pay for a consultant to help you.
                                <br /><br/>
                                This will cost you $1000.
                            </div>
                            <Button text="Spend $1000 for advice" onClick={() => {
                                document.getElementById('paidAdvicePrompt')!.style.display = "none";
                                document.getElementById('navGetAdvice')!.click();
                            }} />
                        </TipOverlay>
                    } */}
                    <ProfitProjection className='projection' />
                </Element>
            </TextContentArea>
            }
        >
        </PageTemplateUpperText >
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.era) dispatch(baseActions.setCurrentEra(props.era));
    }, []);

    const onConfirm = () => {
        props.onConfirm?.();
    }
    return (
        <div>
            {props.children}

            {!FreeAdvice.includes(props.era) &&
                <div className="adviceRemind">

                </div>
            }
        </div>
    );
}
export default BusinessActionTemplate;