import * as React from 'react';
import styled from '@emotion/styled';
import Header from '../Header';
import { WhiteRightChevron } from '../../util/SVG';
import ProgressBar from '../ProgressBar';
import { BizModel } from '../../lib/bizModels';
import { useSelector } from 'react-redux';
import ProfitProjection from '../ProfitProjection';

interface ActionOutcomeTemplateProps {
    children: React.ReactNode;
    noBackground?: boolean;
    whiteFill?: boolean;
    hideFooter?: boolean;
    showNav?: boolean;
}

const ActionOutcomeTemplate: React.FunctionComponent<ActionOutcomeTemplateProps> = (props) => {
    const Element = styled('div')`
        position: absolute;
        top: 0px;
        left: 0px;
        background: ${props.whiteFill ? "white" : "#F5F5F5"};
        width: 100vw;
        min-height: 100vh;
        height: 100%;
        // min-height: 800px;
        // min-width: 900px;

        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: space-evenly;
        gap: 30px;
        padding: 0px;
        .projection {
            position: absolute;
            top: 145px;
            right: 63px;
            @media(max-width:1350px) {
                top: 80px;
                right: 1vw;
            }
        }
        .content {
            @media(max-width: 1350px) {
                right: 370px;
            }
            position: absolute;
            top: 150px;
            right: 500px;
            flex: none;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            
            margin-left: 45px;
            background: #FFFFFF;
            filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.15));

            >div {
                border-radius: 8px;
                background: white;
                padding: 60px;
            }
            .chevron {
                position: absolute;
                right: -20px;
                top:0; bottom: 0;
                margin: auto 0;
            }
            
        }
        @media (max-width: 1500px) {
            padding-bottom: 150px;
        }
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<ActionOutcomeTemplateProps> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const [showChange, setShowChange] = React.useState(true);

    React.useEffect(()=>{
        setTimeout(()=>{setShowChange(true)},700);
    })
    return (
        <>
            <Header showOptions={false} />
            <div className="content text24">
                <div>
                    {props.children}
                </div>
                <WhiteRightChevron className="chevron" />
            </div>
            <ProfitProjection
                className='projection'
                showChange={showChange}
            />
            {props.hideFooter || !model ? "" : <ProgressBar />}
        </>
    );
}
export default ActionOutcomeTemplate;