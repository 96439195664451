import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Y2SeguePage from './Y2Segue';
import {  CompetitorOptions } from './CompetitorAction';
import Y3SeguePage from '../yearThree/Y3Segue';


interface Props {
    choice: string;
}

const CompetitorResultPage: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    `;
    return (
        <ActionOutcomeTemplate
            showNav
        >
            <Element>
                <InnerElement {...props} />
            </Element>

        </ActionOutcomeTemplate>

    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);

    const dispatch = useDispatch();
    React.useEffect(() => {
        const newChoices = JSON.parse(JSON.stringify(choices));
        newChoices.competitor = props.choice;
        dispatch(baseActions.setChoices(newChoices));
        dispatch(baseActions.adjustProjection((CompetitorOptions[model.type].options as any)[props.choice].profitImpact));
    }, []);

    return (
        <>
            <div className="title titleText">
                Spend result
            </div>
            <p dangerouslySetInnerHTML={{ __html: (CompetitorOptions[model.type].options as any)[props.choice].resultText }}>

            </p>
            <Button text="Continue" className='nextButton' onClick={() => { dispatch(baseActions.setCurrentPage(Y3SeguePage)); }} />
        </>
    );
}
export default CompetitorResultPage;

