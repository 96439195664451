import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch, useSelector } from 'react-redux';
import { Metric10, Metric20, Metric40, Metric60, Metric70 } from '../../util/SVG';
import Button from '../../components/Button';
import BlackFridayAdvice from '../../components/advice/BlackFridayAdvice';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import RatesIncreaseResultPage, { KeysType } from './RatesIncreaseResult';
import GrantSpendResultPage from './GrantSpendResult';
import { TestingData } from '../../lib/util';


export const gety3s2TestData = (): TestingData => {
    return [

        { biz: "Coffee", option: "website", profitImpact: .2 },
        { biz: "Beauty", option: "website", profitImpact: .2 },
        { biz: "Homewares", option: "website", profitImpact: .2 },
        { biz: "Coffee", option: "socialMedia", profitImpact: 0 },
        { biz: "Beauty", option: "socialMedia", profitImpact: 0 },
        { biz: "Homewares", option: "socialMedia", profitImpact: 0 },
        { biz: "Coffee", option: "balance", profitImpact: -.1 },
        { biz: "Beauty", option: "balance", profitImpact: -.1 },
        { biz: "Homewares", option: "balance", profitImpact: -.1 },
    ]
}
export const GrantSpendOptions = {
    content: "You're now in the second half of Year 3. You've secured a government grant for $20,000 for being an innovative Australian business.",
    options: [
        {
            label: "website",
            content: "Use all $20K to improve your website design and functionality, making it easier for customers to find the products they're looking for and navigate seamlessly through checkout",
            result: `Well done! You've spent the last 2.5 years growing your audience and driving repeat traffic. Improving your conversion rate through website improvements is the best way you can spend you money at this stage of the game, as you'll get more money for every marketing dollar you spend.`,
            profitImpact: 0.2,
        },
        {
            label: "socialMedia",
            content: "Use all $20K to fund a campaign on social media to attract a new audience of customers",
            result: `Cool! Your social campaign has reached a new audience of potential customers, which has been helpful in improving your brand awareness, but these haven't really converted to sales just yet. You may see dividends in the long term but your profit trajectory remains the same for now.`,
            profitImpact: 0,
        },
        {
            label: "balance",
            content: "Do a little of both! Spend half on improving your website and half on running a social campaign.",
            result: `Trying to do it all? <br/>Balancing website changes and a new social campaign at once has you pulled in all sorts of directions. You don't have time to fully test your new design, resulting in a decrease in conversion rate once you put it live. Your social campaign reaches a new audience but doesn't result in conversions just yet.`,
            profitImpact: -.1,
        },
    ],
    consultantAdvice:
        <>
            <div className="bold">
                Paid advice from your consultant:
            </div>
            <div>
                It will be tempting to pass on this increase tyour customers. However, you have to look at the bigger picture and ask yourself: How much will I gain from passing on the cost? Will this gain cover for the potential loss of customers, now that they realise they'll have to pay for shipping on all orders?
            </div>
            <div>
                Experience tells me that the same principle remains in this situation: having a minimum spend applied to receive free shipping will incentivise customers to buy more in each transaction.
            </div>
            <div>Choose wisely!</div>
        </>
};


interface Props { }

const GrantSpendActionPage: React.FunctionComponent<Props> = (props) => {
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const Element = styled('div')`
        .card {
            font-weight: 400 !important;
        }
    `;
    return (
        <BusinessActionTemplate
            showNav={false}
            era="y3s2"
            decisionTitle="Fantastic news."
            optionCount={GrantSpendOptions.options.length}
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </BusinessActionTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y3s2"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([GrantSpendResultPage, { choice: activeCard }]));
    }

    return (
        <>
            <p>
                {GrantSpendOptions.content}
            </p>
            <p className='bold' style={{ margin: "2px" }}>
                Choose one option:
            </p>

            <div className="cardPanel">
                {GrantSpendOptions.options.map((val, index) => {
                    return (
                        <div className={`card ${activeCard === val.label && "active"}`} id={`${val}Card`} onClick={() => setActiveCard((val.label))}>
                            {val.content}
                        </div>
                    )
                })}
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />
            }
        </>
    );
}
export default GrantSpendActionPage;