import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultProps } from './DefaultProps';
import { BizModel } from '../lib/bizModels';


interface BusinessCardProps extends DefaultProps {
    biz: BizModel;
    icon: React.ReactNode;
}

const BusinessCard: React.FunctionComponent<BusinessCardProps> = (props) => {
    const Element = styled('div')`
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 36px 28px;
        gap: 10px;
        
        width: 29vw;
        height: 192px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        box-sizing: border-box;

        user-select: none;
        cursor: pointer;

        transition: all 0.2s ease;

        &:hover {
            border-left: 5px solid #DC1928;
            display: flex;
            flex-direction: row;
            align-items: center;
    
            height: 226px;
    
            background: #FFFFFF;
            border-left: 5px solid #DC1928;
            box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
    }
    svg {
        width: 80px !important;
        height: 80px !important;
    }
    .name{
        font-family: 'AP Type Pro Text';
        font-style: normal;
        font-weight: 500;
        color: #000000;
    }

    .info{
        font-family: 'AP Type Pro Text';
        font-style: normal;
        font-weight: 400;
        color: #000000;
    }

    
    `;
    return (
        <Element onClick={props.onClick}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<BusinessCardProps> = (props) => {
    return (
        <div>
            <div>{props.icon}</div>
            <div>
                <div className="name subtitleText">{props.biz.name}</div>
                <div className="info content text24">{props.biz.setup.offering}</div>
            </div>
        </div>
    );
}
export default BusinessCard;