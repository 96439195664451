import * as React from 'react';
import styled from '@emotion/styled';
import { Half1, Half2, RedTimelineMarker, WhiteTimelineMarker } from '../util/SVG';
import { FlexCenter } from '../util/css';
import { BizModel } from '../lib/bizModels';
import { useSelector } from 'react-redux';
import { keyframes } from '@emotion/react';


interface Props { }

const GlowPulse = keyframes`
    from {
        filter: brightness(1) drop-shadow(0px 0px 2px blue);
    }
    to {
        filter: brightness(2) drop-shadow(0px 0px 0px blue);
    }
`;
const minWidth = "1337px";

const ProgressBar: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        position: fixed;
        height: 120px;
        width: 100vw;
        left: 0px;
        bottom: 0px;
        // min-width: ${minWidth};
        .timeline1 {
            width: 100vw;
            height: 100%;
        }
        .timeline1 {
            // min-width: ${minWidth};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0px 26px;
            color: #212129;
            position: absolute;
            left: -30px;
            top: 3.07%;
            height: 114px;
            .setupDivider {
                margin: 0 auto;
                height: 84px;
                width: 1px;
                background: #A6A6AA;
            }
        }
        .timeline2 {
            border-radius: 20px;
            position: absolute;
            top: 74px;
            left: 5.1%;
            right: 5.6%;
            height: 2px;
            background: #212129;
            z-index: -1;
        }
        background: #E8E8E9;

        font-family: 'AP Type Pro Text';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #212129;
        
        .setup .label, .wrapup .label {
            justify-content: center !important;
        }
        .wrapup {
            right: 10%;
        }
        .yearOne, .yearTwo, .yearThree {
            position: absolute;
            top: 83px;

            color: #212129;
        }
        .yearOne {
            left: 24.7%;
        }
        .yearTwo {
            left: 48.9%;
        }
        .yearThree {
            left: 73.5%;
        }
        .period {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 15px;

            margin: 0 auto;
            width: 90px;
            height: 112px;
            &.setup .label, &.wrapup .label {
                margin-bottom: 0;
            }
            .label {
                margin-bottom: 30px;
                // width: 90px;
                // height: 81px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;

                svg {
                    width: 20px;
                    /* Inside auto layout */
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }
            .marker {
                width: 20px;
                height: 20px;
                svg {
                    position: absolute;
                    bottom: 30px;
                }
            }
            &.active {
                .label {
                    padding: 2px 8px 3px;
                    border-radius: 8px;
                    aspect-ratio: 1;
                    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
                    background: white;
                }
                &.setup .label, &.wrapup .label {
                    aspect-ratio: unset;
                    // background: none;
                    margin-bottom: 0;
                    padding: 4px 12px;
                }
            }
            svg.active {
                animation: ${GlowPulse} 1s ease infinite alternate;
            }
            .y1s1 {
                position: absolute;
                left: 325px;
                top: .25px;
            }
        }
        .setupDivider {
            position: relative;
            bottom: 15px;
        }
    `;
    const [scale, setScale] = React.useState(1);
    React.useEffect(() => {
        window.onresize = () => {
            const scalable = window.innerWidth < 1500 ? window.innerWidth / 1820 : 1;
            console.log(window.innerWidth, scalable)
            setScale(scalable);
        }
    }, []);
    return (
        <Element
            // style={{ transform: `scale(${scale})` }}
            className=""
        >
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {

    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const currentEra: string = useSelector((state: any) => state.base.currentEra);
    return (
        <>
            <div className="strike1">

            </div>
            <div className="timeline1">

                <div className={`setup period ${currentEra === "setup" ? "active" : ""}`}>
                    <div className="label">
                        Setup
                    </div>
                    <div className="marker">
                        {currentEra === "setup" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>
                <div className="setupDivider" />

                <div className={`y1s1 period ${currentEra === "y1s1" ? "active" : ""}`}>
                    <div className="label">
                        <Half1 />
                        Half 1
                    </div>
                    <div className="marker">
                        {currentEra === "y1s1" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="yearOne">Year 1</div>

                <div className={`y1s2 period ${currentEra === "y1s2" ? "active" : ""}`}>
                    <div className="label">
                        <Half2 />
                        Half 2
                    </div>
                    <div className="marker">
                        {currentEra === "y1s2" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="setupDivider" />

                <div className={`y2s1 period ${currentEra === "y2s1" ? "active" : ""}`}>
                    <div className="label">
                        <Half1 />
                        Half 1
                    </div>
                    <div className="marker">
                        {currentEra === "y2s1" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="yearTwo">Year 2</div>

                <div className={`y2s2 period ${currentEra === "y2s2" ? "active" : ""}`}>
                    <div className="label">
                        <Half2 />
                        Half 2
                    </div>
                    <div className="marker">
                        {currentEra === "y2s2" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="setupDivider" />

                <div className={`y3s1 period ${currentEra === "y3s1" ? "active" : ""}`}>
                    <div className="label">
                        <Half1 />
                        Half 1
                    </div>
                    <div className="marker">
                        {currentEra === "y3s1" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="yearThree">Year 3</div>

                <div className={`y3s2 period ${currentEra === "y3s2" ? "active" : ""}`}>
                    <div className="label">
                        <Half2 />
                        Half 2
                    </div>
                    <div className="marker">
                        {currentEra === "y3s2" ?
                            <RedTimelineMarker className="active" />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>

                <div className="setupDivider" />

                <div className={`wrapup period ${currentEra === "wrapup" ? "active" : ""}`}>
                    <div className="label">
                        Wrap up
                    </div>
                    <div className="marker">
                        {currentEra === "wrapup" ?
                            <RedTimelineMarker />
                            :
                            <WhiteTimelineMarker />
                        }
                    </div>
                </div>
            </div>
            <div className="timeline2">
            </div>
        </>
    );
}
export default ProgressBar;