import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultProps } from '../DefaultProps';
import { BlurFadeIn, PageLoadTransition, RotateFadeIn, ScaleFadeIn } from '../../util/Transitions';


interface DialogueProps extends DefaultProps {
    title?: string;
    children: React.ReactNode;
    buttons: React.ReactNode;
}

const Dialogue: React.FunctionComponent<DialogueProps> = (props) => {
    console.log(PageLoadTransition);
    const Element = styled('div')`
        position: fixed;
        height: calc(100vh - 80px);
        width: 100vw;
        overflow-y: auto;
        

        display: flex;
        justify-content: center;
        align-items: center;

        animation: ${ScaleFadeIn()} .5s ease;
        * {
        animation: ${BlurFadeIn("1", "100px")} .7s ease;
        }
        
        > div {
            width: 70%;
            // min-height: 500px;
            min-width: 300px;
        }
        > div > div {
            padding: 40px 48px;
            background: #FFFFFF;
            border-left: 4px solid #DC1928;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        }
        .title {
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 500;
            
            color: #212129;
            margin: 0;
            
        }
        .content {
            // margin-top: 20px;
            width: 100%;
            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 400;
            color: #212129;
        }
        .buttons {
            > * {
                width: unset;
                max-width: unset;
            }
            height: max-content;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            align-items: flex-end;
            gap: 38px;

        }
    `;
    return (
        <Element>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<DialogueProps> = (props) => {
    return (
        <div>
            <div>
                {props.title && <h1 className="title titleText">{props.title}</h1>}
                <div className="content text24">
                    {props.children}
                </div>
                <div className='buttons'>
                    {props.buttons}
                </div>
            </div>
        </div>
    );
}
export default Dialogue;