import * as React from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from './Button';
import { DefaultProps } from './DefaultProps';


type ExitButtonProps = ButtonProps;

const ExitButton: React.FunctionComponent<ExitButtonProps> = (props) => {
    const Element = styled(Button)`
        button {
            width: 300px;
            // height: 61px;
            background: #FFFFFF;
            border: 2px solid #BCBCBF;
            color: #212129;

            &:hover {
                background: #FFFFFF;
                border: 2px solid #212129;
            }
        }
        @media(max-width:1600px) {
            // button{
            //     font-size: 90%;
            //     line-height: 90%;
            // }
        }
    `;
    return (
        <Element {...props}>
            {/* <InnerElement {...props}/> */}
        </Element>
    );
}

const InnerElement: React.FunctionComponent<ExitButtonProps> = (props) => {
    return (
        <>
            
        </>
    );
}
export default ExitButton;