import * as React from 'react';
import styled from '@emotion/styled';

interface PageHeadingProps {
    label?: string;
    children?: React.ReactNode;
}

const PageHeading: React.FunctionComponent<PageHeadingProps> = (props) => {
    const Element = styled('h1')`
        color: #DC1928;
        font-size: 40px !important;
        line-height: 42px !important;
        margin: 0px 0px 30px;
    `;
    return (
        <Element aria-labelledby={props.label || "page heading"}>
            {props.children}
        </Element>
    );
}

export default PageHeading;