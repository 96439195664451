import { configureStore } from '@reduxjs/toolkit';

import { baseSlice } from './base';

const store = configureStore({
    reducer: {
        base: baseSlice.reducer
    }
});


export default store;


