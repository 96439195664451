import * as React from 'react';
import styled from '@emotion/styled';

import models, { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import Button from '../../components/Button';
import PageHeading from '../../components/wcag/PageHeading';
import TextContentArea from '../../components/TextContentArea';
import BusinessCard from '../../components/BusinessCard';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import BusinessDetailPage from './BusinessDetail';
import { getBizIcon } from '../../lib/util';
import BusinessDetailSliderPage from './BusinessDetailSlider';
import Info from '../../components/Info';
import { css } from '@emotion/css';

interface IntroPageProps { }

const IntroPage: React.FunctionComponent<IntroPageProps> = (props) => {
    const Element = styled('div')`
        overflow: hidden;
        .businessPanel {
            display: flex;
            flex-direction: row;
            // align-items: start;
            // justify-content: start;
            flex-wrap: wrap;
            padding: 0px;
            gap: 40px;
            margin-left: 6px;
            

            min-height:256px;
            // > * {transition: all 1.3s ease;}
            // > * {flex: 1 1 0;}
        }
    `;
    const moreInfo = css`
            a, a:visited {
                text-decoration: underline;
                font-weight: 700;
                color: #212129;
                margin-top: 20px;
            }
            span {
                font-weight: 400;
            }
    `;
    return (
        <PageTemplateUpperText
            textContent={<TextContentArea title="Choose your business">
                <p>
                    It's time to choose your business. Each business comes with pros and cons so you might want to get to know them a little bit.
                </p>
                <Info className={moreInfo} text='Before you dive in, grab a little information about website and shipping platforms so you can compare from those chosen in each business’s setup.'>
                    <a href="/files/slides.pdf" target='__blank'>More info</a>
                </Info>
                <p>
                    <span className="bold">Choose the business you want to progress with.</span>
                </p>
            </TextContentArea>}
        // whiteBackground
        >
            <Element>
                <InnerElement {...props} />
            </Element>
        </PageTemplateUpperText>
    );
}

const InnerElement: React.FunctionComponent<IntroPageProps> = (props) => {
    const dispatch = useDispatch();

    return (
        <div className="businessPanel">
            {models.map((val, idx) => {
                return (
                    <BusinessCard biz={val} icon={getBizIcon(val)()} onClick={() => dispatch(baseActions.setCurrentPage([BusinessDetailSliderPage, { selected: val }]))} />
                )
            })}
        </div>
    );
}
export default IntroPage;