import * as React from 'react';
import styled from '@emotion/styled';
import ActionOutcomeTemplate from '../../components/pageTemplates/ActionOutcome';
import Button from '../../components/Button';
import { BizModel } from '../../lib/bizModels';
import { useDispatch, useSelector } from 'react-redux';
import { baseActions } from '../../state/base';
import WrapUpPage from './WrapUp';
import WrapUpOnePage from './WrapUpOne';
import ExitButton from '../../components/ExitButton';
import PreIntroPage from '../setup/PreIntro';
import CoverPage from '../cover';
import { FlexCenter } from '../../util/css';
import { keyframes } from '@emotion/react';
import $ from 'jquery';

const MoreInfoContact = "xxx -- AusPost to confirm";



const confetti = keyframes`
    0%  {
        opacity: 0;
        transform: translateY(0%) rotate(0deg);
    }
    10% {
        opacity: 1;
    }
    35% {
        transform: translateY(-800%) rotate(270deg);
        filter: brightness(7);
    }
    80% {
        opacity: 1;
        filter: brightness(1);
    }
    100% {
        opacity: 0;
        transform: translateY(2000%) rotate(1440deg);
    }
`;
function rnd(m: any, n: any) {
    m = parseInt(m);
    n = parseInt(n);
    return Math.floor(Math.random() * (n - m + 1)) + m;
}
function initConfetti() {
    $.each($(".phenomenal"), function () {
        var confetticount = 100;
        for (var i = 0; i <= confetticount; i++) {
            $('.phenomenal').append('<span class="particle c' + rnd(1, 2) + '" style="top:' + rnd(10, 50) + '%; left:' + rnd(0, 100) + '%;width:' + rnd(6, 8) + 'px; height:' + rnd(3, 4) + 'px;animation-delay: ' + (rnd(0, 30) / 10) + 's;"></span>');
        }
    });
}
interface Props {
}

const WrapUpTwo: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
        .content > div >* ~ * {
            margin-top: 20px;
        }
        .buttons {
            margin-top: 30px;
            display: flex;
            gap: 24px;
            > *, button {
                width: 300px;
            }
        }
        .phenomenal {
            position: relative;
            .particle {
                opacity: 0;
                position: absolute;
                -webkit-animation: ${confetti} 3s ease-in infinite;
                        animation: ${confetti} 3s ease-in infinite;
            }
            .c1 {
                background-color: rgba(76, 175, 80, 0.5);
            }
            .c2 {
                background-color: rgba(156, 39, 176, 0.5);
            }
        }
    `;
    return (
        <Element id="wrapUp2">
            <ActionOutcomeTemplate
                showNav
            >

                <InnerElement {...props} />


            </ActionOutcomeTemplate>
        </Element>

    );
}
const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);
    const projectedProfit: number = useSelector((state: any) => state.base.projectedProfit);
    const dispatch = useDispatch();

    const results: Array<{ title: string, content: React.ReactNode }> = [
        {
            title: "Tough Luck!",
            content:
                <div>
                    It looks like the decisions you've made have resulted in detrimental impact on {model.name}. But you should now have a clearer picture on what it takes to run a profitable eCommerce business. Share this knowledge with your customers when you're having a conversation with them. To learn more about eCommerce, go to eCommerce Insights, located in the Insights Hub. You can also access more learning by searching 'eCommerce' in LinkedIn Learning.
                </div>
        },
        {
            title: "This is a good result!",
            content:
                <div>
                    There may have been some hiccups for {model.name} along the way, but overall, you've made good decisions. Share the knowledge you've gained from this game with your customer when you're having a conversation with them. To learn more about eCommerce, go to eCommerce Insights, located in the Insights Hub. You can also access more learning by searching 'eCommerce' in LinkedIn Learning.
                </div>
        },
        {
            title: "Phenomenal!",
            content: <div>
                You've shown you've got what it takes to make {model.name} successful. Share the knowledge you've gained from this game with your customer when you're having a conversation with them. To learn more about eCommerce, go to eCommerce Insights, located in the Insights Hub. You can also access more learning by searching 'eCommerce' in LinkedIn Learning.
            </div>
        },

    ]
    const resultIndex = projectedProfit < 0 ? 0 : projectedProfit < model.targetProfit ? 1 : 2;
    React.useEffect(() => {
        initConfetti();
        dispatch(baseActions.setCurrentEra("wrapup"));
    }, []);

    return (
        <>
            <div className="title titleText  ">
                <span className={resultIndex === 2 ? `phenomenal` : ""}>{results[resultIndex].title}</span>
            </div>
            {results[resultIndex].content}
            <div>If you still want to explore more, why not play again and choose a different business this time?</div>
            <div className="buttons">
                <Button className="" text="Play again" onClick={() => {
                    dispatch(baseActions.clearAll());
                    dispatch(baseActions.setCurrentPage(PreIntroPage));
                }} />
                <ExitButton className="" text="Exit" onClick={() => {
                    dispatch(baseActions.clearAll());
                    dispatch(baseActions.setCurrentPage(CoverPage))
                }} />
            </div>
        </>
    );
}
export default WrapUpTwo;

