import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplate from '../../components/pageTemplates/PageTemplate';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import Dialogue from '../../components/pageTemplates/Dialogue';
import ChallengeContinuesPage from './ChallengeContinues';

interface Props { }

const Y2SeguePage: React.FunctionComponent<Props> = (props) => {

    const Element = styled('div')`
        .ready {
            display: inline-block;
            margin: 0px 0px 30px;
        }
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const modalActive = useSelector((state: any) => state.base.modalActive);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = React.useState(false)
    return (
        <Dialogue
            title="Well done! You've made it through your first year of operation."
            buttons={
                <>
                    <Button className="" text="Got it, let's start Year 2" onClick={() => {
                        dispatch(baseActions.setCurrentPage(ChallengeContinuesPage));
                    }} />
                </>
            }
        >
            <p>
                As you move on to your second year, you will see that Net Promoter Score and customer retention become even more important.
            </p>
            <p>
                Net Promoter Score is a customer loyalty and satisfaction measurement, driven by customer experience. Traffic in general, and Customer Retention Rate (how many customers make repeat purchases) are directly affected by your Net Promoter Scores.
            </p>
        </Dialogue>
    );
}
export default Y2SeguePage;