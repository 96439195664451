import * as React from 'react';
import styled from '@emotion/styled';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import TextContentArea from '../../components/TextContentArea';
import { baseActions } from '../../state/base';
import { useDispatch, useSelector } from 'react-redux';
import { Metric10, Metric20, Metric40, Metric60, Metric70 } from '../../util/SVG';
import Button from '../../components/Button';
import BlackFridayAdvice from '../../components/advice/BlackFridayAdvice';
import BusinessActionTemplate from '../../components/pageTemplates/BusinessAction';
import RatesIncreaseResultPage, { KeysType } from './RatesIncreaseResult';
import { TestingData } from '../../lib/util';
import { BizType } from '../../lib/bizModels';


export type FreeShippingChoices = "shipping" | "campaign" | "freeShipping";
export type ThresholdShippingChoice = "marketing" | "adsThreshold" | "conditionalShipping";

export const RatesIncreaseKeys = {
    "shipping": 0, "campaign": 0, "freeShipping": 0,
    "marketing": 1, "adsThreshold": 1, "conditionalShipping": 1
};

export const gety3s1TestData = (competitorChoice: string): TestingData => {
    const optionsIndex = (RatesIncreaseKeys as any)[competitorChoice]
    const branches: Array<Array<{ biz: BizType, option: string, profitImpact: number }>> = [[
        { biz: "Coffee", option: "threshold", profitImpact: -.01 },
        { biz: "Beauty", option: "threshold", profitImpact: -.01 },
        { biz: "Homewares", option: "threshold", profitImpact: -.01 },
        { biz: "Coffee", option: "paidShipping", profitImpact: -.5 },
        { biz: "Beauty", option: "paidShipping", profitImpact: -.5 },
        { biz: "Homewares", option: "paidShipping", profitImpact: -.5 },
    ],
    [
        { biz: "Coffee", option: "threshold", profitImpact: .5 },
        { biz: "Beauty", option: "threshold", profitImpact: .5 },
        { biz: "Homewares", option: "threshold", profitImpact: .5 },
        { biz: "Coffee", option: "paidShipping", profitImpact: -.5 },
        { biz: "Beauty", option: "paidShipping", profitImpact: -.5 },
        { biz: "Homewares", option: "paidShipping", profitImpact: -.5 },
    ]]
    return branches[optionsIndex];
}

export const RatesIncreaseOptions = [
    {
        content: "You've been hit by rate increases from your delivery partner, driving up your fulfilment costs. Do you want to pass this onto your customers?",
        options: [
            {
                label: "threshold",
                content: "Introduce a minimum spend to qualify for free shipping to drive up the average order value",
                result: `Congratulations! Introducing a minimum spend threshold to qualify for free shipping means that your average order value has increased significantly, making every order much more profitable. Conversion rate has dipped only marginally, but overall profitability has gone through the roof!
                `,
                profitImpact: .5,
            },
            {
                label: "paidShipping",
                content: "Update your shipping policy to make the customer pay for shipping on all orders to cover your increased costs",
                result: `Oh dear. Unfortunately your customers have not reacted well to introducing shipping fees, and your cart abandonment rates have gone through the roof! Your overall sales have plummeted.`,
                profitImpact: -.5,
            },
        ],
        consultantAdvice:
            <>
                <div className="bold">
                    Paid advice from your consultant:
                </div>
                <div>
                    You are currently maintaining a free shipping policy on all orders, so the increased shipping rates are going to hurt your bottom line further.
                </div>
                <div>
                    If you can implement a strategy that drives up your average order value, then this will have a huge impact to improve your overall profitability.
                </div>
                <div>
                    Of your two options, both will result in customers spending more per order, so I want you to think about the policy that would be most likely to convert
                </div>
                <div>
                    In my experience, shipping costs act as an enormous deterrent to purchase, so I would recommend updating your policy to the one where customers are able to access free shipping once they spend a certain amount.
                </div>
                <div>Choose wisely!</div>

            </>
    },
    {
        content: "You've been hit by rate increases from your delivery partner, driving up your fulfilment costs. Do you want to pass this onto your customers?",
        options: [
            {
                label: "threshold",
                content: "Maintain your free shipping with threshold policy and wear the additional shipping costs",
                result: `You did a good thing.<br/>Despite higher costs to service each order, you've still been able to come close to maintaining profitability given the impact your free shipping (with threshold) policy has on AOV.`,
                profitImpact: -.1,
            },
            {
                label: "paidShipping",
                content: "Update your shipping policy to make the customer pay for shipping on all orders to cover your increased costs",
                result: `Oh dear. Unfortunately your customers have not reacted well to introducing shipping fees, and your cart abandonment rates have gone through the roof! Your overall sales have plummeted.`,
                profitImpact: -.5,
            }
        ],
        consultantAdvice:
            <>
                <div className="bold">
                    Paid advice from your consultant:
                </div>
                <div>
                    It would be tempting to pass on this increase your customers. However, you must look at the bigger picture and ask yourself: How much will I gain from passing on the cost? How many transactions will I lose when customers realise they'll have to pay for shipping on all orders?
                </div>
                <div>
                    Experience tells me that the small gains per order you make by charging shipping, will be more than offset by a negative impact to conversion rate and customer retention. Although your profitability will take a small hit, retaining your shipping policy of free shipping with a threshold is key to attracting and retaining customers.
                </div>
                <div>Choose wisely!</div>
            </>
    }
];


interface Props { }

const RatesIncreaseActionPage: React.FunctionComponent<Props> = (props) => {
    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const competitorChoice = choices.competitor
    const optionsIndex = (RatesIncreaseKeys as any)[competitorChoice]
    return (
        <BusinessActionTemplate
            era="y3s1"
            decisionTitle="It's the start of Year 3 and it's that time of year again!"
            optionCount={RatesIncreaseOptions[optionsIndex].options.length}
            advice={RatesIncreaseOptions[optionsIndex].consultantAdvice}
            showNav

        >
            <InnerElement {...props} />
        </BusinessActionTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(baseActions.setCurrentEra("y3s1"));
    }, []);

    const [activeCard, setActiveCard] = React.useState<string>();
    const onConfirm = () => {

        dispatch(baseActions.setCurrentPage([RatesIncreaseResultPage, { choice: activeCard }]));
    }

    const choices: { [key: string]: string; } = useSelector((state: any) => state.base.choices);
    const competitorChoice = choices.competitor;
    console.log(competitorChoice)
    const ratesIndex = (RatesIncreaseKeys as any)[competitorChoice];

    return (
        <>
            <p>
                {RatesIncreaseOptions[ratesIndex].content}
                <br />
                Consider what would be the likely impacts of either option before you hit confirm.
            </p>
            <p className='bold'>
                Choose one option:
            </p>

            <div className="cardPanel">
                {RatesIncreaseOptions[ratesIndex].options.map((val, index) => {
                    return (
                        <div className={`card ${activeCard === val.label && "active"}`} id={`${val}Card`} onClick={() => setActiveCard((val.label))}>
                            {val.content}
                        </div>
                    )
                })}
            </div>
            {activeCard !== undefined && <Button className="nextButton" text="Confirm" onClick={onConfirm} />
            }
        </>
    );
}
export default RatesIncreaseActionPage;