import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import PageHeading from '../../components/wcag/PageHeading';
import Button from '../../components/Button';
import { baseActions } from '../../state/base';
import IntroPage from './ChooseYourBusiness';
import CoverPage from '../cover';
import Dialogue from '../../components/pageTemplates/Dialogue';
import PreIntroPage from './PreIntro';
import PageTemplate from '../../components/pageTemplates/PageTemplate';

interface Props { }

const WelcomePage: React.FunctionComponent<Props> = (props) => {
    const selectedModel = useSelector((state: any) => state.base.selectedModel);
    const dispatch = useDispatch();
    const Element = styled('div')`
        .ready {
            display: inline-block;
            margin: 0px 0px 30px;
        }
        .button {
            width: 330px;
        }
    `;
    return (
        <PageTemplate>
            <Element className=''>
                <InnerElement {...props} />
            </Element>
        </PageTemplate>
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    const model = useSelector((state: any) => state.base.selectedModel);
    const modalActive = useSelector((state: any) => state.base.modalActive);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = React.useState(false)
    return (
        <>
            {modalActive ?
                <>
                    <div className='popupbg' />
                    <div className='popup'>
                        <div>
                            <PageHeading>Have you got what it takes?</PageHeading>
                            <p>
                                Running a business is no easy feat. You’re best to start small, spend wisely, and then try to make a profit.
                            </p>
                            <p className="bold">Can you make it through 3 years with money left in the bank?</p>

                            <div className="buttons">
                                <Button className="" text="Choose your business" onClick={() => {
                                    dispatch(baseActions.setCurrentPage(IntroPage));
                                }} />
                                <Button className="" text="Exit" onClick={() => {
                                    dispatch(baseActions.setCurrentPage(CoverPage))
                                }} />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <Dialogue
                        title="Welcome!"
                        buttons={
                            <>
                                <Button className="" text="Accept the challenge" onClick={() => dispatch(baseActions.setCurrentPage(PreIntroPage))} />
                            </>
                        }
                    >
                        <p>
                        There are hundreds of thousands of eCommerce businesses operating in Australia, many of which are Australia Post customers. Could you run one of those businesses?

                        </p>
                        <span className="bold ready">Ready?</span>

                    </Dialogue>
                </>
            }
        </>
    );
}
export default WelcomePage;