import * as React from 'react';
import styled from '@emotion/styled';
import TextContentArea from '../../components/TextContentArea';
import PageTemplateUpperText from '../../components/pageTemplates/PageTemplateUpperText';
import Info from '../../components/Info';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { RedDollarSign, RedTick, RedWrong } from '../../util/SVG';
import { FromLeft, FromRight } from '../../util/Transitions';
import DollarValue from '../../components/DollarValue';
import { useDispatch, useSelector } from 'react-redux';
import { BizModel } from '../../lib/bizModels';
import { baseActions } from '../../state/base';
import InstalledToolsPage from './InstalledTools';
import InfoPanel from '../../components/InfoPanel';
import QuizSelect from '../../components/QuizSelect';
import { AnsweredQuiz, Quiz2, WHatsInANumberQuiz } from '../../lib/quiz';
import QuizCheckableDivs from '../../components/QuizCheckableDivs';
import NavigatingTheChallengePage from './NavigatingTheChallenge';
import { SubtitleText, Text24 } from '../../util/css';


interface Props {

}

const Quiz2Page: React.FunctionComponent<Props> = (props) => {
    const Element = styled('div')`
    width: 100%;
    label {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        // line-height: 36px;
        ${Text24}
    }
    display: flex;
    gap: 40px;

    .leftPanel {
        height: 100%;
        width: 60vw;
        // min-width: 750px
        // max-width: 900px;
    }
    .rightPanel {
        width: 28vw;
        // display: none;
        // position: relative;
        z-index: 50;
        &:has(.quizFeedback){
            margin-top: -10px;
            animation: ${FromRight()} 0.33s ease;
        }
        &:has(.aovInfo){
            margin-top: 200px;
            animation: ${FromLeft()} 0.33s ease;
        }
    }
    .quizQuestions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 38px;
        
    }
    .quizQuestion {
        // width: 10vw !important;
    }
    .quizFeedBack{
        // position: absolute;
        // height: 705px;
        top: 150px;
        right: 0px;
    }
    .quizFeedback {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 40px 48px;
        gap: 40px;

        background: #FFFFFF;
        border-radius: 8px;
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;

                // width: 634px;
                // height: 524px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 500;
                // font-size: 40px;
                // line-height: 48px;
                ${SubtitleText}

            }
            .detail {
                // width: 634px;
                // height: 452px;

                font-family: 'AP Type Pro Text';
                font-style: normal;
                font-weight: 400;
                // font-size: 24px;
                // line-height: 32px;
                ${Text24}
                /* or 150% */

                color: #212129;


                /* Inside auto layout */
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                ul {
                    // margin-top: 100px;
                }
                li {
                    margin-top: 30px;
                }
            }
        }

    }
    `;

    const [selectedIndex, setSelectedIndex] = React.useState<number>();
    const [showFeedBack, setshowFeedBack] = React.useState(false);
    const [gotIt, setGotIt] = React.useState(false);
    const model: BizModel = useSelector((state: any) => state.base.selectedModel);

    const quiz = Quiz2;
    const quizID = `quiz_${quiz.id}`;

    const answeredQuiz = useSelector((state: any) => state.base.quizAnswers);
    const dispatch = useDispatch();

    const isCorrect = () => selectedIndex && (quiz.questions[0].options[selectedIndex] === quiz.questions[0].correct);
    console.log('selectedIndex', selectedIndex)
    const checkAnswer = () => {
        if (selectedIndex === undefined) {
            console.log("quiz not answered");
            return;
        }

        const answer = quiz.questions[0].options[selectedIndex!];
        var incorrect = !isCorrect()
        var answers: Array<{ id: string, answer: string }> = [{ id: quiz.questions[0].id, answer }];
        console.log(answers, incorrect);

        dispatch(baseActions.setQuizAnswered({ quizID, answers }));
        setshowFeedBack(true);
        console.log(answeredQuiz[quizID])
    }
    console.log(answeredQuiz[quizID])
    return (
        <PageTemplateUpperText
            noBackground
            hideFooter
            textContent={<TextContentArea title="Okay." >
                <Element>
                    <div className='leftPanel'>
                        <div id={quizID} className='quizQuestions'>
                            {quiz.questions.map((val, index) => {
                                return (
                                    <>
                                        <label>
                                            {val.question}
                                        </label>
                                        <QuizCheckableDivs
                                            className="quizQuestion"
                                            id={val.id}
                                            correct={val.correct}
                                            selected={selectedIndex}
                                            disabled={answeredQuiz[quizID] !== undefined || showFeedBack}
                                            options={val.options}
                                            onSelect={(val, index) => setSelectedIndex(index)}
                                        />
                                    </>
                                )

                            })}
                            {!showFeedBack && selectedIndex !== undefined && <Button text="Check answer" onClick={checkAnswer} />}
                        </div>
                    </div>
                    {showFeedBack &&
                        <div className='rightPanel'>
                            <InfoPanel
                                defaultChevron
                                className="quizFeedback"
                                headerIcon={isCorrect() ? <RedTick /> : <RedWrong />}
                                headerTitle={isCorrect() ? "That's right!" : "Not Quite"}
                                onGotIt={() => dispatch(baseActions.setCurrentPage(NavigatingTheChallengePage))}
                            >
                                {isCorrect() ?
                                    <>
                                        <p>
                                            Seems like you might be onto something here. Investing in Search Engine Optimisation now will set your website up for organic traffic later.

                                        </p>
                                        <p>
                                            If you think it might have been a fluke, there's another chance for some advice as you make business decisions.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p>
                                            Maybe a little help would be a good idea. Investing in Search Engine Optimisation now was the best option to set your website up for organic traffic later.

                                        </p>
                                        <p>You'll have another chance to get some advice as you make your business decisions.</p>
                                    </>
                                }
                            </InfoPanel>
                        </div>
                    }
                </Element>
            </TextContentArea >}
        >
        </PageTemplateUpperText >
    );
}

const InnerElement: React.FunctionComponent<Props> = (props) => {
    return (
        <>

        </>
    );
}
export default Quiz2Page;