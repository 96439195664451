import * as React from 'react';
import styled from '@emotion/styled';
import { BlueInfo } from '../util/SVG';
import { DefaultProps } from './DefaultProps';
import { Text24 } from '../util/css';


interface InfoProps extends DefaultProps {
    children?: React.ReactNode;
    text: string;
}

const Info: React.FunctionComponent<InfoProps> = (props) => {
    const Element = styled('div')`
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px 32px;
        gap: 18px;

        width: 100%;

        background: #D9E6F7;
        border-left: 4px solid #4884D9;
        border-radius: 8px;

        svg{
            min-width: 2vw !important;
            height: 31px;
        }
        span {
            width: 894px;

            font-family: 'AP Type Pro Text';
            font-style: normal;
            font-weight: 700;
            ${Text24}

            color: #212129;
        }
        a {
            display: block;
        }
    `;
    return (
        <Element className={props.className}>
            <InnerElement {...props} />
        </Element>
    );
}

const InnerElement: React.FunctionComponent<InfoProps> = (props) => {
    return (
        <>
            <BlueInfo className="icon"/>
            <span>
                {props.text}
                {props.children}
            </span>

        </>
    );
}
export default Info;